import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Navbar from "../components/layout/Navbar";
import BackgroundBlobTop from "../components/objects/BackgroundBlobTop";
import BackgroundBlobBottom from "../components/objects/BackgroundBlobBottom";

import { track } from "../utils/mixpanel.js";

class NotFound404 extends React.Component {
  componentDidMount() {
    track("open-404");
  }
  render() {
    return (
      <div className="u-absolute u-top--0 u-w-all u-blue-background u-flex u-overflow--hidden">
        <BackgroundBlobTop />
        <div className="u-wrap u-relative u-mauto u-vh">
          <Navbar />
          <div className=" u-align-center ">
            <div className="welcome-page u-pr--48">
              <div className="u-main-font--vlarge u-color-white u-flex u-justify-center">
                Not much to see here!
              </div>
            </div>
            <div className="login-formu-relative u-flex u-justify-center u-p--24">
              <div className="login-form__card o-card u-w--33pct">
                <h2 className="u-main-font">
                  I haven't really made this page yet,
                  <br />
                  nor have I made the one you have tried to get to
                  <br /> Sorry about that!
                </h2>
                <h3 className="u-main-font">
                  Give me a message on the community tab if you think something
                  is a big miss!
                </h3>
              </div>
            </div>
          </div>
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

NotFound404.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(NotFound404));
