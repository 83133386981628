import React, { Component } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import PropTypes from "prop-types";

import AddNewPotButton from "./AddNewPotButton.js";
import Pot from "../layout/Pot.js";
import TimeDrawer from "../layout/TimeDrawer";

import { fetchDemoPots } from "../../actions/potActions";
import {
  fetchPotTargets,
  fetchDemoPotTargets,
} from "../../actions/targetActions";
import {
  fetchDemoTargetsPotHoursCount,
  fetchSpecificPotHoursCount,
} from "../../actions/analyticalActions";

import {
  getNextStartTimeFromLogs,
  getDBDateFormat,
  getMostRecentMondayBeforeDate,
  getBeginningOfTheMonthISODate,
} from "../../utils/dateFunctions.js";
import { timeBlocks } from "../../utils/constants";
import { getHeightOfElementWithId } from "../../utils/UIFunctions";
import { TimePropTypes, PotPropTypes } from "../../utils/propTypes.js";

class Tracking extends Component {
  state = {
    targetCountsLoaded: false,
    innerWindowHeight: null,
  };

  componentDidMount = async () => {
    await this.props.fetchPotTargets();

    this.changeTheSizeOfTheMainDivs();

    document.addEventListener(
      "time-drawer-changed",
      this.changeTheSizeOfTheMainDivs
    );
  };

  componentWillUnmount = () => {
    document.removeEventListener(
      "time-drawer-changed",
      this.changeTheSizeOfTheMainDivs
    );
  };

  changeTheSizeOfTheMainDivs = () => {
    // To make pot container height be dynamic on mobile and desktop
    const navHeight = getHeightOfElementWithId("nav-bar", document) || 42;

    const timeDrawerHeight =
      getHeightOfElementWithId("time-drawer", document) || 140;

    const progressBarHeight =
      getHeightOfElementWithId("tracking-utils", document) || 78;

    const innerWindowHeight =
      window.innerHeight - (navHeight + progressBarHeight + timeDrawerHeight);
    this.setState({ innerWindowHeight });
  };

  componentDidUpdate = async (previousProps) => {
    const countsNotLoadedForPotsYet =
      this.props.targets.potTargets && !this.state.targetCountsLoaded;
    const dayHasUpdated = this.props.time.date !== previousProps.time.date;
    const potTargetsHaveUpdated =
      this.props.targets.potTargets !== previousProps.targets.potTargets;

    if (countsNotLoadedForPotsYet || dayHasUpdated || potTargetsHaveUpdated) {
      await this.fetchCurrentProgressForTargets(
        this.props.time.date,
        this.props.targets.potTargets
      );
      this.setState({ targetCountsLoaded: true });
    }
  };

  onDragEnd = (result) => {
    const { destination, draggableId } = result;
    const { customPots } = this.props.pots;

    //if a block doesn't land on anything, do nothing
    if (!destination) {
      return;
    }

    //Find which hour was picked up and where it landed
    const sourceTimeBlock = timeBlocks.find(
      (block) => block.id === parseInt(draggableId)
    );

    const destinationPot = customPots.find(
      (pot) => pot.id === destination.droppableId
    );

    //  if the block wasn't dropped onto a valid pot, do nothing
    if (!destinationPot || !sourceTimeBlock) {
      return;
    }

    //if valid dragging and dropping throw an event which is spotted in Column.jsx and ProgressBar.jsx
    this.spottedDrop({
      hours: sourceTimeBlock.value,
      sourceId: sourceTimeBlock.id,
      destinationPotId: destinationPot.id,
      colour: destinationPot.colour,
    });
  };

  spottedDrop = async (event) => {
    const {
      logs,
      date,
      pots: { customPots },
    } = this.props;

    // get the potid from the pot name in the spotted drop event
    const pot = customPots.find((pot) => pot.id === event.destinationPotId);

    //turn most recent time into integers for comparisons
    const startTime = getNextStartTimeFromLogs(logs);

    // prepare the record to save
    const recordDetail = {
      potId: pot.id,
      date: getDBDateFormat(date),
      startTime: startTime,
      duration: event.hours,
    };
    await this.props.onNewLog(recordDetail);

    // what hour did we start on?
    // lets check if we need to go to the next day
    // note that this means the newStartHour can be 25+
    const hours = parseInt(startTime.split(":")[0]);
    const newStartHour = hours + event.hours;
    if (newStartHour >= 24 || startTime === "23:45") {
      await this.props.onNewDay();
    }

    //on a new drop check to see if the drop changed any current progress values
    await this.fetchCurrentProgressForTargets(
      this.props.time.date,
      this.props.targets.potTargets
    );
  };

  getPotTargets = (pot) => {
    // if there are targets, check if they match this potId
    if (this.props.targets.potTargets) {
      return this.props.targets.potTargets.find((target) => {
        return target.potId === pot.id;
      });
    }
    return null;
  };

  fetchThisPotHoursCountInTheTargetPeriod = async (
    potId,
    targetPeriod,
    date
  ) => {
    let startDate;
    if (targetPeriod === "day") {
      startDate = getDBDateFormat(date);
    } else if (targetPeriod === "week") {
      startDate = getMostRecentMondayBeforeDate(date);
    } else if (targetPeriod === "month") {
      startDate = getBeginningOfTheMonthISODate(date);
    }
    const endDate = getDBDateFormat(date);

    this.props.fetchSpecificPotHoursCount(potId, startDate, endDate);
  };

  fetchCurrentProgressForTargets = async (date, potTargets) => {
    await Promise.all(
      potTargets.map(async (target) => {
        await this.fetchThisPotHoursCountInTheTargetPeriod(
          target.potId,
          target.targetPeriod,
          date
        );
      })
    );
  };

  returnPotCurrentProgressResults = (potId) => {
    if (!this.props.analytics.specificPotHoursCount) {
      return;
    }

    return this.props.analytics.specificPotHoursCount[potId];
  };

  render() {
    if (
      //wait for pots and their targets to be called, unless it is on the landing page
      !this.props.pots.customPots &&
      this.props.targets.potTargets
    ) {
      return (
        <div className="u-mt--32">
          <h3 className="u-main-font--large u-color-white">Loading...</h3>
        </div>
      );
    }

    return (
      <div className="dnd-tracking">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div
            className="u-wrap pots-container"
            style={{ height: this.state.innerWindowHeight }}
          >
            <div className="pots-container__grid">
              {this.props.pots.customPots.map((pot, index) => {
                return pot.finishedStatus ? null : (
                  <Droppable droppableId={pot.id} key={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Pot
                          pot={pot}
                          key={index}
                          targetDetails={this.getPotTargets(pot)}
                          isEditable={true}
                          currentTargetProgress={this.returnPotCurrentProgressResults(
                            pot.id
                          )}
                          // dont add {provided.placeholder} or it is very jumpy for the users
                        />
                      </div>
                    )}
                  </Droppable>
                );
              })}
              <AddNewPotButton />
            </div>
          </div>
          <TimeDrawer />
        </DragDropContext>
      </div>
    );
  }
}

Tracking.propTypes = {
  pots: PropTypes.shape(PotPropTypes).isRequired,
  time: PropTypes.shape(TimePropTypes).isRequired,
  onNewDay: PropTypes.func,
  onNewLog: PropTypes.func,
  fetchDemoPots: PropTypes.func.isRequired,
  fetchDemoPotTargets: PropTypes.func.isRequired,
  fetchDemoTargetsPotHoursCount: PropTypes.func.isRequired,
  fetchPotTargets: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  pots: state.pots,
  targets: state.targets,
  time: state.time,
});

export default connect(mapStateToProps, {
  fetchPotTargets,
  fetchSpecificPotHoursCount,
  fetchDemoPots,
  fetchDemoPotTargets,
  fetchDemoTargetsPotHoursCount,
  getDBDateFormat,
})(Tracking);
