export const REQUESTING = "REQUESTING";
export const GET_ERRORS = "GET_ERRORS";
export const RESET_ERRORS = "RESET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const NEW_USER_SIGNED_UP = "NEW_USER_SIGNED_UP";
export const USER_LOADING = "USER_LOADING";

export const CONFIRM_VALID_PASSWORD_TOKEN = "CONFIRM_VALID_PASSWORD_TOKEN";

export const POT_CREATED = "POT_CREATED";
export const POTS_FETCHED = "POTS_FETCHED";
export const DEMO_POTS_FETCHED = "DEMO_POTS_FETCHED";
export const POT_EDITED = "POT_EDITED";

//for targets
export const POT_TARGET_CREATED = "POT_TARGET_CREATED";
export const POT_TARGETS_FETCHED = "POT_TARGETS_FETCHED";
export const POT_TARGET_EDITED = "POT_TARGET_EDITED";
export const SPECIFIC_POT_HOURS_COUNT = "SPECIFIC_POT_HOURS_COUNT";
export const STREAK_OF_TARGETS = "STREAK_OF_TARGETS";
export const STATUS_OF_TARGETS = "STATUS_OF_TARGETS";

export const UPDATE_LOGS = "UPDATE_LOGS";
export const REMOVE_LOGS = "REMOVE_LOGS";
export const ADD_PAGE_OF_LOGS = "ADD_PAGE_OF_LOGS";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const UPDATE_DATE = "UPDATE_DATE";

export const NOTE_SAVED = "NOTE_SAVED";
export const NOTES_FETCHED = "NOTES_FETCHED";
export const NOTE_EDITED = "NOTE_EDITED";
export const NOTES_DELETED = "NOTES_DELETED";
export const FETCH_PAGE_OF_NOTES = "FETCH_PAGE_OF_NOTES";

//for line chart data showing each day
export const DAILY_POT_HOURS_FETCHED = "DAILY_POT_HOURS_FETCHED";
export const DAILY_POT_TYPE_HOURS_FETCHED = "DAILY_POT_TYPE_HOURS_FETCHED";

//for the pie chart data showing aggregated data
export const THIS_WEEKS_POTS_TOTAL_HOURS = "THIS_WEEKS_POTS_TOTAL_HOURS";
export const THIS_WEEKS_POT_TYPES_TOTAL_HOURS =
  "THIS_WEEKS_POT_TYPES_TOTAL_HOURS";

export const LAST_WEEKS_POTS_TOTAL_HOURS = "LAST_WEEKS_POTS_TOTAL_HOURS";
export const LAST_WEEKS_POT_TYPES_TOTAL_HOURS =
  "LAST_WEEKS_POT_TYPES_TOTAL_HOURS";

export const THIS_MONTHS_POTS_TOTAL_HOURS = "THIS_MONTHS_POTS_TOTAL_HOURS";
export const THIS_MONTHS_POT_TYPES_TOTAL_HOURS =
  "THIS_MONTHS_POT_TYPES_TOTAL_HOURS";

export const LAST_MONTHS_POTS_TOTAL_HOURS = "LAST_MONTHS_POTS_TOTAL_HOURS";
export const LAST_MONTHS_POT_TYPES_TOTAL_HOURS =
  "LAST_MONTHS_POT_TYPES_TOTAL_HOURS";

export const ALL_TIME_POTS_TOTAL_HOURS = "ALL_TIME_POTS_TOTAL_HOURS";
export const ALL_TIME_POT_TYPES_TOTAL_HOURS = "ALL_TIME_POT_TYPES_TOTAL_HOURS";

// For PWAs
export const SET_DEFERRED_PROMPT = "SET_DEFERRED_PROMPT";
