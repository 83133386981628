import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from "recharts";

import { fetchPots } from "../../../actions/potActions.js";
import {
  fetchDailyPotHours,
  fetchDailyPotTypeHours,
  fetchThisWeeksPotsTotalHours,
  fetchThisWeeksPotTypeTotalHours,
  fetchThisMonthsPotsTotalHours,
  fetchThisMonthsPotTypeTotalHours,
  fetchAllTimePotsTotalHours,
  fetchAllTimePotTypesTotalHours,
} from "../../../actions/analyticalActions.js";

import {
  convertDailyPotHoursIntoLineChartData,
  convertDailyPotTypeHoursIntoLineChartData,
  aggregateRunningTotal,
} from "../../../utils/analyticsFunctions";
import { createClone } from "../../../utils/arrayFunctions";
import { potTypes } from "../../../utils/constants";

class ComparisonsChart extends Component {
  state = {
    potTypes: [],
    visiblePotType: { id: "work", name: "Work", colour: "#80EFED" },
  };

  componentDidMount = async () => {
    // //convert api response into linechart shape
    // const potHoursLineChartData = convertDailyPotHoursIntoLineChartData(
    //   this.props.analytics.dailyPotHours,
    //   this.props.pots.customPots
    // );
    //convert api response and save it for later
    const potTypesHoursLineChartData = aggregateRunningTotal(
      this.mergeRecordsOfSameMonth(
        this.seperateDateFields(
          convertDailyPotTypeHoursIntoLineChartData(
            this.props.analytics.dailyPotTypeHours
          )
        )
      )
    );

    this.setState({
      potTypes: createClone(potTypes),
      data: potTypesHoursLineChartData,
    });
  };

  seperateDateFields = (data) => {
    // for each day
    return data.map((record) => {
      // split date and save dd, mm, yyyy
      const [yyyy, mm, dd] = record.date.split("-");

      // append each key with the year
      for (var key of Object.keys(record)) {
        Object.defineProperty(
          record,
          key + yyyy,
          Object.getOwnPropertyDescriptor(record, key)
        );
        delete record[key];
      }

      return {
        ...record,
        yyyy,
        mmdd: mm + dd,
      };
    });
  };

  mergeRecordsOfSameMonth = (data) => {
    let skeleton = [];
    data.map((record) => {
      // find if mmdd is in the skeleton already
      const index = skeleton.findIndex(
        (mmddRecord) => mmddRecord.mmdd === record.mmdd
      );

      if (index > -1) {
        for (var key of Object.keys(record)) {
          skeleton[index][key] = record[key];
        }
      } else {
        skeleton.push(record);
      }
    });

    return skeleton;
  };

  // if a pot or pot type has a value of 0, hide it from the legend
  customLineChartTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div style={{ color: "white", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <p className="intro">{label}</p>
          <div className="label">
            {payload
              .sort((a, b) => {
                return b.value - a.value;
              })
              .map((element) => {
                if (element.value === 0) {
                  return "";
                } else {
                  return (
                    <div>
                      <span>{`${element.name || "Deleted pot"} : ${
                        element.value
                      }`}</span>
                      <br />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      );
    }

    return null;
  };

  showPotTypeData = (potType) => {
    this.setState({ visiblePotType: potType });
  };

  hasMoreThanOneYearsData = () => {
    if (this.state.data) {
      const uniqueYears = [
        ...new Set(this.state.data.map((record) => record.yyyy)),
      ];

      if (uniqueYears.length > 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  render() {
    // if there is only one years worth of data do not show this component
    if (!this.hasMoreThanOneYearsData()) {
      return null;
    }
    return (
      <div className="o-analytics-key-highlights">
        <div className="u-flex">
          <div className="u-main-font--large u-color-white">
            Compare to past year
          </div>
        </div>
        <LineChart
          //to stop overflow of the max
          width={this.props.width}
          height={this.props.height}
          margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          data={this.state.data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="mmdd"
            name="mmdd"
            domain={["auto", "auto"]}
            label={{
              value: "Days Recorded",
              offset: -20,
              position: "insideBottom",
              fill: "white",
              fontSize: "12px",
            }}
            style={{ fontSize: "12px" }}
          />
          <YAxis
            label={{
              value: "Hours",
              angle: -90,
              position: "insideLeft",
              fill: "white",
            }}
            style={{ fontSize: "12px" }}
          />
          <Tooltip content={this.customLineChartTooltip} />
          <Line
            type="monotone"
            dataKey={this.state.visiblePotType.id + "2022"}
            name={"2022"}
            isAnimationActive={false}
            dot={false}
            key={4}
            stroke={this.state.visiblePotType.colour + "99"}
            strokeWidth={4}
          />
          <Line
            type="monotone"
            dataKey={this.state.visiblePotType.id + "2021"}
            name={"2021"}
            isAnimationActive={false}
            dot={false}
            key={3}
            stroke={this.state.visiblePotType.colour + "75"}
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey={this.state.visiblePotType.id + "2020"}
            name={"2020"}
            isAnimationActive={false}
            dot={false}
            key={2}
            stroke={this.state.visiblePotType.colour + "50"}
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey={this.state.visiblePotType.id + "2019"}
            name={"2019"}
            isAnimationActive={false}
            dot={false}
            key={1}
            stroke={this.state.visiblePotType.colour + "25"}
            strokeWidth={3}
          />

          <Brush dataKey="date" gap={1} />
        </LineChart>

        <div className="o-line-chart__buttons-group">
          <div className="o-line-chart__buttons-group__pots">
            {this.state.potTypes.map((potType, index) => {
              const isVisible = this.state.visiblePotType.id === potType.id;
              return (
                <button
                  className="o-button u-m--12 u-main-font--small"
                  style={{
                    backgroundColor: isVisible ? potType.colour : "grey",
                  }}
                  onClick={() => this.showPotTypeData(potType)}
                  key={index}
                >
                  {potType.name}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

ComparisonsChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  auth: state.auth,
  pots: state.pots,
  time: state.time,
});

export default connect(mapStateToProps, {
  fetchPots,
  fetchDailyPotHours,
  fetchDailyPotTypeHours,
  fetchThisWeeksPotTypeTotalHours,
  fetchThisMonthsPotTypeTotalHours,
  fetchThisMonthsPotsTotalHours,
  fetchThisWeeksPotsTotalHours,
  fetchAllTimePotsTotalHours,
  fetchAllTimePotTypesTotalHours,
})(ComparisonsChart);
