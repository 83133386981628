import React, { Component } from "react";
import PropTypes from "prop-types";

import { reduceHexColor } from "../../../utils/potFunctions";

// import {} from "../../utils/propTypes";

class TargetProgressBar extends Component {
  colorOfProgress = (count, targetNumber, adjective, index) => {
    const red = `linear-gradient(25deg, ${reduceHexColor(
      "#ff0000",
      2
    )},  rgb(255,0,0)`;

    const green = `linear-gradient(25deg, ${reduceHexColor(
      "#00FF00",
      2
    )},  rgb(0,255,0)`;

    const grey = `linear-gradient(25deg, ${reduceHexColor(
      "#C0C0C0",
      2
    )},  rgb(192,192,192)`;

    if (count === "earlier than first record") {
      return "white";
    }

    if (adjective === "min") {
      if (targetNumber <= count) {
        // if the count is larger than the target we have already won
        return green;
      } else if (index === 0) {
        // there is still time today to win or fail
        return grey;
      } else {
        // if less than our min target we have failed and there is no time left
        return red;
      }
    } else if (adjective === "max") {
      if (targetNumber < count) {
        // if count is larger than our max target number we have already failed
        return red;
      } else if (index === 0) {
        // there is still time today
        return grey;
      }
      // it is larger than our min target
      return green;
    }
  };

  render() {
    if (!this.props.targets || this.props.targets.length === 0) {
      return null;
    }
    return (
      <div>
        {this.props.targets.map((target) => {
          return (
            <div className="u-flex u-flex--row-reverse u-w-all u-color-white u-block--mobile u-mb--24--mobile">
              <div className="u-flex  u-w-half u-mlauto u-mr--0 u-w-all--mobile">
                <div className="progress-bar__stack u-w-all">
                  {target.progress.map((period, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "stack-unit--first o-hoverover-element"
                            : "stack-unit o-hoverover-element"
                        }
                        key={index}
                        style={{
                          width: "calc(16px + (14%)",
                          background: `${this.colorOfProgress(
                            period.totalDuration,
                            target.targetNumber,
                            target.adjective,
                            index
                          )}`,
                          zIndex: 7 - index,
                        }}
                      >
                        <div className="u-sub-font--small o-hoverover-overlay--target">
                          {typeof period.totalDuration === "string" // only show numbers not text
                            ? null
                            : period.totalDuration}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="u-w-half u-flex u-justify-flex-end u-align-center u-w-all--mobile  u-block--mobile">
                <div className="u-sub-font--small u-text-right u-truncate u-mr--8">
                  A {target.adjective} of {target.targetNumber} hours of{" "}
                  {target.potName}
                </div>
                {/* <div className="u-sub-font--medium u-text-right u-ph--24 u-text-oneline">
                  {this.getStreak(target.targetId)} 🔥
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

TargetProgressBar.propTypes = {
  targets: PropTypes.array.isRequired,
  streaks: PropTypes.array.isRequired,
};

export default TargetProgressBar;
