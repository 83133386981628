import { potTypes } from "../utils/constants";

//fill missing days with 0s not MVP

//Aggregate data
export const aggregateRunningTotal = (inputData) => {
  //all pots and pot types should be in all the inputs days, even if they are zero
  //create a skeleton to return
  let skeleton = [];
  for (let i = 0; i < inputData.length; i++) {
    //find previous records details (if undefined set as zero)
    const previous = skeleton[i - 1];
    // add yesterdays total to todays
    const today = inputData[i];

    //for the first in the array add all custom pots to the skeleton data set
    if (!previous) {
      skeleton.push(today);
    } else {
      let dayRecord = { date: inputData[i].date };

      //for every child of input data[i], find and add the previous value
      Object.keys(inputData[i]).forEach((key) => {
        // if key is a date don't bother aggregating
        if (key === "date") {
          return;
        }
        // if string dont bother
        if (typeof today[key] === "string") {
          return (dayRecord[key] = today[key]);
        }

        // if yesterday there is no value, go back another day
        if (!previous[key]) {
          previous[key] = skeleton[i - 2] ? skeleton[i - 2][key] : NaN;
        }
        // if day before there is no value, go back another day
        if (!previous[key]) {
          previous[key] = skeleton[i - 3] ? skeleton[i - 3][key] : 0;
        }

        const aggregatedTotal = previous[key] + today[key];
        dayRecord[key] = aggregatedTotal;
      });

      //add the aggregated scores for this day to the dataset
      skeleton.push(dayRecord);
    }
  }
  return skeleton;
};

// FOR POT FUNCTIONS ------------

export const convertDailyPotHoursIntoLineChartData = (
  apiOutput,
  allUsersPots
) => {
  return fillDailyPotsHoursToHaveBlanksWhenNotMentioned(
    apiOutput.map((day) => {
      // for every day name the date
      const structure = {
        date: day._id,
      };

      day.pots.forEach((pot) => {
        //add potId as the key, and pot totalDuration as value for every pot in the day output
        structure[pot.potId] = pot.totalDuration;
      });

      return structure;
    }),
    allUsersPots
  );
};

export const fillDailyPotsHoursToHaveBlanksWhenNotMentioned = (
  lineChartInput,
  allUsersPots
) => {
  // for each day in the linechart input
  lineChartInput.forEach((day) => {
    // check every possible pot
    allUsersPots.forEach((customPot) => {
      // if the pot does not exist in the day already
      if (!(customPot.id in day)) {
        // add the key id and set it to be zero
        day[customPot.id] = 0;
      }
    });
  });
  return lineChartInput;
};

// FOR POT TYPE FUNCTIONS -------------
export const convertDailyPotTypeHoursIntoLineChartData = (apiOutput) => {
  return fillDailyPotsTypeHoursToHaveBlanksWhenNotMentioned(
    apiOutput.map((day) => {
      // for every day name the date
      const structure = {
        date: day._id,
      };

      // for each days listed potTypes
      day.potTypes.forEach((potType) => {
        // find the potType
        const matchingPotType = potTypes.find(
          (potTypeDetails) => potTypeDetails.name === potType.type
        );

        // place the id of the pot type in as the key, with the count as the value for the line chart
        if (matchingPotType) {
          structure[matchingPotType.id] = potType.totalDuration;
        }
      });

      return structure;
    })
  );
};

export const fillDailyPotsTypeHoursToHaveBlanksWhenNotMentioned = (
  lineChartInput
) => {
  // for each day in the linechart input
  lineChartInput.forEach((day) => {
    // check every possible pot
    potTypes.forEach((potType) => {
      // if the pot does not exist in the day already
      if (!(potType.id in day)) {
        // add the key id and set it to be zero
        day[potType.id] = 0;
      }
    });
  });
  return lineChartInput;
};
