import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { requestError } from "./errorActions";

import {
  SET_CURRENT_USER,
  USER_LOADING,
  CONFIRM_VALID_PASSWORD_TOKEN,
  NEW_USER_SIGNED_UP,
} from "./types";

import routes from "../utils/routes";
import { identify, track } from "../utils/mixpanel";

export function storeUserLoginDetails(
  token,
  dispatch,
  userData,
  isBrandNewUser
) {
  // Decode token to get user data
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  const tokenHasExpired = decoded.exp < currentTime;

  if (tokenHasExpired) {
    dispatch(logoutUser());
  } else {
    localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);

    identify({
      userId: decoded.id,
      userEmail: userData?.email,
      userName: userData?.name,
      justCreated: isBrandNewUser ? new Date().toISOString() : undefined,
    });
    if (isBrandNewUser) {
      dispatch(setNewUser({ decoded, createdOn: userData.createdOn }));
    } else {
      dispatch(setCurrentUser({ decoded, createdOn: userData?.createdOn }));
    }
  }
}

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/v2/users/register", userData)
    .then((res) => {
      // must add created on date for the popup to show
      userData.createdOn = res.data.createdOn;

      const { token } = res.data;
      storeUserLoginDetails(token, dispatch, userData, true);

      track("create-account");
      history.push(routes.home);
    })
    .catch((err) => dispatch(requestError(err)));
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/v2/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      userData.createdOn = res.data.createdOn;
      storeUserLoginDetails(token, dispatch, userData);
      track("log-in");
    })
    .catch((err) => dispatch(requestError(err)));
};

export const resetPasswordRequestEmail = (email) => (dispatch) => {
  return axios
    .post(`/api/password/reset`, { email })
    .catch((err) => dispatch(requestError(err)));
};

export const checkResetPasswordTokenIsValid = (email, token) => (dispatch) => {
  axios
    .get(`/api/password/validate-reset-token?token=${token}&email=${email}`)
    .then(() => {
      dispatch(confirmValidationOfPasswordToken());
    })
    .catch((err) => dispatch(requestError(err)));
};

export const resetPassword =
  (email, token, password, password2) => (dispatch) => {
    axios
      .patch(`/api/password/reset?token=${token}&email=${email}`, {
        password,
        password2,
      })
      .catch((err) => dispatch(requestError(err)));
  };

// Set logged in user for new signup
export const setNewUser = (data) => {
  return {
    type: NEW_USER_SIGNED_UP,
    payload: data,
  };
};

// Set logged in user
export const setCurrentUser = (data) => {
  return {
    type: SET_CURRENT_USER,
    payload: data,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  window.location.href = routes.home;
};

export const confirmValidationOfPasswordToken = () => {
  return {
    type: CONFIRM_VALID_PASSWORD_TOKEN,
    data: true,
  };
};
