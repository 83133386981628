import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loginUser } from "../../actions/authActions";

import Navbar from "../../components/layout/Navbar";
import BackgroundBlobTop from "../../components/objects/BackgroundBlobTop";
import BackgroundBlobBottom from "../../components/objects/BackgroundBlobBottom";

import routes from "../../utils/routes";
import { track } from "../../utils/mixpanel";

class Login extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
    isSubmitting: false,
  };

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(routes.home);
    }

    track("view-login-page");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push(routes.home);
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    const userInput = e.target.value;
    this.setState({ [e.target.id]: userInput });
  };

  onSubmit = (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
    this.setState({ isSubmitting: false });
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-flex u-overflow-x--hidden u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-relative u-w-all">
          <Navbar hideCta={true} />
          <div className="u-wrap">
            <div className="u-mt--80 u-flex u-align-center u-justify-center">
              <div className="login-form u-pl--48 u-p--0--mobile ">
                <div className="login-form__card o-card">
                  <h2 className="u-main-font--large u-m--32">Welcome Back!</h2>
                  <form noValidate onSubmit={this.onSubmit}>
                    <div className="o-input-field">
                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        id="email"
                        type="email"
                        placeholder="Email"
                        disabled={this.state.isSubmitting}
                      />
                      <span className="u-input-error-text">
                        {errors.data && errors.data.email
                          ? errors.data.email
                          : ""}
                      </span>
                    </div>
                    <div className="o-input-field">
                      <input
                        onChange={this.onChange}
                        value={this.state.password}
                        id="password"
                        type="password"
                        placeholder="Password"
                      />
                      <span className="u-input-error-text">
                        {errors.data && errors.data.password
                          ? errors.data.password
                          : ""}
                      </span>
                    </div>
                    <div className="u-w-all u-mt--16">
                      <div className="u-flex u-justify-center">
                        <button
                          className="o-button u-main-font--small u-justify-center"
                          type="submit"
                        >
                          Log in
                        </button>
                      </div>
                      <div className="u-justify-center u-flex u-sub-font u-color-black u-p--8">
                        <p>
                          <Link to={routes.requestResetPassword}>
                            Forgot your password?
                          </Link>
                        </p>
                      </div>
                      <div className="u-justify-center u-flex u-sub-font u-color-black">
                        <p>
                          Haven't got an account?{" "}
                          <Link to={routes.register}>Register here</Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

// Typescript is now aware of props required as input
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
