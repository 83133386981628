import { SHOW_MODAL, HIDE_MODAL } from "../actions/types";

const initialState = {
  component: undefined,
  data: {},
  status: "hidden",
  className: "",
  position: "centered",
  onClose: "",
  modalStyle: null,
};

export default function modalReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        component: action.component,
        data: action.data,
        status: "visible",
        className: action.className,
        position: action.position,
        onClose: action.onClose,
        modalStyle: action.modalStyle,
      };

    case HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
}
