import { combineReducers } from "redux";

import analyticsReducer from "./analyticsReducers";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import modalReducer from "./modalReducers";
import notesReducer from "./notesReducers";
import potReducer from "./potReducers";
import targetReducer from "./targetReducers";
import timeReducers from "./timeReducers";
import pwaReducers from "./pwaReducers";

export default combineReducers({
  analytics: analyticsReducer,
  auth: authReducer,
  errors: errorReducer,
  modal: modalReducer,
  notes: notesReducer,
  pots: potReducer,
  targets: targetReducer,
  time: timeReducers,
  pwa: pwaReducers,
});
