import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getDBDateFormat } from "../../utils/dateFunctions";

import {
  saveNewNote,
  fetchNotes,
  editNote,
  deleteNote,
} from "../../actions/notesActions";

import { createClone } from "../../utils/arrayFunctions";
import { track } from "../../utils/mixpanel";

class NoteWindow extends Component {
  state = {
    note: "",
    noteExistsInDb: true,
    noteIsSaved: true,
    isFocused: false,
    placeholder: { message: "", index: 0 },
  };

  componentDidMount = async () => {
    //get note for this day if there is one
    this.saveNoteToState();
    this.setState({ placeholder: this.wordingForPlaceholder() });
  };

  componentDidUpdate = (previousProps) => {
    if (this.props.time.date !== previousProps.time.date) {
      this.saveNoteToState();
    }
  };

  wordingForPlaceholder = () => {
    const differentPrompts = [
      "What made you happier today?",
      "Write a quick summary of your day",
      "How was your day?",
      "Write your day reflection here",
    ];

    const index = Math.floor(Math.random() * differentPrompts.length);

    return { message: differentPrompts[index], index };
  };

  saveNoteToState = async () => {
    await this.props.fetchNotes(this.props.time.date);
    //clone it so people can change their message without updating the state directly

    const savedNote = this.props.notes.notes[0]
      ? createClone(this.props.notes.notes[0].note)
      : "";
    this.setState({
      note: savedNote,
      //if note comes from db state it (so we can patch instead of post notes later)
      noteExistsInDb: savedNote ? true : false,
    });
  };

  noteChange = (e) => {
    this.setState({
      note: e.currentTarget.value,
      noteIsSaved: false,
    });
  };

  onKeyPress = (e) => {
    const characterCode = e.charCode || e.keyCode;
    if (characterCode === 13) {
      this.submitDaySummary();
    }
  };

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  submitDaySummary = async () => {
    track(`note-saved--${this.state.placeholder.index}`);
    this.setState({ isFocused: false });
    // prepare the api request body with the date, note and user and then do actions
    const dateDescribed = getDBDateFormat(this.props.time.date);
    const { note, noteExistsInDb, noteIsSaved } = this.state;
    const noteIsEmpty = !note.replace(/\s/g, "").length;

    if (noteExistsInDb) {
      if (noteIsEmpty) {
        // If the user has submitted an empty string, delete the note
        await this.props.deleteNote(dateDescribed);
        this.setState({ noteIsSaved: true });
      } else {
        // If it's not empty, update the current note
        await this.props.editNote({ note, dateDescribed });
        this.setState({ noteIsSaved: true });
      }
    } else {
      if (!noteIsEmpty && !noteIsSaved) {
        // if the note state has been updated, and it's not empty, create a new note
        await this.props.saveNewNote({ note, dateDescribed });
        this.setState({ noteIsSaved: true, noteExistsInDb: true });
      }
    }
  };

  render() {
    return (
      <div
        className="u-w-all u-relative u-m--12"
        style={{ height: this.state.isFocused ? "100px" : "unset" }}
      >
        {this.state.isFocused ? (
          <textarea
            style={{ height: "80px" }}
            className="u-sub-font--small u-font--input u-color-white u-w-all u-bb--light-grey "
            autoFocus
            value={this.state.note}
            onChange={this.noteChange}
            placeholder={this.state.placeholder.message}
            onBlur={this.submitDaySummary}
            onKeyPress={this.onKeyPress}
            onFocus={
              //dirty hack so that cursor sits at the end of any text that is in the box when we click on it
              function (e) {
                var val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }
            }
          />
        ) : (
          <input
            id="note__input"
            className="u-sub-font--vsmall u-font--input u-color-white u-w-all u-bb--light-grey"
            value={this.state.note}
            onChange={this.noteChange}
            placeholder={this.state.placeholder.message}
            onClick={this.onFocus}
          />
        )}
        {
          //notify if note is not saved
          !this.state.noteIsSaved && (
            <p className="o-input__label u-sub-font--vvsmall u-color-light-grey">
              not saved yet
            </p>
          )
        }
        {
          //show errors if there are any
          this.props.errors && (
            <p className="u-sub-font--vvsmall u-color-red">
              {this.props.errors.note}
            </p>
          )
        }
      </div>
    );
  }
}

// Typescript will warn if any props our outside the defined validation
NoteWindow.propTypes = {
  errors: PropTypes.object,
  fetchNotes: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,
  saveNewNote: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notes: state.notes,
  auth: state.auth,
  time: state.time,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  saveNewNote,
  fetchNotes,
  editNote,
  deleteNote,
})(NoteWindow);
