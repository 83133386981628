import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSpecificPotHoursCount } from "../../actions/analyticalActions";
import { hideModal } from "../../actions/modalActions";
import {
  fetchDayRecords,
  saveRecord,
  updateDateToBeNextDay,
} from "../../actions/timeActions";
import {
  getBeginningOfTheMonthISODate,
  getDBDateFormat,
  getMostRecentMondayBeforeDate,
  getNextStartTimeFromLogs,
} from "../../utils/dateFunctions";

class ExpandedPotTimeBlock extends Component {
  saveTime = async () => {
    // adapted from tracking.js

    const { potId } = this.props;

    //turn most recent time into integers for comparisons
    const startTime = getNextStartTimeFromLogs(this.props.time.logs);

    // prepare the record to save
    const recordDetail = {
      potId,
      date: getDBDateFormat(this.props.time.date),
      startTime: startTime,
      duration: this.props.block.value,
    };

    await this.props.saveRecord(recordDetail);
    // Refetch day records to populate the progress bar
    await this.props.fetchDayRecords({
      date: this.props.time.date,
    });

    // what hour did we start on?
    // lets check if we need to go to the next day
    // note that this means the newStartHour can be 25+
    const hours = parseInt(startTime.split(":")[0]);
    const newStartHour = hours + this.props.block.value;
    if (newStartHour >= 24 || startTime === "23:45") {
      await this.props.updateDateToBeNextDay(this.props.time.date);
      await this.props.fetchDayRecords({
        date: this.props.time.date,
      });
    }

    //on a new recording check to see if any current progress values
    await this.fetchCurrentProgressForTargets(
      this.props.time.date,
      this.props.targets.potTargets
    );

    await this.props.hideModal();
  };

  fetchCurrentProgressForTargets = async (date, potTargets) => {
    await Promise.all(
      potTargets.map(async (target) => {
        await this.fetchThisPotHoursCountInTheTargetPeriod(
          target.potId,
          target.targetPeriod,
          date
        );
      })
    );
  };

  fetchThisPotHoursCountInTheTargetPeriod = async (
    potId,
    targetPeriod,
    date
  ) => {
    let startDate;
    if (targetPeriod === "day") {
      startDate = getDBDateFormat(date);
    } else if (targetPeriod === "week") {
      startDate = getMostRecentMondayBeforeDate(date);
    } else if (targetPeriod === "month") {
      startDate = getBeginningOfTheMonthISODate(date);
    }
    const endDate = getDBDateFormat(date);

    this.props.fetchSpecificPotHoursCount(potId, startDate, endDate);
  };

  render() {
    return (
      <button
        className="block__card u-m--12 "
        style={{
          width: "40%",
          boxShadow: "5px 5px black",
          border: "1px solid",
        }}
        onClick={this.saveTime}
      >
        <span className="u-hide--mobile">{this.props.block.content}</span>
        <span className="u-hide--tabletAndDesktop">
          {this.props.block.value}
        </span>
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  pots: state.pots,
  time: state.time,
  targets: state.targets,
});

export default connect(mapStateToProps, {
  hideModal,
  saveRecord,
  fetchDayRecords,
  updateDateToBeNextDay,
  fetchSpecificPotHoursCount,
})(ExpandedPotTimeBlock);
