import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class NameInput extends Component {
  render() {
    return (
      <div className="u-mb--12--mobile u-mb--32">
        <label
          htmlFor="pot-name"
          className="u-sub-font--vsmall u-color-light-grey u-flex"
        >
          Pot name
        </label>
        <div className="u-flex u-align-center u-mb--4--mobile u-mt--8">
          <input
            value={this.props.name}
            id="pot-name"
            type="text"
            placeholder="Name your pot"
            className=" o-input-field u-sub-font--small u-color-white u-w-all"
            onChange={this.props.nameChange}
          />
        </div>
        {this.props.errors.data && this.props.errors.data.name && (
          <small className="u-sub-font--vsmall u-color-red u-mt--8">
            {this.props.errors.data.name}
          </small>
        )}
      </div>
    );
  }
}

NameInput.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string,
  nameChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {})(NameInput);
