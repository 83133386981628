import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  targetPeriodTypes,
  targetAdjectiveTypes,
} from "../../../utils/constants";
import { PotTargetPropTypes } from "../../../utils/propTypes.js";
import { Icon } from "../../objects/Icon";

class TargetInput extends Component {
  render() {
    return (
      <div className="u-mb--12--mobile u-mb--32">
        <label
          htmlFor="pot-target"
          className="u-sub-font--vsmall u-color-light-grey u-flex"
        >
          Pot target
        </label>
        <div className="u-flex u-align-center u-mb--4--mobile u-mt--8">
          <select
            className="u-sub-font--small u-color-white u-bb--light-grey o-select-options u-mr--8 u-mr--4--mobile"
            onChange={this.props.targetAdjectiveChange}
          >
            {targetAdjectiveTypes.map((adjectiveType) => (
              <option
                selected={
                  this.props.potTargetDefinition &&
                  adjectiveType ===
                    this.props.potTargetDefinition.targetAdjective
                }
                value={adjectiveType}
              >
                {adjectiveType}
              </option>
            ))}
          </select>

          <input
            id="pot-target"
            type="number"
            inputmode="numeric"
            min="0"
            pattern="[0-9]*"
            value={this.props.potTargetDefinition.targetNumber}
            className="u-sub-font--small u-color-white u-w-all u-text-align-center u-bb--light-grey u-m--4 u-p--1"
            placeholder={8}
            onChange={this.props.targetNumberChange}
          />

          <span className="u-sub-font--small u-color-white u-text-oneline">
            hours per
          </span>

          <select
            className="u-sub-font--small u-color-white u-bb--light-grey o-select-options u-ml--8 u-ml--4--mobile"
            onChange={this.props.targetPeriodChange}
          >
            {targetPeriodTypes.map((periodType) => (
              <option
                selected={
                  this.props.potTargetDefinition &&
                  periodType === this.props.potTargetDefinition.targetPeriod
                }
                className=""
                value={periodType}
              >
                {periodType}
              </option>
            ))}
          </select>
          {/* if the pot target number === 0 then you wont be able to delete it for now */}
          {this.props.potTargetDefinition.targetNumber ? (
            <button
              type="button"
              className="o-button--transparent u-mh--4 u-color-white"
              onClick={() => this.props.deleteTargetFunction()}
            >
              <Icon id="clear" size="m" />
            </button>
          ) : null}
        </div>

        <div className="u-sub-font--vsmall u-color-grey u-mb--4--mobile u-mt--8">
          {
            //if the user hasn't got a target tell them as the place holder values could confuse
            this.props.potTargetIsNew && !this.props.potTargetHasChanged
              ? `No target is set for this pot currently`
              : `Your ${
                  this.props.potTargetHasChanged ? "updated" : "current"
                } target is to do a
          ${this.props.potTargetDefinition.targetAdjective} of 
          ${this.props.potTargetDefinition.targetNumber} hours per 
          ${this.props.potTargetDefinition.targetPeriod}`
          }
        </div>
        {this.props.errors.data && this.props.errors.data.targetNumber && (
          <small className="u-sub-font--vsmall u-color-red u-mt--4">
            {this.props.errors.data.targetNumber}
          </small>
        )}
      </div>
    );
  }
}

TargetInput.propTypes = {
  errors: PropTypes.object.isRequired,
  potTargetIsNew: PropTypes.bool,
  potTargetHasChanged: PropTypes.bool,
  potTargetDefinition: PropTypes.shape(PotTargetPropTypes).isRequired,
  targetNumberChange: PropTypes.func,
  targetAdjectiveChange: PropTypes.func,
  deleteTargetFunction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {})(TargetInput);
