import mixpanel from "mixpanel-browser";

// get access to REACT_APP_ variables
require("dotenv").config();

const debugOnly = process.env.REACT_APP_MIXPANEL_ENABLED === "false";
const consoleLogMixpanel =
  process.env.REACT_APP_MIXPANEL_LOGGING_ENABLED === "true";

export function init() {
  if (debugOnly) {
    if (consoleLogMixpanel) {
      console.log("init", process.env.REACT_APP_MIXPANEL_TOKEN);
    }
    return;
  }

  if (!process.env.REACT_APP_MIXPANEL_TOKEN) {
    console.log("Mixpanel disabled - no token provided");
    return;
  }

  mixpanel.init(
    process.env.REACT_APP_MIXPANEL_TOKEN,
    { api_host: "https://api-eu.mixpanel.com" },
    ""
  );
}

export function track(eventString, data) {
  if (debugOnly) {
    if (consoleLogMixpanel) {
      console.log("track", eventString);
    }
    return;
  }

  mixpanel.track(eventString, data);
}

export function identify({ userId, userEmail, userName, justCreated }) {
  if (debugOnly) {
    if (consoleLogMixpanel) {
      console.log("identify", userId, userEmail, userName, justCreated);
    }
    return;
  }

  mixpanel.identify(userId);

  if (justCreated) {
    mixpanel.people.set({ $created: new Date().toISOString() });
  }

  if (userEmail) {
    mixpanel.people.set({
      $email: userEmail,
    });
  }

  if (userName) {
    mixpanel.people.set({
      $first_name: userName,
    });
  }
}

export function optOut() {
  if (!debugOnly) {
    mixpanel.opt_out_tracking();
  }
}

export function optIn() {
  if (!debugOnly) {
    mixpanel.opt_in_tracking();
  }
}

export function hasOptedIn() {
  return debugOnly ? true : mixpanel.has_opted_in_tracking();
}

export function hasOptedOut() {
  return debugOnly ? false : mixpanel.has_opted_out_tracking();
}
