// extracted these as they are used in multiple places
// but this means we need to link the state of the component so we need to pass in self as this

export const nameChange = (e, self) => {
  self.setState({
    potDefinitionHasChanged: true,
    potDefinition: {
      ...self.state.potDefinition,
      name: e.currentTarget.value,
    },
  });
};

export const typeChange = (e, self) => {
  self.setState({
    potDefinitionHasChanged: true,
    potDefinition: {
      ...self.state.potDefinition,
      type: e.currentTarget.value,
    },
  });
};

export const colourChange = (e, self) => {
  self.setState({
    potDefinitionHasChanged: true,
    potDefinition: {
      ...self.state.potDefinition,
      colour: e.currentTarget.value,
    },
  });
};

export const targetAdjectiveChange = (e, self) => {
  e.preventDefault();
  self.setState({
    potTargetHasChanged: true,
    potTargetDefinition: {
      ...self.state.potTargetDefinition,
      targetAdjective: e.currentTarget.value,
    },
  });
};

export const targetNumberChange = (e, self) => {
  e.preventDefault();
  self.setState({
    potTargetHasChanged: true,
    potTargetDefinition: {
      ...self.state.potTargetDefinition,
      targetNumber: e.currentTarget.value,
    },
  });
};

export const targetPeriodChange = (e, self) => {
  self.setState({
    potTargetHasChanged: true,
    potTargetDefinition: {
      ...self.state.potTargetDefinition,
      targetPeriod: e.currentTarget.value,
    },
  });
};
