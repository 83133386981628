import React, { Component } from "react";

import Navbar from "../../../components/layout/Navbar.js";
import BackgroundBlobTop from "../../../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../../../components/objects/BackgroundBlobBottom.js";
import Footer from "../../../components/layout/Footer.js";
import LinkButton from "../../../components/objects/LinkButton.js";

import { suggestedArticles } from "../blogPostsDirectory.js";

class YouShould extends Component {
  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden u-z-index---1">
          <BackgroundBlobTop />
          <Navbar fixedPosition={true} />
          <div className="u-w-all u-overflow--hidden">
            <img
              className="o-banner-image"
              src="https://images.unsplash.com/photo-1620404426680-fa32422d7882?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=976&q=80"
            />
          </div>
          <div className="u-w-all u-wrap">
            <div className=" u-align-center u-mt--16">
              <div className="u-m0auto u-color-white u-sub-font--small u-blog-text">
                <div className="u-text-center">
                  <div className="u-sub-font--large">
                    How to track every hour of your day
                  </div>
                  <h4 className="u-sub-font--small u-italic u-mt--32">
                    I started in 2018. I haven't stopped. Learn from my
                    mistakes.
                  </h4>
                </div>
                <div className="u-w-all u-overflow--hidden u-mt--32">
                  <img
                    className="o-banner-image--inset"
                    src="https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1842&q=80"
                  />
                </div>
                <div className="u-p--12 u-sub-font--vsmall">
                  NOTE: This write up is intentionally exhaustive as to help
                  anyone who is struggling, I do not expect many people to do
                  all of this — but I do hope it helps structure how to improve.
                  As a summary:
                  <ul>
                    <li>Use time tracking to establish your baseline</li>
                    <li>
                      Make predictions and test them (I spend 20% of my time
                      doing X)
                    </li>
                    <li>
                      Create goals for your year - what do you want to
                      accomplish?
                    </li>
                    <li>
                      Create targets that would lead you there that are also
                      measurable
                    </li>
                  </ul>
                </div>

                <h3 className="u-sub-font--medium u-mt--12">
                  Record only what is important to you
                </h3>
                <p>
                  We live in a world where it is possible to track any metric
                  about your life — to some success. Not all of these are
                  actionable though.
                </p>
                <p>
                  For me, coming up with targets and hypotheses helps me
                  structure my pots where I put my time and learn from tracking
                  them.
                </p>
                <p>
                  Additionally the simple act of recording makes me feel
                  accountable for what I am doing.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">
                  Get some baseline stats
                </h3>
                <p>
                  It’s hard to know where to go if you don’t know where you are.
                </p>
                <p>
                  Recording just two days of your life as you already act can be
                  insightful. In doing it, you may spot time sinks that you want
                  to change.
                </p>
                <p>
                  Be detailed or be general it is up to you but just make sure
                  you can recall what you did in each hour when you record (for
                  some this means each hour note it down, for others each
                  evening works).
                </p>
                <p>
                  If your time is “bity” record the general intent of the hour.
                  For example, when I am making lunch but also on a work call I
                  would classify that as working. When I am getting up, making
                  and eating breakfast, looking at my phone and setting up my
                  desk, I group all that time as ‘admin and chores’ or ‘morning
                  routine’.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">Hypotheses</h3>
                <p>
                  A good example is work. Before tracking I looked at work as
                  generally things I needed to do for my own benefit going
                  foreward. I never asked "Do I like my job?". Now I personally
                  assess every hour of work into 4 pots.
                </p>

                <img src="https://cdn-images-1.medium.com/max/800/1*FWfhjfDpN323o0gwDfWbkg.png" />
                <ul>
                  <li>Joyful work — the work I want to be doing</li>
                  <li>
                    Developing work — learning and skills and challenges that I
                    may not love but do improve me
                  </li>
                  <li>
                    Grim work — work I hate, that is tedious and unhelpful
                  </li>
                  <li>
                    Just work — not everything is joyous or useful, some things
                    are just work and that’s fine but numbing
                  </li>
                </ul>
                <p>
                  This may feel extreme but I started me understanding when I
                  was wasting my time and helped me optimise for better work to
                  enjoy.
                </p>

                <p>
                  I recommened you make a list of hypotheses about your time. To
                  help you start here are some random ones — don’t create a lot
                  of these, we should be specific:
                </p>
                <ul>
                  <li>I think half my work time is joyful.</li>
                  <li>
                    I think I spend as much time with friends as I do chilling
                    alone.
                  </li>
                  <li>I sleep for 8 hours each day.</li>
                  <li>What I eat rarely affects my next day.</li>
                  <li>Over 50 hours of work a week stresses me out.</li>
                </ul>
                <p>Break each hypothesis into things you can measure</p>
                <ul>
                  <li>
                    I will measure how I feel at work - joyful, grim, boring,
                    learning.
                  </li>
                  <li>
                    I will compare hours relaxing alone to time recorded with
                    friends.
                  </li>
                  <li>
                    I will record when I sleep and when I am in bed but awake.
                  </li>
                  <li>
                    I will keep a food diary and track how productive I feel on
                    the day
                  </li>
                  <li>
                    I will each day record how stressed I feel and also measure
                    how much I work.
                  </li>
                </ul>
                <p>
                  Doing this will give you a good opportunity to learn about
                  yourself.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">
                  Think of your long term goals
                </h3>
                <p>What do you want to complete or start this year?</p>
                <p>
                  Try to make them achievable — ie not “do more” but “do double
                  the number of hours”, or “hit 2 hours a week” (aka 100 hours
                  this year), or complete X.
                </p>
                <div>
                  <b>
                    Write your goals down and think about how you will happily
                    say you achieve them
                  </b>
                </div>
                <ul>
                  <li>
                    <strong class="markup--strong markup--pre-strong">
                      Read six books this year
                    </strong>{" "}
                    — are you interested to record time to each book or will one
                    pot for reading do?{" "}
                  </li>
                  <li>
                    <strong class="markup--strong markup--pre-strong">
                      Exercise for 4 hours a week
                    </strong>{" "}
                    — do you have a target event or goal
                  </li>
                  <li>
                    <strong class="markup--strong markup--pre-strong">
                      Build my first version of my website/project/design.
                    </strong>
                  </li>
                  <li>
                    <strong class="markup--strong markup--pre-strong">
                      Get a new job{" "}
                    </strong>
                    - will you record how much time you are browsing for jobs
                    and how much time is spent actually applying?{" "}
                  </li>
                  <li>
                    <strong class="markup--strong markup--pre-strong">
                      Run a marathon{" "}
                    </strong>
                    - recording planning and prepping, as well as training or
                    should that all go into one pot?
                  </li>
                </ul>
                <h3>Think about what you regularly do</h3>
                <p>
                  Some things can’t be avoided. Everyone must sleep, eat and pay
                  taxes…
                </p>
                <p>
                  You will likely need time for doing admin like activities. The
                  same with chores.
                </p>
                <p>
                  They are unlikely to be things you will have goals for doing,
                  but they may be things you’ll be interested to compare with.
                  Ie, I have spent more time cleaning than I have listening to
                  Podcasts. Can I do both?
                </p>
                <h3>Finally, consider these categories:</h3>

                <img
                  className="u-w-all"
                  src="https://cdn-images-1.medium.com/max/800/1*seQLMtmlUY6cb_BlmOLxoQ.png"
                />

                <p>
                  Is there anything missing from your list that would go in
                  here?
                </p>
                <h3>
                  You should have a list now of what you want to categories by
                </h3>
                <p>
                  Here are the first pots I created when I started, for
                  inspiration. Notice how I never prioritised hobbies. This is
                  completely different now.
                </p>

                <img
                  className="u-w-all"
                  src="https://cdn-images-1.medium.com/max/800/1*EMrud-_zvv5WUb_UrIuSpA.png"
                />

                <h3 className="u-sub-font--medium u-mt--32">And finally...</h3>
                <img
                  className="u-w-all"
                  src="https://cdn-images-1.medium.com/max/2560/1*9dI5XgFdSxuIly7slWs_Aw.png"
                />
                <br />

                <h3 className="u-sub-font--medium u-mt--32">
                  … and you will do more of it
                </h3>
                <p>
                  Automation makes things easier but so do other things. If you
                  want to make a habit remove barriers to do it.
                </p>
                <p>
                  <strong class="markup--strong markup--p-strong">
                    Story:{" "}
                  </strong>
                  I was training for a triathlon so I brought cycle rides inside
                  and did them early in the morning with a film set up ready to
                  watch. Less fussing, no going outside and getting cold. It is
                  easier to do as less effort is required.
                </p>
                <p>
                  Finding a time to record is important too but not
                  dramatically. I tend to record each time I unlock my phone but
                  I started recording it when I first opened my laptop in the
                  morning originally, then I was recording as I got into bed.
                  Now it is natural to do it whenever I think of it - which is
                  multiple times a day.
                </p>
                <p>
                  And I started wearing a watch, so I was more aware of the
                  time. It helped make mental notes of what I was doing at what
                  time.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">Value your time</h3>
                <div className=" u-flex u-justify-center">
                  <img
                    className="u-w-33pct"
                    src="https://cdn-images-1.medium.com/max/600/1*uxIDFFXu0ipu0qpH98_0vQ.png"
                  />
                </div>
                <p>
                  Tracking should take time. It should be simple and easy. I
                  takes me less than 3 minutes a day to record my time in total.
                </p>

                <img
                  className="u-w-all u-mt--32"
                  src="https://cdn-images-1.medium.com/max/2560/1*bzDwSzau117nZGxcnqSw-g.png"
                />

                <p>
                  For me, I used a spreadsheet for a year before I started to
                  look for apps. When I used timer apps I hated them as I felt
                  the timers ticking up put stress on me eating seconds setting
                  them flutter on by.
                </p>
                <p>
                  I ended up back at the spreadsheet. It let me do my own data
                  crunching and was complete free (in cost and ability).
                </p>
                <p>But spreadsheets are a pain to use on mobile.</p>
                <p>Instead I built everyhour for myself, and you can use it!</p>
                <p>
                  If you would rather use a spreadsheet here is my original
                  template (which you will need to tailor for your own work).
                  But I will bet that if you start, you will want to use
                  everyhour for all the same issues I came across.
                </p>
                <h4>My original spreadsheet:</h4>
                <p>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1OZlQG-h6HM8-WFfjkA9tRQZbyAuPpOZcG1exnNMXlyI/edit?usp=sharing"
                    data-href="https://docs.google.com/spreadsheets/d/1OZlQG-h6HM8-WFfjkA9tRQZbyAuPpOZcG1exnNMXlyI/edit?usp=sharing"
                    class="markup--anchor markup--p-anchor"
                    rel="nofollow noopener"
                    target="_blank"
                  >
                    https://docs.google.com/spreadsheets/d/1OZlQG-h6HM8-WFfjkA9tRQZbyAuPpOZcG1exnNMXlyI/edit?usp=sharing
                  </a>
                </p>

                <h3 className="u-sub-font--medium u-mt--32">
                  Get this superpower?
                </h3>
                <p>
                  I started tracking with a spreadsheet. Feel free too. I built
                  everyhour so that you don't have to. You can record easily on
                  any device. You can customised it as much as you want, with as
                  many categories as you want. You can view analytics on your
                  time in one click.
                  <br />
                  <br />
                  You can sign up here:
                  <div className="u-flex u-justify-center u-mv--24">
                    <LinkButton type="Register" text="Sign up" color="purple" />
                  </div>
                  <p>
                    If you are struggling with setting up a way for you to track
                    time, please give me a message!
                  </p>
                </p>
              </div>
              <h3 className="u-sub-font--medium u-mt--32 u-color-white u-text-center">
                Read more...
              </h3>
              {suggestedArticles()}
            </div>
            <Footer />
          </div>
          <BackgroundBlobBottom />
        </div>
      </div>
    );
  }
}

export default YouShould;
