import axios from "axios";

import { demoPots } from "../utils/constants";
import { requestError } from "./errorActions";

import { POT_CREATED, POTS_FETCHED, POT_EDITED } from "./types";

export const saveNewPot = (potData) => (dispatch) => {
  return axios
    .post(`/api/v2/users/pots`, potData)
    .then((res) => {
      dispatch(saveNewPotDetails(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const saveNewPotDetails = (data) => {
  return {
    type: POT_CREATED,
    pot: data,
  };
};

export const fetchPots = () => (dispatch) => {
  // get all pots for specific user
  return axios
    .get(`/api/v2/users/pots`)
    .then((res) => {
      dispatch(fetchedPots(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const fetchDemoPots = () => (dispatch) => {
  dispatch(fetchedPots(demoPots));
};

export const fetchedPots = (data) => {
  return {
    type: POTS_FETCHED,
    pots: data,
  };
};

export const editPotDetails = (editedPotDetails) => (dispatch) => {
  const fieldsAllowingChange = {
    name: editedPotDetails.name,
    type: editedPotDetails.type,
    colour: editedPotDetails.colour,
    finishedStatus: editedPotDetails.finishedStatus,
  };
  return axios
    .patch(`/api/v2/users/pots/${editedPotDetails.id}`, fieldsAllowingChange)
    .then(async (res) => {
      dispatch(changedPotDetails(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const changedPotDetails = (data) => {
  return {
    type: POT_EDITED,
    potChanges: data,
  };
};

export const updateOrderOfPots = (orderedPots) => (dispatch) => {
  return axios
    .patch(`/api/v2/users/pots/reorder`, orderedPots)
    .then(async (res) => {
      fetchPots();
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
