import { colours, potTypes } from "./constants";

export const findFontAndBackgroundColour = (potColour) => {
  return (
    Object.values(colours).find(
      (colour) =>
        // check if pot has a name that we have defined
        colour.hex === potColour ||
        // or check if the colour is defined as part of our suggested colours and use that
        colour.hex === potColour.hex
    ) || {
      // or if the potColour is old and custom just use its value
      hex: potColour,
      fontOverlayHex: colours.white.hex,
    }
  );
};

export const getColourOfPotType = (name) => {
  const potTypeForEntry = potTypes.find((potType) => {
    return potType.name === name;
  });

  if (potTypeForEntry) {
    return potTypeForEntry.colour;
  }

  return colours.grey.hex;
};

export const reduceHexColor = (hex, factor) => {
  // from https://css-tricks.com/converting-color-spaces-in-javascript/
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];

    // 6 digits
  } else if (hex.length === 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }

  return "rgb(" + +r / factor + "," + +g / factor + "," + +b / factor + ")";
};
