import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { resetPasswordRequestEmail } from "../../actions/authActions";

import Navbar from "../../components/layout/Navbar";
import BackgroundBlobTop from "../../components/objects/BackgroundBlobTop";
import BackgroundBlobBottom from "../../components/objects/BackgroundBlobBottom";

import routes from "../../utils/routes";

class AskToResetPassword extends Component {
  state = {
    email: "",
    errors: {},
    isPasswordResetEmailSent: false,
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.props.resetPasswordRequestEmail(this.state.email.toLowerCase());
    if (!Object.keys(this.props.errors).length) {
      this.setState({ isPasswordResetEmailSent: true });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-flex u-overflow-x--hidden u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-wrap u-relative">
          <Navbar hideCta={true} />
          <div className="u-mt--80 u-flex u-align-center u-justify-center">
            <div className="login-form  u-pl--48 u-p--0--mobile  ">
              <div className="login-form__card o-card">
                {this.state.isPasswordResetEmailSent ? (
                  <div>
                    <h2 className="u-main-font--medium u-mb--24">
                      {" "}
                      Password Reset Email has been sent{" "}
                    </h2>
                    <h4 className="u-sub-font--small u-mb--24">
                      Please open the link in the email to change your password
                    </h4>
                  </div>
                ) : (
                  <div>
                    <h2 className="u-main-font--medium u-mb--24">
                      We'll send a recovery link
                    </h2>
                    <form noValidate onSubmit={this.onSubmit}>
                      <div className="o-input-field">
                        <input
                          className="u-main-font--small"
                          onChange={this.onChange}
                          value={this.state.email}
                          id="email"
                          type="email"
                          placeholder="Email"
                        />
                        <span className="u-input-error-text">
                          {errors.data && errors.data.email
                            ? errors.data.email
                            : ""}
                        </span>
                      </div>
                      <div className="u-w-all u-mt--16">
                        <div className="u-flex u-justify-center">
                          <button
                            className="o-button u-main-font--small u-justify-center"
                            type="submit"
                          >
                            Send reset password link
                          </button>
                        </div>
                        <div className="u-justify-center u-flex u-sub-font u-color-black u-p--8">
                          <p>
                            <Link to={routes.login}>Log in</Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

// Typescript is now aware of props required as input
AskToResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { resetPasswordRequestEmail })(
  AskToResetPassword
);
