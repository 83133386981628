import {
  POT_TARGETS_FETCHED,
  POT_TARGET_CREATED,
  STREAK_OF_TARGETS,
  STATUS_OF_TARGETS,
} from "../actions/types";

const initialState = {
  targetStatus: [],
};

export default function targetReducers(state = initialState, action) {
  switch (action.type) {
    case POT_TARGET_CREATED:
      return {
        ...state,
        potTargets: [...state.potTargets, action.potTargets],
      };
    case POT_TARGETS_FETCHED:
      return {
        ...state,
        potTargets: action.potTargets,
      };
    case STREAK_OF_TARGETS:
      return {
        ...state,
        targetStreaks: action.streaks,
      };
    case STATUS_OF_TARGETS:
      return {
        ...state,
        targetStatus: action.status,
      };
    default:
      return state;
  }
}
