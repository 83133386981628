import React, { Component } from "react";
import { Link } from "react-router-dom";

import routes from "../../../utils/routes";

class ReorderSettingsContent extends Component {
  render() {
    return (
      <div className="u-flex u-align-center u-text-unselectable">
        <div className="u-sub-font--small u-color-white">
          Change how you see your pots
          <div className="u-sub-font--vsmall u-color-grey u-mt--8 u-mb--16 ">
            Drag and drop your pots in to an order that makes sense to you.
          </div>
        </div>

        <Link
          to={routes.reorder}
          className="u-right--0 u-mlauto u-main-font--vsmall o-button--green"
        >
          Reorder your pots
        </Link>
      </div>
    );
  }
}

export default ReorderSettingsContent;
