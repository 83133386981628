import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { requestError } from "../../actions/errorActions";
import { undoRecords } from "../../actions/timeActions";
import { updateDateSelection } from "../../actions/timeActions.js";

import { AuthPropTypes, TimePropTypes } from "../../utils/propTypes";

class UndoButton extends Component {
  undoLastAction = async () => {
    try {
      await this.props.undoRecords(1, this.props.time.date);
    } catch (e) {
      this.props.requestError(e);
    }
  };

  render() {
    const { className = "" } = this.props;
    return (
      <div className={className}>
        <button
          onClick={this.undoLastAction}
          className="o-button u-main-font--small"
        >
          Undo
        </button>
      </div>
    );
  }
}

UndoButton.propTypes = {
  className: PropTypes.string,
  time: PropTypes.shape(TimePropTypes).isRequired,
  auth: PropTypes.shape(AuthPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  time: state.time,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  requestError,
  undoRecords,
  updateDateSelection,
})(UndoButton);
