import { GET_ERRORS, RESET_ERRORS } from "./types";

export const requestError = (err) => (dispatch) => {
  // distingush between internal and external error
  if (err.response) {
    return dispatch({
      type: GET_ERRORS,
      data: err.response.data,
      message: err.message,
    });
  } else {
    return dispatch({
      type: GET_ERRORS,
      data: err,
      message: err.message,
    });
  }
};

export const resetErrors = () => {
  return {
    type: RESET_ERRORS,
    payload: {},
  };
};
