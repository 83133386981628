import { SHOW_MODAL, HIDE_MODAL } from "./types";

export const showModal = ({
  component,
  data,
  position = "centered",
  className,
  onClose,
  modalStyle,
}) => {
  return {
    type: SHOW_MODAL,
    component,
    data,
    position,
    className,
    onClose,
    modalStyle,
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
