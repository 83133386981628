import React, { Component } from "react";

import Navbar from "../components/layout/Navbar.js";
import DashboardTracking from "../components/layout/DashboardTracking.js";

import { track } from "../utils/mixpanel.js";
import BackgroundBlobTop from "../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../components/objects/BackgroundBlobBottom.js";

class Track extends Component {
  componentDidMount() {
    track("open-tracking");
  }

  render() {
    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-overflow--hidden u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-relative">
          <Navbar />
          <DashboardTracking />
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

Track.propTypes = {};

export default Track;
