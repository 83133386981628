import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { saveNewPot } from "../../actions/potActions.js";
import { onboardingPots, onboardingSelectedPots } from "../../utils/constants";
import { getHeightOfElementWithId } from "../../utils/UIFunctions";

import Pot from "./Pot.js";
import { Redirect } from "react-router";
import routes from "../../utils/routes.js";

class OnboardingPotCreation extends Component {
  state = {
    // Preselected pots should be highlighted from a variety of others
    selectedPots: [],
    potsWindowHeight: null,
  };

  componentDidMount = () => {
    // To make pot container height be dynamic on mobile and desktop
    const textContent = getHeightOfElementWithId(
      "onboardingTextContent",
      document
    );
    const onboardingPotsSubmission = getHeightOfElementWithId(
      "onboardingPotsSubmission",
      document
    );
    const potsWindowHeight =
      window.innerHeight - (textContent + onboardingPotsSubmission);

    this.setState({
      potsWindowHeight,
      selectedPots: onboardingSelectedPots,
      disabledSubmitButton: true,
    });
  };

  toggleSelectedPotsState(pot) {
    // check if the pot already exists in the selected pots array
    const indexOfPot = this.state.selectedPots.findIndex((selectedPot) => {
      return selectedPot.id === pot.id;
    });

    if (indexOfPot < 0) {
      // add if not in isHidden
      this.setState({
        selectedPots: [...this.state.selectedPots, pot],
      });
    } else {
      // remove if is in isHidden
      const newSelectedPotsState = this.state.selectedPots;
      newSelectedPotsState.splice(indexOfPot, 1);

      this.setState({
        selectedPots: newSelectedPotsState,
      });
    }

    if (this.state.selectedPots.length > 8) {
      this.setState({ disabledSubmitButton: false });
    } else {
      this.setState({ disabledSubmitButton: true });
    }
  }

  colorOfPot = (pot) => {
    // if it is selected return the color
    if (
      this.state.selectedPots.some(
        (selectedPot) => selectedPot.name === pot.name
      )
    ) {
      return pot.colour.hex;
    } else {
      return "#00000000";
    }
  };

  renderPickablePots = (pots) => {
    return (
      <div
        className="pots-container__grid"
        style={{ height: this.state.potsWindowHeight + "px" }}
      >
        {pots.map((pot) => {
          const potColor = this.colorOfPot(pot);
          return (
            <div className="">
              <Pot
                pot={{
                  name: pot.name,
                  colour: potColor,
                  type: pot.type,
                }}
                onboardingSelected={potColor === "#00000000" ? false : true}
                isEditable={false}
                onClickOfPot={() => this.toggleSelectedPotsState(pot)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  async submitForPots() {
    this.setState({ disabledSubmitButton: true });
    try {
      await Promise.all(
        this.state.selectedPots.map(async (pot) => {
          const potDetail = {
            name: pot.name,
            colour: pot.colour.hex,
            type: pot.type,
          };

          await this.props.saveNewPot(potDetail);
        })
      );
    } catch (err) {
      this.setState({ disabledSubmitButton: false });
      return;
    }

    this.setState({ disabledSubmitButton: false, potsAreSubmitted: true });
  }

  render() {
    if (this.state.potsAreSubmitted) {
      return <Redirect push to={routes.home} />;
    }
    return (
      <div className="u-wrap">
        <div className="dnd-tracking ">
          <div className="u-ph--32 u-text-center pots-container--onboarding">
            {this.renderPickablePots(onboardingPots)}
          </div>
        </div>

        <div
          className="u-blue-background submit-pots-drawer"
          id="onboardingPotsSubmission"
        >
          <button
            className={
              this.state.disabledSubmitButton
                ? "o-button--grey u-m--8 u-main-font--medium "
                : "o-button--purple u-m--8 u-main-font--medium "
            }
            onClick={() => this.submitForPots()}
            disabled={this.state.disabledSubmitButton}
          >
            {this.state.selectedPots.length > 9
              ? "Let's start tracking!"
              : `Pick ${10 - this.state.selectedPots.length} more pots`}
          </button>
          <div className="u-m--12 u-sub-font--small u-bold u-color-white">
            Don't worry you can create and edit pots later.
          </div>
        </div>
      </div>
    );
  }
}

OnboardingPotCreation.propTypes = {
  saveNewPot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { saveNewPot })(OnboardingPotCreation);
