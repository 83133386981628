import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Icon } from "../objects/Icon";

import { showModal, hideModal } from "../../actions/modalActions";

import NewPotModal from "./NewPotModal";

class AddNewPotButton extends Component {
  state = {
    show: false,
  };

  showNewPotModal = () => {
    if (this.props.customOnClick) {
      this.props.customOnClick();
    } else {
      const modalContent = () => (
        <div>
          <NewPotModal dismissModal={hideModal} />
        </div>
      );

      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    return (
      <>
        <div className="pot">
          <button
            className="c-add-new-pot__button pot__shape u-blue-background"
            onClick={this.showNewPotModal}
          >
            <div className="u-color-white u-h-all u-w-all u-flex u-align-center u-justify-center">
              <Icon id="add" size="xl" />
            </div>
          </button>
        </div>
      </>
    );
  }
}

AddNewPotButton.propTypes = {
  customOnClick: PropTypes.func,
};
export default connect(null, { showModal })(AddNewPotButton);
