import {
  DAILY_POT_HOURS_FETCHED,
  DAILY_POT_TYPE_HOURS_FETCHED,
  THIS_WEEKS_POTS_TOTAL_HOURS,
  THIS_WEEKS_POT_TYPES_TOTAL_HOURS,
  LAST_WEEKS_POTS_TOTAL_HOURS,
  LAST_WEEKS_POT_TYPES_TOTAL_HOURS,
  THIS_MONTHS_POTS_TOTAL_HOURS,
  THIS_MONTHS_POT_TYPES_TOTAL_HOURS,
  LAST_MONTHS_POTS_TOTAL_HOURS,
  LAST_MONTHS_POT_TYPES_TOTAL_HOURS,
  ALL_TIME_POTS_TOTAL_HOURS,
  ALL_TIME_POT_TYPES_TOTAL_HOURS,
  SPECIFIC_POT_HOURS_COUNT,
} from "../actions/types";

const initialState = {};

export default function analyticsReducers(state = initialState, action) {
  switch (action.type) {
    case DAILY_POT_HOURS_FETCHED:
      return {
        ...state,
        dailyPotHours: action.data,
      };
    case DAILY_POT_TYPE_HOURS_FETCHED:
      return {
        ...state,
        dailyPotTypeHours: action.data,
      };
    case THIS_WEEKS_POTS_TOTAL_HOURS:
      return {
        ...state,
        thisWeeksPotsTotalHours: action.data,
      };
    case THIS_WEEKS_POT_TYPES_TOTAL_HOURS:
      return {
        ...state,
        thisWeeksPotTypesTotalHours: action.data,
      };
    case LAST_WEEKS_POTS_TOTAL_HOURS:
      return {
        ...state,
        lastWeeksPotsTotalHours: action.data,
      };
    case LAST_WEEKS_POT_TYPES_TOTAL_HOURS:
      return {
        ...state,
        lastWeeksPotTypesTotalHours: action.data,
      };
    case THIS_MONTHS_POT_TYPES_TOTAL_HOURS:
      return {
        ...state,
        thisMonthsPotTypesTotalHours: action.data,
      };
    case THIS_MONTHS_POTS_TOTAL_HOURS:
      return {
        ...state,
        thisMonthsPotsTotalHours: action.data,
      };
    case LAST_MONTHS_POT_TYPES_TOTAL_HOURS:
      return {
        ...state,
        lastMonthsPotTypesTotalHours: action.data,
      };
    case LAST_MONTHS_POTS_TOTAL_HOURS:
      return {
        ...state,
        lastMonthsPotsTotalHours: action.data,
      };
    case ALL_TIME_POTS_TOTAL_HOURS:
      return {
        ...state,
        allTimePotsTotalHours: action.data,
      };
    case ALL_TIME_POT_TYPES_TOTAL_HOURS:
      return {
        ...state,
        allTimePotTypesTotalHours: action.data,
      };
    case SPECIFIC_POT_HOURS_COUNT:
      return {
        ...state,
        specificPotHoursCount: {
          ...state.specificPotHoursCount,
          [action.data.potId]: action.data,
        },
      };
    default:
      return state;
  }
}
