import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as serviceWorker from "../../../serviceWorker";

import { requestError } from "../../../actions/errorActions";

import {
  getUTCTimeFromHourEntry,
  getLocalHourFromUTCTime,
} from "../../../utils/dateFunctions";
import { track } from "../../../utils/mixpanel";

class NotificationsSettingsContent extends Component {
  state = {
    isDownloading: false,
    notificationsAreOn: false,
    notificationsAreSupported: true,
    timeToNotifyUTC: getUTCTimeFromHourEntry(21),
    userDefinedTime: "21",
    notifcationHaveBeenUpdated: false,
    timeChangeSaved: undefined,
  };

  async componentDidMount() {
    // Check device is not using safari (as it doesn't support notifications)
    const macNotUsingSafari = /chrome|firefox|edge/.test(
      window.navigator.userAgent.toLowerCase()
    );

    // Check is browser is Brave (as we need to explain how to enable notifications)
    if (navigator.brave && (await navigator.brave.isBrave())) {
      this.setState({ isBraveBroswer: true });
    }

    // Check if the browser supports notifications
    if (!("Notification" in window) || !macNotUsingSafari) {
      return this.setState({ notificationsAreSupported: false });
    }

    //check if the browser has notifications enabled already and set it in the UI
    serviceWorker.checkIfNotificationSubscriptionExists(async (result) => {
      if (
        window.Notification &&
        Notification.permission === "granted" &&
        result
      ) {
        await this.getNotificationTimesFromDB();

        this.setState({
          notificationsAreOn: true,
        });
      }
    });
  }

  getNotificationTimesFromDB = async () => {
    try {
      await axios.get(`/api/notifications/desiredTime`).then((res) => {
        // if empty
        if (!res.data[0]) {
          this.setState({
            timeToNotifyUTC: getUTCTimeFromHourEntry(21),
            userDefinedTime: 21,
          });
        } else {
          this.setState({
            timeToNotifyUTC:
              res.data[0].timeToNotifyUTC || getUTCTimeFromHourEntry(21),
            userDefinedTime:
              getLocalHourFromUTCTime(res.data[0].timeToNotifyUTC) || 21,
          });
        }
      });
    } catch (e) {
      this.props.requestError(e);
    }
  };

  doesBrowserAllowNotifications = async () => {
    //if user hasn't expressed permission then trigger the browser to ask
    if (Notification.permission === "default") {
      return Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          return true;
        }
        return false;
      });
    } else if (Notification.permission === "blocked") {
      window.confirm(
        "Your browser is blocking notifications. Click the 🔒 icon in the URL bar to change your notification preferences."
      );
      return false;
    } else {
      return true;
    }
  };

  toggleNotificationPreferenceOfBrowser = async () => {
    if (!this.state.notificationsAreOn) {
      track("notifications-toggle-on");
      // turn notifications on!
      const doWeHavePermission = await this.doesBrowserAllowNotifications();

      if (doWeHavePermission) {
        // but does the browser let us?
        serviceWorker.createNotificationSubscription((result) => {
          // try to create a new subscription
          if (result) {
            //if successful show the user
            this.setState({
              notificationsAreOn: true,
              timeToNotifyUTC: getUTCTimeFromHourEntry(21),
              notifcationHaveBeenUpdated: true,
            });
          } else {
            // a subscription already exists
            this.setState({ notificationsAreOn: true });
          }
        });
      }
    } else {
      track("notifications-toggle-off");
      // delete sub from db by overwriting
      fetch("/api/notifications/register", {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: localStorage.jwtToken,
        },
        body: JSON.stringify({
          subscription: "removedByUser",
        }),
      });

      // remove the service workers ability
      serviceWorker.removeNotificationSubscription();

      // change the toggle
      this.setState({ notificationsAreOn: false });
    }
  };

  onNotificationTimeChange = (e) => {
    this.setState({
      timeToNotifyUTC: getUTCTimeFromHourEntry(e.currentTarget.value),
      userDefinedTime: e.currentTarget.value,
      timeChangeSaved: "false",
    });
  };

  onCompletedTimeChange = async (e) => {
    this.sanitiseHourSelection(this.state.userDefinedTime);
    // add to db
    const userId = this.props.auth.user.id;
    try {
      await axios.post(`/api/notifications/desiredTime`, {
        timeToNotifyUTC: this.state.timeToNotifyUTC,
        userId,
      });
    } catch (e) {
      this.props.requestError(e);
    }
    this.setState({ timeChangeSaved: "true" });
  };

  sanitiseHourSelection = (hour) => {
    if (hour < 0) {
      this.setState({
        timeToNotifyUTC: getUTCTimeFromHourEntry(0),
        userDefinedTime: 0,
      });
    }

    if (hour > 24) {
      this.setState({
        timeToNotifyUTC: getUTCTimeFromHourEntry(24),
        userDefinedTime: 24,
      });
    }
  };

  testNotificationButton = () => {
    navigator.serviceWorker.controller.postMessage({
      action: "TEST_NOTIFICATION",
    });
  };

  hoursUntilNextNotification = () => {
    // current UTC time
    const currentTime = new Date().toISOString().split("T")[1].split(":")[0];
    // time to be notified
    const notificationTime = this.state.timeToNotifyUTC.split(":")[0];

    const hoursUntilNotification = notificationTime - currentTime;
    if (hoursUntilNotification === 1) {
      return "within the next hour";
    } else if (hoursUntilNotification < 0) {
      return `in around ${24 + hoursUntilNotification} hours`;
    } else if (hoursUntilNotification === 0) {
      return `in around 24 hours`;
    } else {
      return `in around ${hoursUntilNotification} hours`;
    }
  };

  render() {
    return (
      <div>
        {this.state.notificationsAreSupported ? (
          <div>
            <div className="u-flex u-align-center u-mb--24">
              <div className="">
                <div className="u-sub-font--small u-color-white">
                  I want to be reminded to record{" "}
                  {window.Notification &&
                  Notification.permission === "denied" ? (
                    <div className="u-sub-font--vsmall u-color-grey u-mv--4">
                      Currently your browser is blocking notifications and hence
                      we can't send them.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.state.isBraveBroswer ? (
                  <div className="u-sub-font--vsmall u-color-white u-w--75pct">
                    You seem to be using a Brave browser, and hence you must
                    manually enable notifications in your settings, and bring
                    shields down for this website. To access, please navigate to
                    your privacy settings{" "}
                    <span className="u-underline">
                      brave://settings/privacy
                    </span>{" "}
                    and toggle "Use Google services for push messaging" on, and
                    then click "Relaunch".
                  </div>
                ) : null}
              </div>
              <div className="u-right--0 u-mlauto u-color-white u-main-font--vsmall">
                <label className="o-switch">
                  <input
                    type="checkbox"
                    id="isAllowedToNotify"
                    onChange={this.toggleNotificationPreferenceOfBrowser}
                    checked={this.state.notificationsAreOn}
                  />
                  <span className="o-switch__slider round"></span>
                </label>
              </div>
            </div>

            {this.state.notificationsAreOn ? (
              <div>
                <div className="u-flex u-align-center u-mb--24">
                  <div className="u-sub-font--small u-color-white">
                    Test what notifications look like by clicking the button
                  </div>
                  <div className="u-right--0 u-mlauto u-color-white">
                    <button
                      className="o-button--green"
                      onClick={this.testNotificationButton} // used for manual testing so we can see what notifications will look like
                    >
                      Test Me
                    </button>
                  </div>
                </div>
                <div className="u-flex">
                  <div className="u-sub-font--small u-color-white u-block">
                    At what time do you want to be reminded each day?
                    <div className="u-color-grey u-sub-font--vsmall">
                      You will be notified {this.hoursUntilNextNotification()}
                    </div>
                  </div>

                  <div className=" u-align-center u-flex u-mlauto u-right--0 u-color-white u-sub-font--small ">
                    <div className="u-color-grey u-sub-font--vvsmall">
                      {this.state.timeChangeSaved === "false" && "Not saved"}
                      {this.state.timeChangeSaved === "true" && "Saved"}
                    </div>
                    <div className="u-flex u-align-center">
                      <input
                        id="pot-target"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        max={24}
                        min={0}
                        className=" u-text-align-center u-bb--light-grey u-m--4 u-p--1 u-color-white"
                        placeholder={this.state.userDefinedTime}
                        value={this.state.userDefinedTime}
                        onChange={this.onNotificationTimeChange}
                        onBlur={this.onCompletedTimeChange}
                      />{" "}
                      :00
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="u-sub-font--vsmall u-color-white">
            This device does not support notifications. This is likely because
            this is an iPhone. If it is not, please try a different browser.
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { requestError })(
  NotificationsSettingsContent
);
