import {
  UPDATE_DATE,
  UPDATE_LOGS,
  REMOVE_LOGS,
  ADD_PAGE_OF_LOGS,
} from "../actions/types";

const initialState: TimeState = {
  date: new Date(),
  logs: [],
  endReached: false,
};

export default function (state = initialState, action: any): TimeState {
  switch (action.type) {
    case UPDATE_DATE:
      return {
        ...state,
        date: action.date,
      };
    case UPDATE_LOGS:
      return {
        ...state,
        logs: action.logs,
        endReached: action.endReached,
      };
    case ADD_PAGE_OF_LOGS:
      return {
        ...state,
        logs: [...action.logs, ...state.logs],
        endReached: action.endReached,
      };
    case REMOVE_LOGS:
      return {
        ...state,
        logs: state.logs.slice(0, state.logs.length - action.removeCount),
        endReached: action.endReached,
      };
    default:
      return state;
  }
}
