import React, { Component } from "react";
import BackgroundBlobBottom from "../objects/BackgroundBlobBottom";

class BlogSuggestionCard extends Component {
  render() {
    return (
      <a href={this.props.article.link} className="u-w-half u-m--8">
        <div className="o-suggested-article-card">
          <div className="u-w-all u-overflow--hidden">
            <img
              className="o-suggested-article-card--img"
              src={this.props.article.image}
            />
          </div>
          <div className="u-ph--24 u-pb--24 u-color-white">
            <div className="u-sub-font--small">{this.props.article.title}</div>
            <div className="u-sub-font--vsmall">
              {this.props.article.forward}
            </div>
          </div>
          <BackgroundBlobBottom />
        </div>
      </a>
    );
  }
}

export default BlogSuggestionCard;
