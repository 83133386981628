import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import routes from "../../utils/routes.js";
import LinkButton from "../objects/LinkButton.js";
import { Icon } from "../objects/Icon.js";

import { isOnPage } from "../../utils/stringFunctions.js";
import { logoutUser } from "../../actions/authActions";

class Navbar extends Component {
  state = {
    userHasScrolled: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (e) => {
    if (window.scrollY === 0) {
      this.setState({ userHasScrolled: false });
    } else if (window.scrollY > 0) {
      this.setState({ userHasScrolled: true });
    }
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  loggedInNav() {
    return (
      <>
        <div className="u-flex u-align-center u-text-unselectable u-mh--8">
          <Link
            className={classnames(
              "u-sub-font--small u-link-white u-pr--32 u-pr--12--mobile",
              {
                "u-link--active": isOnPage(routes.home),
              }
            )}
            to={routes.home}
          >
            Track
          </Link>
          <Link
            className={classnames(
              "u-sub-font--small u-link-white u-pr--32 u-pr--12--mobile",
              {
                "u-link--active": isOnPage(routes.analytics),
              }
            )}
            to={routes.analytics}
          >
            Analyse
          </Link>
          <Link
            className={classnames(
              "u-sub-font--small u-link-white u-pr--32 u-pr--12--mobile",
              {
                "u-link--active": isOnPage(routes.diary),
              }
            )}
            to={routes.diary}
          >
            Diary
          </Link>

          <div className="o-settings u-relative">
            <Icon
              id="hamburger"
              size="m"
              className="u-color-white u-hide--mobile"
            />
            <Icon
              id="hamburger"
              size="s"
              className="u-color-white u-hide--tabletAndDesktop"
            />
            <div className="o-settings__drop-down u-right--0">
              <Link to={routes.settings}>
                <button className="o-settings__drop-down-link">Settings</button>
              </Link>
              <button
                onClick={this.onLogoutClick}
                className="o-settings__drop-down-link u-p--8"
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  loggedOutNav() {
    return (
      <div className="u-flex u-align-center u-text-unselectable ">
        <a
          className="u-link-white u-sub-font--small u-mh--12"
          href={routes.about}
        >
          <strong>About</strong>
        </a>
        <a
          className="u-link-white u-sub-font--small u-mh--12"
          href={routes.blog.home}
        >
          Blog
        </a>
        <a
          className="u-link-white u-sub-font--small u-mh--12"
          href={routes.login}
        >
          Log in
        </a>
        <LinkButton type="Register" text="Sign up" color="purple" />
      </div>
    );
  }

  render() {
    const {
      auth: { isAuthenticated },
      hideCta,
    } = this.props;

    return (
      <nav id="nav-bar" className="u-text-unselectable">
        <div className="u-wrap">
          <div className="u-flex u-align-center u-justify-between u-pv--12 u-pv--8--mobile">
            <div className="u-mr--16 " style={{ width: "15%" }}>
              <a
                className="u-main-font--small u-link--active u-color-white"
                href={routes.home}
              >
                everyhour
              </a>
            </div>
            {isAuthenticated
              ? this.loggedInNav()
              : hideCta
              ? null
              : this.loggedOutNav()}
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  hideCta: PropTypes.bool,
  className: PropTypes.string,
  fixedPosition: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
