import React, { Component } from "react";
import { timeBlocks } from "../../utils/constants";
import ExpandedPotTimeBlock from "./ExpandedPotTimeBlock";

class ExpandedPotModal extends Component {
  displayAchievement = () => {
    const { targetDetails, currentTargetProgress } = this.props;
    if (
      //if there is a target
      targetDetails &&
      //and there is progress for that target
      currentTargetProgress
    ) {
      //and the totalDuration is larger or equal to the target and the target is to do a minimum of X hours
      if (
        currentTargetProgress.totalDuration >= targetDetails.targetNumber &&
        targetDetails.targetAdjective === "min"
      ) {
        return (
          <div
            role="img"
            aria-label="welldone-trophy"
            className="o-celebrate-animation u-mh--4"
          >
            🏆
          </div>
        );
      }
      // but if the target is to do a maximum of X hours and the count is larger
      else if (
        currentTargetProgress.totalDuration > targetDetails.targetNumber &&
        targetDetails.targetAdjective === "max"
      ) {
        return (
          <span role="img" aria-label="thumbsdown">
            👎
          </span>
        );
      } else {
        return null;
      }
    }
  };

  render() {
    return (
      <div className="u-color-white">
        <div className="pot__name u-sub-font--small u-bold u-text-shadow">
          {this.displayAchievement()}
          {this.props.potDetails.name}
        </div>
        {this.props.targetDetails && this.props.currentTargetProgress ? (
          <div>
            <div className="u-sub-font--vsmall u-color-white u-hide--mobile">
              {this.props.currentTargetProgress.totalDuration}/
              {this.props.targetDetails.targetPeriod} (Target:{" "}
              {this.props.targetDetails.targetNumber}/
              {this.props.targetDetails.targetPeriod})
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "center",
          }}
        >
          {timeBlocks.map((block, index) => (
            <ExpandedPotTimeBlock
              block={block}
              potId={this.props.potDetails.id}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ExpandedPotModal;
