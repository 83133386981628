import React, { Component } from "react";
import PropTypes from "prop-types";

import TargetProgressBar from "./TargetProgressBar";

import {
  getNameOfDayForLast7Days,
  getNameOfLast7Months,
} from "../../../utils/dateFunctions";

class TargetProgress extends Component {
  state = {
    last7PeriodNames: [],
  };

  componentDidMount() {
    switch (this.props.type) {
      case "day":
        return this.setState({ last7PeriodNames: getNameOfDayForLast7Days() });
      case "week":
        return this.setState({
          last7PeriodNames: ["This", "Last", "2", "3", "4", "5", "6"],
        });
      case "month":
        return this.setState({
          last7PeriodNames: getNameOfLast7Months(),
        });
      default:
        return null;
    }
  }

  titleText() {
    switch (this.props.type) {
      case "day":
        return "Daily";
      case "week":
        return "Weekly";
      case "month":
        return "Monthly";
      default:
        return null;
    }
  }

  render() {
    if (!this.props.targets || this.props.targets.length === 0) {
      return null;
    }
    return (
      <div className="o-analytics-key-highlights">
        <div className="u-sub-font--large u-color-white">
          My {this.titleText()} Targets
        </div>
        <div className="u-flex u-color-white u-sub-font--small u-w-half u-mlauto u-mr--0 u-w-all--mobile">
          {this.state.last7PeriodNames.map((day) => {
            return (
              <div className="u-text-center" style={{ width: "14%" }}>
                {day}
              </div>
            );
          })}
        </div>
        <TargetProgressBar
          targets={this.props.targets}
          // streaks={this.props.streaks}
        />
      </div>
    );
  }
}

TargetProgress.propTypes = {
  targets: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  // streaks: PropTypes.array.isRequired,
};

export default TargetProgress;
