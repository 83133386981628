const MAX_NAME_CHARACTERS = 30;

// Useful time constants in milliseconds
const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const DAYS = 24 * HOURS;

const EVERY_HOUR_NAVY_BLUE = "#040821";

const colours = {
  tomato: {
    brand: true,
    label: "Orange red",
    fontOverlayHex: "#FFFFFF",
    hex: "#EC5220",
  },
  mustard: {
    brand: true,
    label: "Mustard yellow",
    fontOverlayHex: "#FFFFFF",
    hex: "#F4CD34",
  },
  grass: {
    brand: true,
    label: "Grass green",
    fontOverlayHex: "#FFFFFF",
    hex: "#3e9d42",
  },
  taro: {
    brand: true,
    label: "Purple taro",
    fontOverlayHex: "#FFFFFF",
    hex: "#7F398F",
  },
  raspberry: {
    brand: false,
    label: "Raspberry pink",
    fontOverlayHex: "#FFFFFF",
    hex: "#BA3465",
  },
  ocean: {
    brand: false,
    label: "Ocean blue",
    fontOverlayHex: "#FFFFFF",
    hex: "#17BEBB",
  },
  apple: {
    brand: false,
    label: "Apple green",
    fontOverlayHex: "#FFFFFF",
    hex: "#99B53B",
  },
  periwinkle: {
    brand: false,
    label: "Periwinkle blue",
    fontOverlayHex: "#FFFFFF",
    hex: "#306EC5",
  },
  violet: {
    brand: false,
    label: "Violet",
    fontOverlayHex: "#FFFFFF",
    hex: "#3A1772",
  },
  raisin: {
    brand: false,
    label: "Raisin black",
    fontOverlayHex: "#FFFFFF",
    hex: "#2D232E",
  },
  babyTomato: {
    brand: true,
    label: "Terracotta",
    fontOverlayHex: "#FFFFFF",
    hex: "#F28B69",
  },
  babyMustard: {
    brand: true,
    label: "Lemon yellow",
    fontOverlayHex: "#FFFFFF",
    hex: "#F8DE77",
  },
  babyGrass: {
    brand: true,
    label: "Olive green",
    fontOverlayHex: "#FFFFFF",
    hex: "#CBDC93",
  },
  babyTaro: {
    brand: true,
    label: "Baby pink",
    fontOverlayHex: "#FFFFFF",
    hex: "#E1C5E8",
  },
  babyRaspberry: {
    brand: false,
    label: "Strawberry pink",
    fontOverlayHex: "#FFFFFF",
    hex: "#E090AD",
  },
  babyOcean: {
    brand: false,
    label: "Aquamarine",
    fontOverlayHex: "#FFFFFF",
    hex: "#80EFED",
  },
  babyApple: {
    brand: false,
    label: "Spearmint green",
    fontOverlayHex: "#FFFFFF",
    hex: "#99D69C",
  },
  babyPeriwinkle: {
    brand: false,
    label: "Sky blue",
    fontOverlayHex: "#FFFFFF",
    hex: "#8CB0E3",
  },
  babyViolet: {
    brand: false,
    label: "Lilac",
    fontOverlayHex: "#FFFFFF",
    hex: "#C4AAEE",
  },
  babyRaisin: {
    brand: false,
    label: "Grape",
    fontOverlayHex: "#FFFFFF",
    hex: "#AE96B0",
  },
  almostWhite: {
    brand: false,
    label: "Almost white",
    fontOverlayHex: "#000000",
    hex: "#f1f1f1",
  },
  lightGrey: {
    brand: false,
    label: "Light grey",
    fontOverlayHex: "#000000",
    hex: "#CCCCCC",
  },
  grey: {
    brand: false,
    label: "Grey",
    fontOverlayHex: "#FFFFFF",
    hex: "#777777",
  },
  white: {
    brand: false,
    label: "White",
    fontOverlayHex: "#000000",
    hex: "#ffffff",
  },
};

const timeBlocks = [
  // what hours are draggable, all should be same width for now to maximise space
  { id: 0, content: "15 mins", value: 0.25, relWidth: 1 },
  { id: 1, content: "30 mins", value: 0.5, relWidth: 1 },
  { id: 2, content: "1 hour", value: 1, relWidth: 1 },
  { id: 3, content: "2 hours", value: 2, relWidth: 1 },
  { id: 4, content: "3 hours", value: 3, relWidth: 1 },
  { id: 5, content: "6 hours", value: 6, relWidth: 1 },
  { id: 6, content: "8 hours", value: 8, relWidth: 1 },
];

// what colours and labels are used for pot types
const potTypes = [
  {
    id: "just-living",
    name: "Just Living",
    colour: colours.taro.hex,
  },
  {
    id: "social",
    name: "Social",
    colour: colours.mustard.hex,
  },
  {
    id: "hobbies",
    name: "Hobbies",
    colour: colours.grass.hex,
  },
  {
    id: "exercise",
    name: "Exercise",
    colour: colours.tomato.hex,
  },
  {
    id: "work",
    name: "Work",
    colour: colours.babyOcean.hex,
  },
  {
    id: "leisure",
    name: "Leisure",
    colour: colours.periwinkle.hex,
  },
  {
    id: "admin-and-chores",
    name: "Admin and Chores",
    colour: colours.apple.hex,
  },
  {
    id: "self-care",
    name: "Self Care",
    colour: colours.raspberry.hex,
  },
  {
    id: "not-recorded",
    name: "Not Recorded",
    colour: colours.lightGrey.hex,
  },
];

// onboarding pots
const onboardingPots = [
  {
    id: "sleep",
    name: "Sleep",
    colour: colours.taro,
    type: "Just Living",
  },
  {
    id: "partner",
    name: "Partner",
    colour: colours.raspberry,
    type: "Social",
  },
  {
    id: "family",
    name: "Family time",
    colour: colours.apple,
    type: "Social",
  },
  {
    id: "work-out",
    name: "Working out",
    colour: colours.apple,
    type: "Exercise",
  },
  {
    id: "fun-work",
    name: "Fun Work",
    colour: colours.mustard,
    type: "Work",
  },
  {
    id: "work",
    name: "Work",
    colour: colours.taro,
    type: "Work",
  },
  {
    id: "grim-work",
    name: "Grim Work",
    colour: colours.tomato,
    type: "Work",
  },
  {
    id: "jogging",
    name: "Jogging",
    colour: colours.taro,
    type: "Exercise",
  },
  {
    id: "studying",
    name: "Studying",
    colour: colours.grass,
    type: "Work",
  },
  {
    id: "project",
    name: "🚀 Side Project",
    colour: colours.grass,
    type: "Work",
  },
  {
    id: "coursea",
    name: "Course A",
    colour: colours.mustard,
    type: "Work",
  },
  {
    id: "courseb",
    name: "Course B",
    colour: colours.mustard,
    type: "Work",
  },
  {
    id: "friends",
    name: "Friends",
    colour: colours.tomato,
    type: "Social",
  },
  {
    id: "dissertation",
    name: "Dissertation",
    colour: colours.periwinkle,
    type: "Work",
  },
  {
    id: "admin",
    name: "Admin and Chores",
    colour: colours.apple,
    type: "Admin and Chores",
  },
  {
    id: "food",
    name: "Food time",
    colour: colours.mustard,
    type: "Admin and Chores",
  },
  {
    id: "commute",
    name: "Commuting",
    colour: colours.mustard,
    type: "Admin and Chores",
  },
  {
    id: "travel",
    name: "Travelling",
    colour: colours.mustard,
    type: "Admin and Chores",
  },
  {
    id: "forgot",
    name: "Forgot to record",
    colour: colours.lightGrey,
    type: "Not Recorded",
  },
  {
    id: "reading",
    name: "Reading",
    colour: colours.taro,
    type: "Hobbies",
  },
  {
    id: "tv",
    name: "TV",
    colour: colours.taro,
    type: "Leisure",
  },
  {
    id: "netflix",
    name: "Netflix",
    colour: colours.tomato,
    type: "Leisure",
  },
  {
    id: "youtube",
    name: "YouTube",
    colour: colours.tomato,
    type: "Leisure",
  },
  {
    id: "browsing",
    name: "Browsing the web",
    colour: colours.taro,
    type: "Leisure",
  },
  {
    id: "podcasts",
    name: "Podcasts",
    colour: colours.periwinkle,
    type: "Leisure",
  },
  {
    id: "chilling",
    name: "Chilling",
    colour: colours.tomato,
    type: "Leisure",
  },
  {
    id: "learning",
    name: "Learning",
    colour: colours.apple,
    type: "Hobbies",
  },
  {
    id: "social",
    name: "Social",
    colour: colours.mustard,
    type: "Social",
  },
  {
    id: "procrastination",
    name: "Procrastination",
    colour: colours.raspberry,
    type: "Leisure",
  },
  {
    id: "eating",
    name: "Eating",
    colour: colours.periwinkle,
    type: "Just Living",
  },
  {
    id: "meditating",
    name: "Meditating",
    colour: colours.raspberry,
    type: "Self Care",
  },
  {
    id: "walking",
    name: "Walking",
    colour: colours.periwinkle,
    type: "Exercise",
  },
  {
    id: "phone",
    name: "On my phone",
    colour: colours.taro,
    type: "Leisure",
  },
  {
    id: "sports",
    name: "SPORTS! (watching)",
    colour: colours.tomato,
    type: "Leisure",
  },
  {
    id: "sports2",
    name: "SPORTS! (doing)",
    colour: colours.tomato,
    type: "Exercise",
  },
  {
    id: "volunteering",
    name: "Volunteering",
    colour: colours.apple,
    type: "Social",
  },
  {
    id: "meetings",
    name: "Meetings",
    colour: colours.tomato,
    type: "Work",
  },
];
const onboardingSelectedPots = [
  {
    id: "sleep",
    name: "Sleep",
    colour: colours.taro,
    type: "Just Living",
  },
  {
    id: "friends",
    name: "Friends",
    colour: colours.tomato,
    type: "Social",
  },
  {
    id: "work",
    name: "Work",
    colour: colours.taro,
    type: "Work",
  },
  {
    id: "forgot",
    name: "Forgot to record",
    colour: colours.lightGrey,
    type: "Not Recorded",
  },
];

const targetPeriodTypes = ["day", "week", "month"];
const targetAdjectiveTypes = ["min", "max"];

module.exports = {
  MAX_NAME_CHARACTERS,
  timeBlocks,
  potTypes,
  targetPeriodTypes,
  targetAdjectiveTypes,
  onboardingPots,
  onboardingSelectedPots,
  colours,
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
  EVERY_HOUR_NAVY_BLUE,
};
