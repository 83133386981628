import { SET_DEFERRED_PROMPT } from "../actions/types";

const initialState = {
  deferredPrompt: null,
};

export default function pwaReducers(state = initialState, action) {
  switch (action.type) {
    case SET_DEFERRED_PROMPT:
      return {
        deferredPrompt: action.deferredPrompt,
      };

    default:
      return state;
  }
}
