import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchPageOfNotes } from "../actions/notesActions";

import Navbar from "../components/layout/Navbar";
import BackgroundBlobTop from "../components/objects/BackgroundBlobTop";

import { track } from "../utils/mixpanel";

interface Props {
  auth: AuthState;
  pots: PotsState;
  time: TimeState;
  notes: NotesState;
  logoutUser: () => void;
  requestError: (error: Error) => void;
  fetchPageOfNotes: (pageNumber?: number) => void;
}

interface State {
  loading: boolean;
  pageNumber: number;
  pageSize: number;
}

class Notes extends Component<Props, State> {
  state = {
    loading: true,
    pageNumber: 1,
    pageSize: 20,
  };

  componentDidMount = async () => {
    track("open-notes");

    await this.loadNextPageOfNotes();
  };

  loadNextPageOfNotes = async () => {
    const { pageNumber } = this.state;

    this.setState({ loading: true });

    await this.props.fetchPageOfNotes(pageNumber);

    const nextPageNumber = pageNumber + 1;

    this.setState({ pageNumber: nextPageNumber, loading: false });
  };

  render() {
    const {
      notes: { notes, endReached },
    } = this.props;
    const { loading, pageNumber } = this.state;

    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-overflow-scroll u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-m0auto u-vh u-absolute u-top--0 u-left--0 u-right--0">
          <Navbar />
          <div className="u-mt--32 u-mt--16--mobile u-wrap">
            {this.state.loading && pageNumber === 1 ? (
              <h3 className="u-main-font--large u-color-white">Loading...</h3>
            ) : !notes.length ? (
              <h3 className="u-main-font--large u-color-white">
                No notes to display yet!
              </h3>
            ) : (
              <>
                {notes.map((note: Note, index: number) => (
                  <div
                    key={index}
                    className="u-flex u-align-center u-p--16 u-color-white u-slim-border"
                  >
                    {note.dateDescribed}: "{note.note}"
                  </div>
                ))}
                {!endReached && (
                  <button
                    className="o-button--purple o-button--stretch u-main-font--small"
                    type="button"
                    disabled={!!loading}
                    onClick={this.loadNextPageOfNotes}
                  >
                    {loading ? "Loading" : "Load more"}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: EveryHourState) => ({
  auth: state.auth,
  notes: state.notes,
});

export default connect(mapStateToProps, {
  fetchPageOfNotes,
})(Notes);
