import { DAYS, HOURS, MINUTES } from "./constants";

export function getNextStartTimeFromLogs(logs) {
  //if no logs make it midnight starting
  if (!logs || logs.length === 0) {
    return "00:00";
  } else {
    // get the numberical hours, minutes and duration from the log
    const { hoursAsNumber, minutesAsANumber, durationAsANumber } =
      getLogsNumericalHoursMinutesAndDuration(logs);

    // get the total hours as a decimal (ie 15.5, 24.25)
    const totalHoursRecorded =
      hoursAsNumber + minutesAsANumber + durationAsANumber;

    // find the remainder of minutes that are not total hours
    const newMinutesAsNumber = 60 * (totalHoursRecorded % 1);
    const newMinutes = newMinutesAsNumber === 0 ? "00" : newMinutesAsNumber; // note it should never be less than 10 but not 0

    // find the number of hours (if the hours are at 24 show this - a user must have navigated to a date they have completely filled)
    const newHoursAsNumber =
      totalHoursRecorded / 24 === 1 ? 24 : Math.floor(totalHoursRecorded % 24);
    const newHours =
      newHoursAsNumber > 9 ? `${newHoursAsNumber}` : `0${newHoursAsNumber}`;

    return `${newHours.toString()}:${newMinutes.toString()}`;
  }
}

export function checkIfLogsDurationGoesTo24Hours(logs) {
  const { hoursAsNumber, minutesAsANumber, durationAsANumber } =
    getLogsNumericalHoursMinutesAndDuration(logs);

  // note it should not be possible to have a log that start time + duration goes over 24 hours
  if (hoursAsNumber + minutesAsANumber + durationAsANumber === 24) {
    return true;
  }
}

export function getLogsNumericalHoursMinutesAndDuration(logs) {
  //find the most recent hour (Assuming the logs are ordered)
  const [hours, minutes] = logs[logs.length - 1].startTime.split(":");

  const hoursAsNumber = parseFloat(hours);
  const minutesAsANumber = parseFloat(minutes) / 60; // returns as a fraction of an hour
  const durationAsANumber = parseFloat(logs[logs.length - 1].duration);

  return { hoursAsNumber, minutesAsANumber, durationAsANumber };
}

export function getDBDateFormat(date) {
  //previously used toISOString but that converts it to GMT which can mess updates
  const year = date.getFullYear();
  const month =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  return year + "-" + month + "-" + day;
}

export function getYesterdaysISODate() {
  const today = new Date();

  const yesterdaysDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
  );

  return yesterdaysDate;
}

export function get7DaysAgoISODate() {
  const today = new Date();

  const lastWeeksDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
  );

  return lastWeeksDate;
}

export function get8DaysAgoISODate() {
  const today = new Date();

  const EightDaysAgoDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8)
  );

  return EightDaysAgoDate;
}

export function get14DaysAgoISODate() {
  const today = new Date();

  const twoWeeksAgoDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14)
  );

  return twoWeeksAgoDate;
}

export function getMostRecentMondayBeforeDate(date) {
  // if it is monday, restart targets, the week begins now
  if (date.getDay() === 0) {
    return date;
  }

  const mostRecentMonday = getDBDateFormat(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay() + 1
    )
  );
  return mostRecentMonday;
}

export function getLastMonthsISODate() {
  const today = new Date();

  const lastMonthsDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
  );

  return lastMonthsDate;
}

export function getLastLastMonthsISODate() {
  const today = new Date();

  const lastMonthsDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth() - 2, today.getDate())
  );

  return lastMonthsDate;
}

export function getBeginningOfTheMonthISODate(today) {
  const beginningOfTheMonthsDate = getDBDateFormat(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );

  return beginningOfTheMonthsDate;
}

export function getWordyDate(recordingDate) {
  if (!recordingDate) {
    return;
  }
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  return recordingDate.toLocaleDateString(undefined, options);
}

export function getDateObjectFromString(date) {
  const dateArray = date.split("-");

  return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
}

export function convertDateToDDMMYY(date) {
  const parsedDate = new Date(date);
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  };
  const shortDate = parsedDate.toLocaleString(undefined, options);
  return shortDate;
}

export function getDateOfNextDay(date) {
  return new Date(date.getTime() + DAYS);
}

export function getRewoundDate(date, halfHoursToRewind) {
  return new Date(date.getTime() - 0.5 * HOURS * halfHoursToRewind);
}

export function isDateWithinTheLast2Days(date) {
  return new Date().getTime() - date.getTime() < 2 * DAYS;
}

export function isDateWithinTheLastWeek(date) {
  return new Date().getTime() - date.getTime() < 7 * DAYS;
}

export function calculatePercentageOfYearLeft() {
  const now = new Date();
  const startOfThisYear = new Date(now.getFullYear(), 0, 0);
  const endOfThisYear = new Date(now.getFullYear() + 1, 0, 0);
  const diff =
    now -
    startOfThisYear +
    (startOfThisYear.getTimezoneOffset() - now.getTimezoneOffset()) * MINUTES;
  const day = Math.floor(diff / DAYS);
  const daysInYear = (endOfThisYear - startOfThisYear) / DAYS;
  const daysInYearAsPercentage = (100 * (daysInYear - day)) / daysInYear;
  return daysInYearAsPercentage.toFixed(1) + "%";
}

export function getUTCTimeFromHourEntry(int) {
  const now = new Date();
  // convert into a real date - using todays date as to account for region time zone variations like BST
  const dateObject = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    int,
    null
  );
  // convert into UTC
  const dateUTC = dateObject.toISOString();
  // Just get the time
  const timeUTC = dateUTC.split("T")[1];
  return timeUTC;
}

export function getLocalHourFromUTCTime(timeUTC) {
  // TODO - Currently, if you are in a timezone that is not an hour integer away you will not be notified on the hour
  const hours = parseInt(timeUTC.split(":")[0]);
  const timezoneChange = new Date().getTimezoneOffset();
  let localHours = hours - timezoneChange / 60;
  if (localHours < 0) {
    localHours = 24 + localHours;
  }
  return localHours;
}

export function getNameOfDayForLast7Days() {
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth();
  const thisDate = today.getDate();

  let nameArray = [];

  for (let i = 0; i < 7; i++) {
    nameArray.push(
      new Date(thisYear, thisMonth, thisDate - i).toLocaleDateString(
        undefined,
        { weekday: "short" }
      )
    );
  }

  return nameArray;
}

export function getNameOfLast7Months() {
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth();
  const thisDate = today.getDate();

  let nameArray = [];

  for (let i = 0; i < 7; i++) {
    nameArray.push(
      new Date(thisYear, thisMonth - i, thisDate).toLocaleDateString(
        undefined,
        { month: "short" }
      )
    );
  }

  return nameArray;
}

export function getTimeStringFromDateObject(dateObject) {
  const hourString = dateObject.getHours().toString();
  const minutesString = dateObject.getMinutes().toString();
  let hours = hourString.length > 1 ? hourString : "0" + hourString;
  const minutes =
    minutesString.length > 1 ? minutesString : "0" + new Date().getMinutes();
  return hours + ":" + minutes;
}
