import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import classnames from "classnames";

import { requestError } from "../../../actions/errorActions";
import { logoutUser } from "../../../actions/authActions";

interface Props {
  auth: AuthState;
  logoutUser: () => void;
  requestError: (error: Error) => void;
}

class DeleteAccountSettingsContent extends Component<Props> {
  state = {
    isAllowedToDeleteAccount: false,
  };

  consentToDeleteCheckBoxTriggered = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ isAllowedToDeleteAccount: e.currentTarget.value });
  };

  deleteAccountRequest = async () => {
    try {
      await axios.delete(`/api/v2/users/${this.props.auth.user.id}`);

      this.props.logoutUser();
    } catch (e) {
      this.props.requestError(e);
      throw e;
    }
  };

  render() {
    return (
      <div className="">
        <div className="u-sub-font--small u-color-white u-mb--16">
          This cannot be undone.
        </div>

        <div className="u-align-center u-flex">
          <input
            type="checkbox"
            id="isAllowedToDeleteAccount"
            name="Delete account checkbox"
            className="u-mr--8"
            style={{ width: "20px", height: "20px" }}
            onChange={this.consentToDeleteCheckBoxTriggered}
          />
          <label
            htmlFor="isAllowedToDeleteAccount"
            className="u-sub-font--vsmall u-color-white"
          >
            <span>
              Please check this box to confirm you understand that this will
              permanently delete your data.
            </span>
          </label>
        </div>

        <div className="u-mv--24">
          <button
            className={classnames("o-button u-main-font--small", {
              "o-button--red": this.state.isAllowedToDeleteAccount,
              "o-button--disabled": !this.state.isAllowedToDeleteAccount,
            })}
            onClick={this.deleteAccountRequest}
            disabled={!this.state.isAllowedToDeleteAccount}
          >
            Delete my account and data
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: EveryHourState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { requestError, logoutUser })(
  DeleteAccountSettingsContent
);
