import PropTypes from "prop-types";

export const ColourPropTypes = {
  hex: PropTypes.string,
  label: PropTypes.string,
  brand: PropTypes.bool,
  fontOverlayHex: PropTypes.string,
};

export const PotPropTypes = {
  id: PropTypes.string,
  order: PropTypes.number,
  name: PropTypes.string,
  colour: PropTypes.oneOfType([
    PropTypes.shape(ColourPropTypes),
    PropTypes.string,
  ]),
  img: PropTypes.string,
};

export const PotTargetPropTypes = {
  targetNumber: PropTypes.number,
  targetPeriod: PropTypes.string,
  targetAdjective: PropTypes.string,
};

export const TimeBlockPropTypes = {
  id: PropTypes.number,
  order: PropTypes.number,
  content: PropTypes.string,
  relWidth: PropTypes.number,
  value: PropTypes.number,
};

export const TimePropTypes = {
  date: PropTypes.date,
  logs: PropTypes.array,
};

export const AuthPropTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

export const ModalPropTypes = {
  status: PropTypes.string,
  component: PropTypes.any,
  data: PropTypes.object,
  position: PropTypes.string,
};

export const AnalyticsPropTypes = {
  allTimePotTypesTotalHours: PropTypes.arrayOf(PropTypes.object),
  allTimePotsTotalHours: PropTypes.arrayOf(PropTypes.object),
  dailyPotHours: PropTypes.arrayOf(PropTypes.object),
  dailyPotTypeHours: PropTypes.arrayOf(PropTypes.object),
  lastWeeksPotTypesTotalHours: PropTypes.arrayOf(PropTypes.object),
  lastWeeksPotsTotalHours: PropTypes.arrayOf(PropTypes.object),
  thisMonthsPotTypesTotalHours: PropTypes.arrayOf(PropTypes.object),
  thisMonthsPotsTotalHours: PropTypes.arrayOf(PropTypes.object),
  thisWeeksPotTypesTotalHours: PropTypes.arrayOf(PropTypes.object),
  thisWeeksPotsTotalHours: PropTypes.arrayOf(PropTypes.object),
};
