// @ts-nocheck
/* eslint import/no-webpack-loader-syntax: off */
import * as React from "react";

import IconAdd from "-!svg-react-loader!../icons/add.svg";
import IconCalendar from "-!svg-react-loader!../icons/calendar.svg";
import IconChevronDown from "-!svg-react-loader!../icons/chevron-down.svg";
import IconChevronLeft from "-!svg-react-loader!../icons/chevron-left.svg";
import IconChevronRight from "-!svg-react-loader!../icons/chevron-right.svg";
import IconClear from "-!svg-react-loader!../icons/clear.svg";
import IconDone from "-!svg-react-loader!../icons/done.svg";
import IconMoreVert from "-!svg-react-loader!../icons/more-vert.svg";
import IconCog from "-!svg-react-loader!../icons/cog.svg";
import IconHamburger from "-!svg-react-loader!../icons/hamburger.svg";
import IconSafariShare from "-!svg-react-loader!../icons/safari-share.svg";

class Icon extends React.Component {
  sizes = {
    xs: "12px",
    s: "18px",
    m: "24px",
    l: "32px",
    xl: "40px",
    xxl: "64px",
  };

  returnComponent = (id, w, h, className, fill) => {
    switch (id) {
      case "add":
        return <IconAdd width={w} height={h} {...{ className, fill }} />;
      case "calendar":
        return <IconCalendar width={w} height={h} {...{ className, fill }} />;
      case "chevron-down":
        return (
          <IconChevronDown width={w} height={h} {...{ className, fill }} />
        );
      case "chevron-left":
        return (
          <IconChevronLeft width={w} height={h} {...{ className, fill }} />
        );
      case "chevron-right":
        return (
          <IconChevronRight width={w} height={h} {...{ className, fill }} />
        );
      case "clear":
        return <IconClear width={w} height={h} {...{ className, fill }} />;
      case "done":
        return <IconDone width={w} height={h} {...{ className, fill }} />;
      case "more-vert":
        return <IconMoreVert width={w} height={h} {...{ className, fill }} />;
      case "cog":
        return <IconCog width={w} height={h} {...{ className, fill }} />;
      case "hamburger":
        return <IconHamburger width={w} height={h} {...{ className, fill }} />;
      case "safari-share":
        return (
          <IconSafariShare width={w} height={h} {...{ className, fill }} />
        );
      default:
        return null;
    }
  };

  render = () => {
    const {
      id,
      size,
      width,
      height,
      className = "",
      fill = "currentColor",
    } = this.props;

    let w = this.sizes.m;
    let h = this.sizes.m;

    if (size) {
      w = h = this.sizes[size] ? this.sizes[size] : this.sizes.m;
    }

    if (width) {
      w = h = width;
    }

    if (height) {
      h = height;
    }

    if (!id) return null;

    return this.returnComponent(id, w, h, className, fill);
  };
}

export { Icon };
