import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { requestError } from "../../../actions/errorActions";
import FeedbackInput from "./FeedbackInput";

interface Props {
  auth: AuthState;
  logoutUser: () => void;
  requestError: (error: Error) => void;
}

class SupportSettingsContent extends Component<Props> {
  state = {
    thumbsUpSubmitted: false,
  };

  feedbackTextChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ feedbackText: e.currentTarget.value });
  };

  thumbsUpSubmit = async () => {
    const userId = this.props.auth.user.id;
    const body = "👍";

    try {
      await axios.post(`/api/feedback`, { body, userId });
    } catch (e) {
      this.props.requestError(e);
    }
    this.setState({ thumbsUpSubmitted: true });
  };

  render() {
    return (
      <>
        <div className="u-mv--16">
          <div className="u-sub-font--small u-color-white u-mt--16">
            If you ever need support, have questions, have ideas or anything at
            all, send me a message here - I would love to hear from you.
          </div>

          <div className="u-sub-font--vsmall u-color-grey u-mt--8 u-mb--16">
            Or if you prefer, send me an email on{" "}
            <a
              className="u-color-white"
              href="mailto:alex@everyhour.xyz?subject=Hi I need some help"
            >
              alex@everyhour.xyz
            </a>
          </div>

          <div className="u-mt--16">
            <FeedbackInput
              type="textarea"
              placeholder="What are your targets? I want to know so I can make everyhour help!"
              title="What are you trying to improve"
            />
          </div>

          <div className="u-mt--16">
            <FeedbackInput
              type="textarea"
              placeholder="Are there any features, wishes or ideas that you have had using everyhour?"
              title="What can I do to make this better for you?"
            />
          </div>
        </div>

        <div className="u-mv--16">
          <div className="u-sub-font--small u-color-white">
            Support me working on this project:
          </div>

          <div className="u-w-all u-mt--16">
            <div className="u-flex u-align-center">
              {this.state.thumbsUpSubmitted ? (
                <div className="u-main-font--vsmall u-color-white">
                  Sent - thanks!
                </div>
              ) : (
                <button
                  onClick={this.thumbsUpSubmit}
                  className="o-button--green u-main-font--vsmall u-mr--16"
                >
                  Click here to send me a
                  <span role="img" aria-label="thumbsup" className="u-mh--4">
                    👍
                  </span>{" "}
                  to my phone
                </button>
              )}
              <a
                href="https://ko-fi.com/everyhouralex"
                className="o-button--green u-link-white u-main-font--vsmall u-mh--16"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Me A Coffee
              </a>
            </div>

            <div className="u-flex u-justify-center u-mt--48">
              <a
                href="https://www.producthunt.com/posts/everyhour?utm_source=badge-review&utm_medium=badge&utm_souce=badge-everyhour#discussion-body"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=302611&theme=dark"
                  alt="everyhour - Your life balance: what gets measured, gets managed | Product Hunt"
                  style={{ width: "250px", height: "54px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: EveryHourState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { requestError })(
  SupportSettingsContent
);
