import React, { Component } from "react";
import HVCOEmailForm from "../../components/layout/HVCOEmailForm";
import BackgroundBlobTop from "../../components/objects/BackgroundBlobTop";
import BackgroundBlobBottom from "../../components/objects/BackgroundBlobBottom";

class FreeConsultation extends Component {
  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden u-z-index---1">
          <BackgroundBlobTop />
          <div className="u-w-all u-vh u-wrap">
            <div className="u-p--8 u-align-center">
              <h1 className="u-main-font--large u-color-white u-text-center">
                Learn how to improve your life balance
              </h1>
              <h4 className="u-sub-font--medium u-color-white u-text-center">
                by speaking to an expert who has gone through it
              </h4>
              <div id="header" className="u-flex u-align-center">
                <div className="u-flex u-flex-column" style={{ width: "65%" }}>
                  <br />
                  <HVCOEmailForm
                    hvcoName="Free_consultation"
                    formTitle="Let us contact you"
                    formSubtitle=""
                  />
                </div>
                <div className="u-mv--48 u-mh--16" id="phoneMock">
                  <img
                    height="600px"
                    alt="Mockup of an iphone XS with everyhour on it"
                    src="./iPhone-XS-MAX.webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

FreeConsultation.propTypes = {};

export default FreeConsultation;
