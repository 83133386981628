export function getHeightOfElementWithId(elementName, document) {
  const element = document.getElementById(elementName);

  if (element) {
    return parseFloat(element.scrollHeight);
  }

  return 0;
}

// this is also checked in the server
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function emitEvent(type, detail = {}, elem = document) {
  // Make sure there's an event type
  if (!type) return;

  // Create a new event
  let event = new CustomEvent(type, detail);

  // Dispatch the event
  return elem.dispatchEvent(event);
}
