import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TwoLevelPieChart from "./TwoLevelPieChart";

import { AnalyticsPropTypes } from "../../../utils/propTypes.js";

class LifeBalancePieCharts extends Component {
  state = {};

  render() {
    const innerRadius = 0.5 * this.props.pieChartSize * (5 / 9); //was going to be half but looked too small
    const outerRadius = 0.5 * this.props.pieChartSize * 0.95; //slightly smaller than the full space
    return (
      <div className="u-flex u-justify-between u-flex-column--mobile">
        <div className="u-flex o-analytics-key-highlights">
          <div className="u-main-font--large u-color-white">Last 7 days</div>
          {!this.props.analytics.thisWeeksPotTypesTotalHours ||
          !this.props.analytics.thisWeeksPotsTotalHours ? (
            <div className="u-main-font--small u-color-white">Loading...</div>
          ) : (
            <TwoLevelPieChart
              pieChartSize={this.props.pieChartSize}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              innerData={this.props.analytics.thisWeeksPotTypesTotalHours}
              outerData={this.props.analytics.thisWeeksPotsTotalHours}
            />
          )}
        </div>
        <div className="u-flex o-analytics-key-highlights">
          <div className="u-main-font--large u-color-white">Last Month</div>
          {!this.props.analytics.thisMonthsPotTypesTotalHours ||
          !this.props.analytics.thisMonthsPotsTotalHours ? (
            <div className="u-main-font--small u-color-white">Loading...</div>
          ) : (
            <TwoLevelPieChart
              pieChartSize={this.props.pieChartSize}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              innerData={this.props.analytics.thisMonthsPotTypesTotalHours}
              outerData={this.props.analytics.thisMonthsPotsTotalHours}
            />
          )}
        </div>
        <div className="u-flex o-analytics-key-highlights">
          <div className="u-main-font--large u-color-white">All time</div>
          {!this.props.analytics.allTimePotTypesTotalHours ||
          !this.props.analytics.allTimePotsTotalHours ? (
            <div className="u-main-font--small u-color-white">Loading...</div>
          ) : (
            <TwoLevelPieChart
              pieChartSize={this.props.pieChartSize}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              innerData={this.props.analytics.allTimePotTypesTotalHours}
              outerData={this.props.analytics.allTimePotsTotalHours}
            />
          )}
        </div>
      </div>
    );
  }
}

LifeBalancePieCharts.propTypes = {
  pieChartSize: PropTypes.number,
  analytics: PropTypes.shape(AnalyticsPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
});

export default connect(mapStateToProps, {})(LifeBalancePieCharts);
