import React, { Component } from "react";

import Install from "./SettingsContents/Install";

class OfferToHelpModal extends Component {
  render() {
    return (
      <div className=" u-m0auto u-color-white ">
        <div className="u-main-font--large u-mv--12 u-flex--desktop">
          <div className="o-celebrate-animation u-mh--32">🎉 </div> Well done!
        </div>
        <div className="u-sub-font--small u-mv--12">
          You are being intentional with your time.
          <br />
          <br />
          Now, throughout the day ask yourself{" "}
          <b> “What do I want to record I have done?”</b> and record 3 or 4
          times a day.
        </div>
        <div
          style={{
            paddingTop: "24px",
            borderTop: "1px solid #cccccc",
            marginTop: "48px",
            color: "#ffffff",
          }}
        ></div>
        <div className="u-main-font--medium u-mv--12 u-flex u-w-all">
          Install the app
        </div>
        <div>
          <Install />
        </div>
      </div>
    );
  }
}

OfferToHelpModal.propTypes = {};

export default OfferToHelpModal;
