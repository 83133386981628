import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  hasOptedIn,
  hasOptedOut,
  optIn,
  optOut,
  track,
} from "../../../utils/mixpanel";

class UserAnalyticsSettingsContent extends Component {
  state = {
    mixpanelTrackingEnabled: false,
  };

  componentDidMount() {
    this.setState({
      mixpanelTrackingEnabled: hasOptedIn() || !hasOptedOut(),
    });
  }

  toggleOptedInStatus = () => {
    const { mixpanelTrackingEnabled } = this.state;

    if (mixpanelTrackingEnabled) {
      track("Opt Out");
      optOut();
      this.setState({ mixpanelTrackingEnabled: false });
    } else {
      // 'Opt In' is already tracked automatically by Mixpanel
      optIn();
      this.setState({ mixpanelTrackingEnabled: true });
    }
  };

  render() {
    const { mixpanelTrackingEnabled } = this.state;

    return (
      <>
        <div className="u-mv--16">
          <div className="u-flex u-justify-between u-align-center">
            <div className="u-flex u-flex-column">
              <span className="u-sub-font--small u-color-white u-mb--8">
                We analyse how you use EveryHour in order to improve the
                performance and features of the app.
              </span>
              <span className="u-sub-font--small u-color-white">
                {mixpanelTrackingEnabled
                  ? " If you'd like to opt out of this, click the button."
                  : " If you're happy for us to use your data to improve the platform, please click the button!"}
              </span>
            </div>

            <button
              className={classNames("u-main-font--vsmall", {
                "o-button--green": !mixpanelTrackingEnabled,
                "o-button--red": mixpanelTrackingEnabled,
              })}
              onClick={this.toggleOptedInStatus}
            >
              {mixpanelTrackingEnabled ? "Disable tracking" : "Allow tracking"}
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(UserAnalyticsSettingsContent);
