import React, { Component } from "react";

import Navbar from "../components/layout/Navbar.js";
import LinkButton from "../components/objects/LinkButton.js";
import BackgroundBlobTop from "../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../components/objects/BackgroundBlobBottom.js";
import Footer from "../components/layout/Footer.js";
import HVCOEmailForm from "../components/layout/HVCOEmailForm.js";

// import { track } from "../utils/mixpanel.js"; //TODO CATCH VIEWS ON MIXPANEL

class Landing extends Component {
  // componentDidMount() {
  //   track("open-landing");
  // }

  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden u-z-index---1">
          <BackgroundBlobTop />
          <Navbar fixedPosition={true} />
          <div className="u-w-all u-wrap">
            <div className=" u-align-center u-mt--120">
              <div className="u-m0auto u-text-center u-align-center">
                <div className="u-sub-font--vlarge u-color-white u-text-center">
                  Hello{" "}
                  <span role="img" aria-label="wave">
                    👋
                  </span>
                </div>
                <br />
                <div className="u-sub-font--large u-color-white u-text-center">
                  My name is Alex
                </div>
                <br />
                <div className="u-justify-center u-flex  u-color-white u-sub-font--small u-align-baseline">
                  <div className="u-mh--4">I am your </div>
                  <div className="u-underline--yellow u-mh--4">dev,</div>
                  <div className="u-underline--orange u-mh--4">designer</div>
                  and
                  <div className="u-underline--green u-mh--4">support</div>
                </div>

                <br />
                <div className="u-color-white u-sub-font--medium u-text-center u-mt--16 u-w--75pct u-m0auto">
                  I changed my life by tracking my time and I want to help
                  change yours too.
                </div>
              </div>

              <iframe
                title="Why I made everyhour, why I record every hour, and why you should too"
                src="https://www.youtube.com/embed/4eO0duB3IPg"
                className="o-iframe-fullscreen-content--youtube u-mt--32"
              ></iframe>

              <div className="u-m0auto u-text-center">
                <div className="u-color-white u-sub-font--medium u-justify-center u-flex u-align-baseline u-mt--16 ">
                  everyhour is a
                  <div className="u-underline--green u-mh--4">
                    company of one.
                    {/* TODO WRITE A BLOG ABOUT WHY COMPANY OF ONE */}
                  </div>
                </div>
                <br />
                <div className="u-color-white u-sub-font--small u-text-center u-mt--16 u-w--75pct u-m0auto ">
                  <span role="img" aria-label="heart">
                    ❤️{" "}
                  </span>
                  <b>It is just me and you</b>
                  <p>
                    no shareholders, no sales guys, no ads, no selling of data,
                    no other priorities. I am building this for us.
                  </p>
                  <br />
                  <br />
                  <span role="img" aria-label="hands in prayer">
                    🤲{" "}
                  </span>
                  <b>If you want help, lets chat</b>
                  <p>
                    I am the person behind support - it is personal. If there is
                    anything you need help with, tell me and I can change it.
                  </p>
                  <br />
                  <br />
                  <span role="img" aria-label="transparent glasses">
                    👓{" "}
                  </span>
                  <b>I am completely transparent.</b>{" "}
                  <p>
                    You can see how much this site costs me, the stats, and how
                    much I earn from it here. [Coming soon]
                    {/* TODO ADD OPEN STATS LINK */}
                  </p>
                </div>
              </div>

              <div className="u-flex u-justify-center u-mt--48 u-mb--32">
                <LinkButton
                  type="Register"
                  text="Join the group"
                  color="purple"
                />
              </div>

              <div className="u-align-center u-mt--48 u-mb--32 u-color-white">
                <div className="u-sub-font--medium u-text-center">
                  Doubt this will work for you?{" "}
                </div>

                <div className="u-m0auto u-mt--48 u-text-center u-w-half--desktop">
                  <HVCOEmailForm
                    hvcoName="just_interested"
                    formTitle="I write about tools, tactics and tips to help improve life balance, without the bull"
                    formSubtitle="Learn from my experiences"
                    buttonText="Subscribe"
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
          <BackgroundBlobBottom />
        </div>
      </div>
    );
  }
}

Landing.propTypes = {};

export default Landing;
