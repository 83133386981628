import React, { Component } from "react";
import PropTypes from "prop-types";

class NavDots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //needed to fill array with something otherwise map doesnt happen
      dotCountArray: new Array(this.props.dotCount).fill("0"),
    };
  }

  render() {
    return (
      <div className="dots">
        {this.state.dotCountArray.map((content, index) => {
          return (
            <svg height="16" width="16">
              <circle
                cx="8"
                cy="8"
                r={index + 1 === this.props.current ? 8 : 2}
                stroke="white"
                stroke-width="3"
                fill="white"
              />
            </svg>
          );
        })}
      </div>
    );
  }
}

NavDots.propTypes = {
  current: PropTypes.number,
  dotCount: PropTypes.number,
};

export default NavDots;
