import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

import { TimeBlockPropTypes } from "../../utils/propTypes";

class HourBlock extends Component {
  render() {
    return (
      <div
        className="block__div"
        // multiplied by 100 to ensure they take up the maximum space
        style={{ width: this.props.block.relWidth * 100 + "%" }}
      >
        <Draggable
          draggableId={this.props.block.id.toString()}
          index={this.props.block.id}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className={"block__card"}>
                <span className="u-hide--mobile">
                  {this.props.block.content}
                </span>
                <span className="u-hide--tabletAndDesktop">
                  {this.props.block.value}
                </span>
              </div>
            </div>
          )}
        </Draggable>
      </div>
    );
  }
}

HourBlock.propTypes = {
  block: PropTypes.shape(TimeBlockPropTypes).isRequired,
};

export default HourBlock;
