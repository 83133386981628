import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HourBlock from "./HourBlock.js";
import { timeBlocks } from "../../utils/constants";
import {
  getDBDateFormat,
  getNextStartTimeFromLogs,
  getTimeStringFromDateObject,
} from "../../utils/dateFunctions.js";
import { emitEvent } from "../../utils/UIFunctions.js";
import { showModal } from "../../actions/modalActions.js";
import OfferToHelpModal from "./OfferToHelpModal.js";

class TimeDrawer extends Component {
  componentDidUpdate() {
    emitEvent("time-drawer-changed", {});
  }

  wordingToShowUser = () => {
    const logsTimeString = getNextStartTimeFromLogs(this.props.time.logs);
    const todayTimeString = getTimeStringFromDateObject(new Date());

    if (this.props.time.logs.length === 0) {
      return (
        // if there are no hours in this day show the blob to prompt users
        <h2 className="c-time-drawer__CTA u-sub-font--small u-color-white u-text-unselectable u-flex u-align-center u-pulse-animation">
          <div>
            <div className="o-blob"></div>
          </div>
          Starting from midnight, what have you done today? <br />
          Drag your hours into your pots.
        </h2>
      );
    } else if (logsTimeString > todayTimeString) {
      if (this.props.auth.createdOn) {
        const dateUserCreatedProfile = this.props.auth.createdOn.split("T")[0];
        const todaysDate = getDBDateFormat(new Date());
        if (todaysDate === dateUserCreatedProfile) {
          this.showNewUserModal();
        }
      }
      return (
        <h2
          className={`c-time-drawer__CTA u-sub-font--small u-color-white u-text-unselectable ${
            this.props.auth.isBrandNewUser ? null : "u-hide--mobile"
          }`}
        >
          Well done! What are you going to do now? <b>Make it worth while!</b>
          <br />
          And come back to see your life balance later.
        </h2>
      );
    } else {
      return (
        <h2
          className={`c-time-drawer__CTA u-sub-font--small u-color-white u-text-unselectable  ${
            this.props.auth.isBrandNewUser ? null : "u-hide--mobile"
          }`}
        >
          What did you do at {logsTimeString}?
        </h2>
      );
    }
  };

  showNewUserModal = () => {
    const modalContent = () => (
      <div>
        <OfferToHelpModal />
      </div>
    );
    this.props.showModal({ component: modalContent });
  };

  render() {
    return (
      <div
        id="time-drawer"
        className="u-wrap u-ph--20--desktop u-left--0 u-right--0 c-time-drawer"
      >
        <div className="">
          {this.wordingToShowUser()}
          <Droppable droppableId="c-time-drawer">
            {(provided, snapshot) => (
              <div
                className="c-time-drawer__shape"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {timeBlocks.map((block, index) => (
                  <HourBlock key={index} block={block} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}

TimeDrawer.propTypes = {
  type: PropTypes.string,
  isLandingPage: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  time: state.time,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getNextStartTimeFromLogs,
  showModal,
})(TimeDrawer);
