// this file registers the service worker code defined in sw.js and gets the push notifications credentials

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function register(config) {
  console.log("trying to register");

  // check the browser supports serviceWorker else do not try to install and if the developer wants it on right now
  if (
    process.env.REACT_APP_SERVICE_WORKER_ENABLED !== "false" && //this is set in  client .env
    "serviceWorker" in navigator
  ) {
    console.log("registering - waiting for page to load");

    window.addEventListener("load", () => registerServiceWorker(config));
  } else {
    console.log("sw not supported");
  }
}

function registerServiceWorker(config) {
  // define where your service worker code exists
  const swUrl = "/sw.js";

  if (isLocalhost) {
    checkValidServiceWorker(swUrl, config);

    // Add some additional logging to localhost, pointing developers to the service worker/PWA documentation.
    navigator.serviceWorker.ready.then(() => {
      console.log(
        "This web app is being served cache-first by a service " +
          "worker. To learn more, visit https://bit.ly/CRA-PWA"
      );
    });
  } else {
    // Is not localhost. Just register service worker
    registerValidSW(swUrl, config);
  }
}

export function checkIfNotificationSubscriptionExists(callback) {
  if (navigator.serviceWorker && navigator.serviceWorker.ready) {
    // check a service worker exists
    navigator.serviceWorker.ready.then((registration) => {
      if (registration.pushManager) {
        registration.pushManager
          .getSubscription()
          .then(async function (subscription) {
            let result;
            // if subscription doesn't exist
            if (!subscription) {
              result = false;
            } else {
              result = true;
            }
            callback(result);
          });
      } else {
        callback(false);
      }
    });
  }
}

export function createNotificationSubscription(callback) {
  if (!navigator.serviceWorker) {
    return callback(false);
  }
  // check the service worker is ready
  navigator.serviceWorker.ready.then((registration) => {
    if (registration.pushManager) {
      // check if there is a subscription
      registration.pushManager
        .getSubscription()
        .then(async function (subscription) {
          console.log(subscription);
          // if subscription exists already
          if (subscription) {
            return callback(true);
          }
          // Otherwise lets create a subscription
          // get everyhours VAPID key
          const response = await fetch("/api/notifications/vapidPublicKey");
          const vapidPublicKey = await response.text(); // is in binary form
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
          // send it to service worker and to backend
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey,
            })
            .then((subscription) => {
              // Read the serialised subscription
              subscription = JSON.parse(JSON.stringify(subscription));
              // On first load not all the functions of a service worker are available and hence we will need to force a reload
              if (!navigator.serviceWorker.controller) {
                window.location.reload();
                return callback(false);
              }

              // https://medium.com/@madridserginho/how-to-handle-webpush-api-pushsubscriptionchange-event-in-modern-browsers-6e47840d756f

              // Send subscription to service worker to save it so that we can update it at a later time
              navigator.serviceWorker.controller.postMessage({
                action: "REQUEST_SUBSCRIPTION",
                subscription: subscription,
              });

              // register the browser subscription details in our backend
              fetch("/api/notifications/register", {
                method: "post",
                headers: {
                  "Content-type": "application/json",
                  Authorization: localStorage.jwtToken,
                },
                body: JSON.stringify({
                  subscription: subscription,
                }),
              })
                .then(() => {
                  return callback(true);
                })
                .catch((error) => {
                  console.log(error);
                  return callback(false);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      callback(false);
    }
  });
}

export function removeNotificationSubscription() {
  if (!navigator.serviceWorker) {
    return;
  }
  navigator.serviceWorker.ready.then(function (reg) {
    reg.pushManager.getSubscription().then(function (subscription) {
      subscription
        .unsubscribe()
        .then(function (successful) {
          return true;
        })
        .catch(function (e) {
          return false;
        });
    });
  });
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(() => {
      console.log("service worker registered");
    })

    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        window.removeEventListener("load", () => registerServiceWorker());
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
