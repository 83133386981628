import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { requestError } from "../../actions/errorActions";
import { validateEmail } from "../../utils/UIFunctions";

class HVCOEmailForm extends Component {
  state = {
    email: "",
    hasRegistered: false,
    errors: {},
  };

  sendContactDetails = () => {
    if (this.state.email) {
      const email = this.state.email;
      if (validateEmail(email)) {
        return axios
          .post(`/api/marketing/register`, {
            email,
            hvcoName: this.props.hvcoName,
          })
          .then(async () => {
            this.setState({ hasRegistered: true });
            setTimeout(() => this.sendUserToLandingPage(), 3000);
          })
          .catch((err) => {
            requestError(err);
          });
      } else {
        this.setState({
          errors: { email: "This email looks broken?" },
        });
      }
    }
  };

  sendUserToLandingPage = () => {
    window.location.href = "/";
  };

  onChange = (e) => {
    const userInput = e.target.value;
    this.setState({ email: userInput });
  };

  render() {
    return (
      <div id="contact-from" className="u-w-75% u-m0auto u-mt--minus28">
        {this.state.hasRegistered ? (
          <div className="u-sub-font--medium u-color-white">On its way!</div>
        ) : (
          <div>
            <div className="u-sub-font--medium u-color-white">
              {this.props.formTitle}
            </div>
            <div className="u-sub-font--small u-color-white">
              {this.props.formSubtitle}
            </div>
            <div className="u-w-all u-mt--16">
              <input
                className="u-w-all u-white-background o-input-field u-p--12"
                onChange={this.onChange}
                value={this.state.email}
                id="email"
                type="email"
                placeholder="Email"
              />
              <span className="u-input-error-text">
                {this.state.errors && this.state.errors.email
                  ? this.state.errors.email
                  : null}
              </span>
            </div>
            <div className="u-mt--24">
              <button
                className="o-button--green u-sub-font--vsmall "
                onClick={() => this.sendContactDetails()}
              >
                {this.props.buttonText ? this.props.buttonText : "Read how"}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

HVCOEmailForm.propTypes = {
  hvcoName: PropTypes.string.isRequired,
  formSubtitle: PropTypes.string,
  formTitle: PropTypes.string,
  buttonText: PropTypes.string,
};

export default HVCOEmailForm;
