import React, { Component } from "react";

import Navbar from "../../components/layout/Navbar.js";
import BackgroundBlobTop from "../../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../../components/objects/BackgroundBlobBottom.js";
import Footer from "../../components/layout/Footer.js";
import { suggestedArticles } from "./blogPostsDirectory.js";

class BlogHome extends Component {
  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden u-z-index---1">
          <BackgroundBlobTop />
          <Navbar fixedPosition={true} />
          <div className="u-w-all u-overflow--hidden">
            <img
              className="o-banner-image"
              src="https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1866&q=80"
            />
          </div>
          <div className="u-w-all u-wrap">
            <div className=" u-align-center u-mt--32 ">
              <h3 className="u-sub-font--medium u-mt--32 u-mb--32 u-color-white u-text-center">
                Recommended reading
              </h3>
              {suggestedArticles()}
            </div>
            <Footer />
          </div>
          <BackgroundBlobBottom />
        </div>
      </div>
    );
  }
}

export default BlogHome;
