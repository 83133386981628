import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import { hideModal } from "../../actions/modalActions";

import { ModalPropTypes } from "../../utils/propTypes";

import { Icon } from "./Icon";

class Modal extends Component {
  closeModalMethods = async () => {
    if (this.props.modal.onClose) {
      await this.props.modal.onClose();
    }
    this.props.hideModal();
  };

  render() {
    const { status, component, data, className, position, modalStyle } =
      this.props.modal;

    const modalIsHidden = status === "hidden";

    return (
      <div
        className={classnames("o-modal", {
          "o-modal--content-centered": position === "centered",
          "u-opacity--0 u-vis--hidden": !!modalIsHidden,
          "u-opacity--1": !modalIsHidden,
          className,
        })}
      >
        <div className="o-modal__background" onClick={this.closeModalMethods} />
        <div className={classnames("o-modal__container", { className })}>
          <div className="o-modal__content" style={modalStyle}>
            <button
              className="u-absolute u-top--0 u-right--0 u-p--32"
              onClick={this.closeModalMethods}
            >
              <Icon id="clear" size="xl" className="o-icon" />
            </button>
            {component && component(data)}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  modal: PropTypes.shape(ModalPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { hideModal })(Modal);
