import React, { Component } from "react";

import Navbar from "../components/layout/Navbar.js";

import BackgroundBlobTop from "../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../components/objects/BackgroundBlobBottom.js";

import { track } from "../utils/mixpanel.js";
import Footer from "../components/layout/Footer.js";
import HVCOEmailForm from "../components/layout/HVCOEmailForm.js";

class Landing extends Component {
  componentDidMount() {
    track("open-landing");
  }

  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden  u-z-index---1">
          <BackgroundBlobTop />
          <div className="u-w-all u-wrap--landing">
            <Navbar fixedPosition={true} />
            <div className="u-p--8 u-align-center u-text-unselectable">
              <div id="header" className="u-flex u-align-center u-mt--120 ">
                <div className="u-flex u-flex-column u-m0auto u-align-center">
                  <div className="u-sub-font--vvlarge u-color-white u-text-center u-mb--32">
                    Improve your life balance,{" "}
                    <span className="u-main-font--vvlarge">everyhour</span>.
                  </div>
                  <div className="u-color-white u-sub-font--medium u-text-center u-mt--16">
                    Time is our most valuable asset.
                  </div>
                  <div className="u-color-white u-sub-font--medium u-text-center">
                    How do you spend it?
                  </div>
                  <div
                    id="statement"
                    className="u-sub-font--medium u-color-white u-mh--80--desktop u-text-center"
                    style={{ lineHeight: "1.6" }}
                  >
                    <span className="u-main-font--medium">everyhour</span>
                    {""} is the easiest way to record how you spend your time
                  </div>
                  <div className=" u-mt--48 u-mt--32--mobile">
                    <a href="/register" className="u-link-white">
                      <div className="o-rainbow-background ">
                        <div className="o-button--landing u-main-font--small change-background-color">
                          Start now - it's free!
                        </div>
                      </div>
                      <div className="u-color-white u-text-center u-sub-font--vsmall u-mt--8">
                        No credit card needed
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="u-flex u-justify-center u-mt--120">
                <a
                  href="https://www.producthunt.com/posts/everyhour?utm_source=badge-review&utm_medium=badge&utm_souce=badge-everyhour#discussion-body"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=302611&theme=dark"
                    alt="everyhour - Your life balance: what gets measured, gets managed | Product Hunt"
                    style={{ width: "250px", height: "54px" }}
                  />
                </a>
              </div>

              <div className="u-grid--1--mobile u-grid--3 u-sub-font--vsmall u-color-white u-w-all u-text-center">
                <div>
                  <div className="u-mh--32 u-mt--32--mobile o-analytics-key-highlights">
                    <b>
                      "Time tracking apps felt like they required more effort
                      than I was saving -{" "}
                      <span className="u-italic">everyhour </span> is different"
                    </b>
                    <div className="u-mt--32--desktop">Arnaud - CEO</div>
                  </div>
                </div>
                <div className=" u-mt--64--desktop ">
                  <div className="o-analytics-key-highlights u-mh--32 ">
                    <b>
                      "everyhour has helped find more hours in the day, stay on
                      top of uni and be mindful of how I spend time."
                      <br />
                      "...it keeps me accountable and allows me to honestly
                      reflect… "
                    </b>
                    <div className="u-mt--32--desktop">
                      Rhys - Undergraduate
                    </div>
                  </div>
                </div>
                <div>
                  <div className=" o-analytics-key-highlights u-mh--32 u-mt--32--mobile">
                    <b>
                      "It has done what I needed it to do! I now have a
                      completely different routine to how I start my day. Now I
                      don't need to use Everyhour - it has been very easy and
                      very effective."
                    </b>
                    <div className="u-mt--32--desktop">Jake - Developer</div>
                  </div>
                </div>
              </div>

              <div className="u-flex u-justify-center u-mt--120">
                <div className="u-align-center u-text-center u-color-white ">
                  <div className=" u-m0auto u-mb--24">
                    <div className="u-sub-font--medium">
                      <span className="u-bold">Track</span> your time by easily
                      dragging chunks of time into custom pots
                    </div>
                  </div>

                  <img
                    alt="the tacking page showing you can drag and drop time blocks into pots to record you day"
                    src="app-mock--track.png"
                    className="u-fill-available u-w-all--mobile"
                  />
                </div>
              </div>

              <div className="u-flex u-justify-center u-mt--120">
                <div className="u-align-center u-text-center u-color-white ">
                  <div className="u-m0auto u-mb--24">
                    <div className="u-sub-font--medium">
                      <span className="u-bold">Analyse</span> your online and
                      offline targets and take charge of your life balance
                    </div>
                  </div>

                  <img
                    alt="the key features in the analyse tab, showing your pie chart life balance and your targets"
                    src="analyse_cards.png"
                    className="u-wrap"
                  />
                </div>
              </div>

              <div className="u-mauto u-mv--120 u-mv--120--mobile u-text-center">
                <div className="u-mb--48 u-color-white u-main-font--medium">
                  Want to see what you really prioritise in your life?
                </div>
                <div className="u-flex u-justify-center u-mt--48 u-mt--32--mobile">
                  <a href="/register" className="u-link-white">
                    <div className="o-rainbow-background ">
                      <div className="o-button--landing u-main-font--small">
                        Start correcting your life balance
                      </div>
                    </div>
                  </a>
                </div>
                <div className="u-mt--48  u-sub-font--small">
                  <a className="u-color-white" href="/about">
                    Want to learn more?
                  </a>
                </div>
              </div>

              <div className="u-mv--64 u-mv--32--mobile u-flex-column--mobile">
                <div className="u-main-font--large u-color-white u-mb--8">
                  What's coming?
                </div>
                <div className="u-sub-font--medium u-color-white u-mb--16">
                  Got a feature request?{" "}
                  <a
                    className="u-color-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/AlexCharlesGoff"
                  >
                    {" "}
                    Tell me
                  </a>
                </div>
                <iframe
                  title="trello board with the everyhour roadmap on it"
                  data-hj-allow-iframe=""
                  className="o-iframe-fullscreen-content"
                  src="https://trello.com/b/KhMiy0ZR.html"
                ></iframe>
              </div>

              <div className="u-align-center u-mt--48 u-mb--32 u-color-white">
                <div className="u-sub-font--medium u-text-center">
                  Doubt this will work for you?{" "}
                </div>

                <div className="u-m0auto u-mt--48 u-text-center u-w-half--desktop">
                  <HVCOEmailForm
                    hvcoName="just_interested"
                    formTitle="I write about tools, tactics and tips to help improve life balance, without the bull"
                    formSubtitle="Learn from my experiences"
                    buttonText="Subscribe"
                  />
                </div>
                <p className=" u-sub-font-small u-text-center u-mt--32">
                  <a className="u-color-white" href="/blog">
                    If you want a sneak peak have a look at the blog page.
                  </a>
                </p>
              </div>

              <Footer />
            </div>
            <BackgroundBlobBottom />
          </div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {};

export default Landing;
