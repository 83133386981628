import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from "recharts";

import { fetchPots } from "../../../actions/potActions.js";
import {
  fetchDailyPotHours,
  fetchDailyPotTypeHours,
  fetchThisWeeksPotsTotalHours,
  fetchThisWeeksPotTypeTotalHours,
  fetchThisMonthsPotsTotalHours,
  fetchThisMonthsPotTypeTotalHours,
  fetchAllTimePotsTotalHours,
  fetchAllTimePotTypesTotalHours,
} from "../../../actions/analyticalActions.js";

import {
  convertDailyPotHoursIntoLineChartData,
  convertDailyPotTypeHoursIntoLineChartData,
  aggregateRunningTotal,
} from "../../../utils/analyticsFunctions";
import { createClone } from "../../../utils/arrayFunctions";
import { potTypes } from "../../../utils/constants";

class PotsProgressChart extends Component {
  state = {
    visibleLines: [],
    isAggregated: true,
  };

  componentDidMount = async () => {
    //convert api response into linechart shape
    const potHoursLineChartData = convertDailyPotHoursIntoLineChartData(
      this.props.analytics.dailyPotHours,
      this.props.pots.customPots
    );
    //convert api response and save it for later
    const potTypesHoursLineChartData =
      convertDailyPotTypeHoursIntoLineChartData(
        this.props.analytics.dailyPotTypeHours
      );

    this.setState({
      //jsonify to strip the ability to change constants by accident when we hide the lines
      visibleLines: createClone(potTypes),
      data: potTypesHoursLineChartData,
      dataType: "dailyPotTypeHours",
      isAggregated: true,
      potHoursLineChartData: potHoursLineChartData,
      potTypesHoursLineChartData: potTypesHoursLineChartData,
    });
  };

  toggleIndividualLineSelection = (toggledButtonId) => {
    // copy state
    const newVisibleLines = createClone(this.state.visibleLines);

    //find if the pot's data is visible or not
    const locationInArray = newVisibleLines.findIndex((visibleLine) => {
      return visibleLine.id === toggledButtonId;
    });

    if (locationInArray > -1) {
      //if it is visible, remove it from the visible array in state
      newVisibleLines.splice(locationInArray, 1);
      this.setState({
        visibleLines: newVisibleLines,
      });
    } else {
      if (this.state.dataType === "dailyPotHours") {
        //find which pots details we need to put back in
        const pot = this.props.pots.customPots.find(
          (pot) => pot.id === toggledButtonId
        );
        newVisibleLines.push(pot);
      } else if (this.state.dataType === "dailyPotTypeHours") {
        //find which type we need to put back in
        const matchingPotType = potTypes.find(
          (potType) => potType.id === toggledButtonId
        );
        newVisibleLines.push(matchingPotType);
      }

      this.setState({
        visibleLines: newVisibleLines,
      });
    }
  };

  toggleAllLines = () => {
    if (this.state.hideAllLines) {
      this.setState({
        visibleLines:
          this.state.dataType === "dailyPotHours"
            ? createClone(this.props.pots.customPots)
            : createClone(potTypes),
        hideAllLines: false,
      });
    } else {
      this.setState({
        visibleLines: [],
        hideAllLines: true,
      });
    }
  };

  togglePotType = () => {
    // change the dataType and show all data one the line charts
    this.setState({
      dataType:
        this.state.dataType === "dailyPotHours"
          ? "dailyPotTypeHours"
          : "dailyPotHours",
      visibleLines:
        this.state.dataType === "dailyPotHours"
          ? createClone(potTypes)
          : createClone(this.props.pots.customPots),
    });
  };

  toggleIsAggregated = () => {
    this.setState({
      isAggregated: !this.state.isAggregated,
    });
  };

  // if a pot or pot type has a value of 0, hide it from the legend
  customLineChartTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div style={{ color: "white", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <p className="intro">{label}</p>
          <div className="label">
            {payload
              .sort((a, b) => {
                return b.value - a.value;
              })
              .map((element) => {
                if (element.value === 0) {
                  return "";
                } else {
                  return (
                    <div>
                      <span>{`${element.name || "Deleted pot"} : ${
                        element.value
                      }`}</span>
                      <br />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      );
    }

    return null;
  };

  selectDataset = () => {
    // there are 4 conditions either the data is for pots or potTypes, or they are aggregated or not
    switch (this.state.dataType) {
      case "dailyPotTypeHours":
        switch (this.state.isAggregated) {
          case true:
            return aggregateRunningTotal(this.state.potTypesHoursLineChartData);
          case false:
            return this.state.potTypesHoursLineChartData;
          // no default
        }
        break;
      case "dailyPotHours":
        switch (this.state.isAggregated) {
          case true:
            return aggregateRunningTotal(this.state.potHoursLineChartData);
          case false:
            return this.state.potHoursLineChartData;
          // no default
        }

      // no default
    }
  };

  render() {
    return (
      <div className="o-analytics-key-highlights">
        <div className="u-flex">
          <div className="u-main-font--large u-color-white">
            Your aggregated time
          </div>
          <div className="u-right--0 u-mlauto">
            <button
              className="o-button--green u-m--4 u-sub-font--vsmall "
              onClick={this.toggleAllLines}
            >
              {this.state.hideAllLines ? "Show all" : "Hide all"}
            </button>
            <button
              className="o-button--green u-m--4 u-sub-font--vsmall "
              onClick={this.togglePotType}
            >
              {this.state.dataType === "dailyPotHours"
                ? "Switch to Pot Types"
                : "Switch to all Pots"}
            </button>
            <button
              className="o-button--green u-m--4 u-sub-font--vsmall"
              onClick={this.toggleIsAggregated}
            >
              {this.state.isAggregated
                ? "Unaggregate hours"
                : "Aggregate hours"}
            </button>
          </div>
        </div>
        <LineChart
          //to stop overflow of the max
          width={this.props.width}
          height={this.props.height}
          margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          data={this.selectDataset()}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            name="date"
            domain={["auto", "auto"]}
            label={{
              value: "Days Recorded",
              offset: -20,
              position: "insideBottom",
              fill: "white",
              fontSize: "12px",
            }}
            style={{ fontSize: "12px" }}
          />
          <YAxis
            label={{
              value: "Hours",
              angle: -90,
              position: "insideLeft",
              fill: "white",
            }}
            style={{ fontSize: "12px" }}
          />
          <Tooltip content={this.customLineChartTooltip} />
          {this.state.visibleLines.map((pot, index) => {
            return (
              <Line
                type="monotone"
                dataKey={pot.id}
                stroke={pot.colour}
                name={pot.name}
                isAnimationActive={false}
                dot={false}
                key={index}
              />
            );
          })}
          <Brush dataKey="date" gap={1} />
        </LineChart>

        <div className="o-line-chart__buttons-group">
          <div className="o-line-chart__buttons-group__pots">
            {this.state.dataType === "dailyPotHours"
              ? this.props.pots.customPots.map((pot) => {
                  const isVisible = this.state.visibleLines.find(
                    (element) => element.id === pot.id
                  );
                  return (
                    <button
                      className="o-button u-m--12 u-main-font--small"
                      style={{
                        backgroundColor: isVisible ? pot.colour : "grey",
                      }}
                      onClick={() => this.toggleIndividualLineSelection(pot.id)}
                    >
                      {pot.name}
                    </button>
                  );
                })
              : potTypes.map((potType, index) => {
                  const isVisible = this.state.visibleLines.find(
                    (element) => element.id === potType.id
                  );
                  return (
                    <button
                      className="o-button u-m--12 u-main-font--small"
                      style={{
                        backgroundColor: isVisible ? potType.colour : "grey",
                      }}
                      onClick={() =>
                        this.toggleIndividualLineSelection(potType.id)
                      }
                      key={index}
                    >
                      {potType.name}
                    </button>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}

PotsProgressChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  auth: state.auth,
  pots: state.pots,
  time: state.time,
});

export default connect(mapStateToProps, {
  fetchPots,
  fetchDailyPotHours,
  fetchDailyPotTypeHours,
  fetchThisWeeksPotTypeTotalHours,
  fetchThisMonthsPotTypeTotalHours,
  fetchThisMonthsPotsTotalHours,
  fetchThisWeeksPotsTotalHours,
  fetchAllTimePotsTotalHours,
  fetchAllTimePotTypesTotalHours,
})(PotsProgressChart);
