import axios from "axios";

import {
  getDateOfNextDay,
  isDateWithinTheLast2Days,
  getDBDateFormat,
  getDateObjectFromString,
  checkIfLogsDurationGoesTo24Hours,
} from "../utils/dateFunctions";

import { requestError } from "./errorActions";
import {
  UPDATE_LOGS,
  UPDATE_DATE,
  REMOVE_LOGS,
  ADD_PAGE_OF_LOGS,
} from "./types";

// ACTIONS

// get all records for a user for the date provided
export const saveRecord = (data) => async (dispatch) => {
  try {
    await axios.post(`/api/v2/users/records`, data);
  } catch (err) {
    dispatch(requestError(err));
  }
};

// undo the specified number of actions for a user
export const undoRecords = (undoCount, date) => async (dispatch) => {
  try {
    const dateParam = date ? `?date=${getDBDateFormat(date)}` : "";

    await axios.delete(`/api/v2/users/records/undo/${undoCount}${dateParam}`);

    dispatch(removeLogs(undoCount));
  } catch (err) {
    dispatch(requestError(err));
  }
};

// get all records for a user for the date provided
export const fetchDayRecords =
  ({ date = undefined }) =>
  async (dispatch) => {
    try {
      const dateParam = date ? `?date=${getDBDateFormat(date)}` : "";

      const response = await axios.get(`/api/v2/users/records${dateParam}`);

      dispatch(updateLogs(response.data));
    } catch (err) {
      dispatch(requestError(err));
    }
  };

// get a certain number of day's worth of records
export const fetchPageOfRecords =
  ({ pageNumber = 1, pageSize = 30 }) =>
  async (dispatch) => {
    try {
      const numberOfDaysParam = `?numberOfDays=${pageSize}&page=${pageNumber}`;

      const response = await axios.get(
        `/api/v2/users/records${numberOfDaysParam}`
      );

      if (pageNumber === 1) {
        dispatch(updateLogs(response.data));
      } else {
        dispatch(addPageOfLogs(response.data));
      }
    } catch (err) {
      dispatch(requestError(err));
    }
  };

// get the latest logs for a user
export const fetchLatestRecords = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/v2/users/records/latest`);

    if (data && data.length) {
      const dateOfLastRecord = data[0].date;

      // If date is in last 2 days we will choose that instead of todays date
      const date = getDateObjectFromString(dateOfLastRecord);
      const lastRecordIsInTheLast2Days = isDateWithinTheLast2Days(date);
      if (lastRecordIsInTheLast2Days) {
        // if it is within that date, but the user has already recorded 24 hours in that day go to the next date
        if (checkIfLogsDurationGoesTo24Hours(data)) {
          const dayAfter = getDateOfNextDay(date);
          dispatch(updateDateSelection(dayAfter));
        } else {
          // otherwise just pick the date from the record
          dispatch(updateDateSelection(date));
        }
      }
    }
  } catch (err) {
    dispatch(requestError(err));
  }
};

// reset logs to be empty
export const resetLogs = () => (dispatch) => {
  dispatch(updateLogs([]));
};

// DISPATCHERS

// update the date in the store
export const updateDateSelection = (date) => {
  return {
    type: UPDATE_DATE,
    date: date,
  };
};

// update the date in the store
export const updateDateToBeNextDay = (date) => {
  const tomorrowsDate = getDateOfNextDay(date);
  return {
    type: UPDATE_DATE,
    date: tomorrowsDate,
  };
};

// update all logs in the store
export const updateLogs = (newLogs) => {
  return {
    type: UPDATE_LOGS,
    logs: newLogs,
    endReached: false,
  };
};

// add more logs to the logs store array
export const addPageOfLogs = (newLogs) => {
  return {
    type: ADD_PAGE_OF_LOGS,
    logs: newLogs,
    endReached: !newLogs.length,
  };
};

// remove a specific number of logs in the store
export const removeLogs = (removeCount) => {
  return {
    type: REMOVE_LOGS,
    removeCount,
    endReached: false,
  };
};
