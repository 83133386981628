import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <div className="u-mt--80 u-flex u-justify-center">
          <div className="u-sub-font--vsmall u-color-white">
            Made with{" "}
            <span role="img" alt="love" aria-label="love">
              💌
            </span>
          </div>
        </div>
        <div>
          <div className="u-flex u-justify-center">
            <div className="u-sub-font--vsmall u-m--20">
              <a
                className="u-color-white"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/alexcharlesgoff"
              >
                Twitter
              </a>
            </div>
            <div className="u-sub-font--vsmall u-m--20">
              <a
                href="https://medium.com/everyhour"
                target="_blank"
                rel="noopener noreferrer"
                className="u-color-white"
              >
                Medium
              </a>
            </div>
            <div className="u-sub-font--vsmall u-m--20">
              <a
                href="https://www.reddit.com/r/everyhourApp/"
                className="u-color-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                Reddit
              </a>
            </div>
            <div className="u-sub-font--vsmall u-color-white u-m--20">
              <a
                href="https://www.websitepolicies.com/policies/view/KNpQEkQZ"
                className="u-color-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
            <div className="u-sub-font--vsmall u-color-white u-m--20">
              <a
                href="https://www.websitepolicies.com/policies/view/GrLNc9fQ"
                className="u-color-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie policy
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
