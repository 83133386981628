import React, { Component } from "react";

class BackgroundBlobTop extends Component {
  render() {
    return (
      <div className="o-background-image u-z-index---1 u-bottom--0">
        <img src="/transparent-blob--bottom-right.svg" alt="background tint" />
      </div>
    );
  }
}

export default BackgroundBlobTop;
