import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Pot from "../components/layout/Pot";
import { Icon } from "../components/objects/Icon.js";

import { fetchPots, updateOrderOfPots } from "../actions/potActions.js";
import { getHeightOfElementWithId } from "../utils/UIFunctions";
import routes from "../utils/routes";
import { AuthPropTypes } from "../utils/propTypes";

class Reorder extends Component {
  state = {
    pots: [],
    hiddenPots: [],
    previewMode: "mobile",
  };

  componentDidMount = async () => {
    const {
      auth: {
        user: { id },
      },
    } = this.props;

    await Promise.all([
      // get all the pots a user has
      this.props.fetchPots(id),
    ]);

    // split the pots into the right groups
    this.splitHiddenAndNot(this.props.pots.customPots);
  };

  splitHiddenAndNot = (pots) => {
    let hiddenPots = [];
    let activePots = [];

    pots.forEach((pot) => {
      if (pot.finishedStatus === true) {
        hiddenPots.push(pot);
      } else {
        activePots.push(pot);
      }
    });

    // add an index to both sets
    activePots = this.addIndex(activePots);
    hiddenPots = this.addIndex(hiddenPots);

    this.setState({
      hiddenPots,
      pots: activePots,
    });
  };

  addIndex = (pots) => {
    pots.forEach((pot, index) => {
      return (pot.index = index);
    });
    return pots;
  };

  reorderList = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result = this.addIndex(result);

    return result;
  };

  movePotBetweenLists = (fromIndex, fromList, toIndex, toList, nowHidden) => {
    //remove from the original array
    let [movedPot] = fromList.splice(fromIndex, 1);
    // set finished status
    movedPot.finishedStatus = nowHidden;

    // add to the other list
    toList.splice(toIndex, 0, movedPot);

    // renumber for the db
    toList = this.addIndex(toList);
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // if pots move lists
    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId === "reorder-pots") {
        // move to hidden pots
        this.movePotBetweenLists(
          source.index,
          this.state.pots,
          destination.index,
          this.state.hiddenPots,
          true
        );
      } else {
        // from hidden to not
        this.movePotBetweenLists(
          source.index,
          this.state.hiddenPots,
          destination.index,
          this.state.pots,
          false
        );
      }
    } else {
      // pots have simply moved order
      if (source.droppableId === "reorder-pots") {
        this.setState({
          pots: this.reorderList(
            this.state.pots,
            result.source.index,
            result.destination.index
          ),
        });
      } else {
        this.setState({
          hiddenPots: this.reorderList(
            this.state.hiddenPots,
            result.source.index,
            result.destination.index
          ),
        });
      }
    }
  };

  togglePreviewMode = () => {
    this.setState({
      previewMode: this.state.previewMode === "desktop" ? "mobile" : "desktop",
    });
  };

  saveChanges = async () => {
    // only update index
    let contract = [];
    this.state.pots.map((pot) => {
      return contract.push({
        id: pot.id,
        index: pot.index,
        finishedStatus: pot.finishedStatus,
      });
    });

    this.state.hiddenPots.map((pot) => {
      return contract.push({
        id: pot.id,
        index: pot.index,
        finishedStatus: pot.finishedStatus,
      });
    });

    await this.props.updateOrderOfPots(contract);

    // if good send to next page
    if (!Object.keys(this.props.errors).length) {
      window.location.href = routes.home;
    }
  };

  render() {
    if (!this.state.pots) {
      return <h1 className="u-main-font--large">Loading...</h1>;
    }

    const heightOfPotsColumn =
      window.innerHeight -
      (getHeightOfElementWithId("reorder-banner", document) +
        getHeightOfElementWithId("back-button", document));

    return (
      <div className="u-fixed u-top--0 u-w-all u-h-all u-blue-background u-overflow-hidden">
        <button
          className="u-color-white u-flex u-align-center u-pv--24"
          onClick={this.saveChanges}
          id="back-button"
        >
          <Icon id="chevron-left" size="xl" className="o-icon" />
          <div className="u-sub-font--small u-inline u-link--active">
            Save changes
          </div>
        </button>
        <div className="u-wrap u-m0auto">
          <div id="reorder-banner" className="u-ph--24">
            <div className="u-main-font--large u-color-white u-pv--24">
              Reorder your pots
            </div>

            <div className="u-sub-font--small u-color-white">
              Simply drag and drop them in the order you want
            </div>
          </div>
          <div className="u-flex">
            <div
              className={
                window.innerWidth < 500
                  ? "u-m--12 u-w-all"
                  : "u-m--12 u-w--25pct"
              }
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <div
                  className="pots-container u-pb--16"
                  style={{ height: heightOfPotsColumn + "px" }}
                >
                  <Droppable droppableId="reorder-pots">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {this.state.pots.map((pot, index) => (
                          <Draggable
                            key={pot.id}
                            draggableId={pot.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="u-m--8"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Pot
                                  pot={pot}
                                  key={index}
                                  isEditable={false}
                                  size={"thin"}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                  <div className="u-sub-font--medium u-color-white">
                    Hidden Pots
                  </div>
                  <Droppable droppableId="hidden-pots">
                    {(provided, snapshot) => (
                      <div
                        className="u-pb--64"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.state.hiddenPots.map((pot, index) => (
                          <Draggable
                            key={pot.id}
                            draggableId={pot.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="u-m--8"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Pot
                                  pot={pot}
                                  key={index}
                                  isEditable={false}
                                  size={"thin"}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
            {
              // on mobile dont render the preview
              window.innerWidth < 500 ? null : (
                <div className="u-m--12 u-w--75pct">
                  {this.state.previewMode === "desktop" ? (
                    <div className="u-m0auto u-slim-border--grey u-p--12">
                      <div className="u-flex u-align-center">
                        <div className="u-color-white u-sub-font--medium u-mv--12">
                          Desktop Preview
                        </div>
                        <button
                          className="o-button--green u-right--0 u-mlauto"
                          onClick={this.togglePreviewMode}
                        >
                          Mobile view
                        </button>
                      </div>
                      <div
                        className="pots-container__grid pots-container  u-overflow-scroll"
                        style={{ height: "750px" }}
                      >
                        {this.state.pots.map((pot, index) => {
                          return pot.finishedStatus ? null : (
                            <div>
                              <Pot pot={pot} key={index} isEditable={false} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="u-m0auto u-slim-border--grey"
                      style={{ width: "440px" }}
                    >
                      <div className="u-flex u-align-center u-m--12">
                        <div className="u-color-white u-sub-font--medium u-mv--12">
                          Mobile Preview
                        </div>
                        <button
                          className="o-button--green u-right--0 u-mlauto"
                          onClick={this.togglePreviewMode}
                        >
                          Desktop view
                        </button>
                      </div>
                      <div
                        className="pots-container__grid--mobile pots-container u-mauto"
                        style={{ width: "400px", height: "750px" }}
                      >
                        {this.state.pots.map((pot, index) => {
                          return pot.finishedStatus ? null : (
                            <div>
                              <Pot
                                pot={pot}
                                key={index}
                                isEditable={false}
                                size={"mobile"}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

Reorder.propTypes = {
  pots: PropTypes.object.isRequired,
  auth: PropTypes.shape(AuthPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pots: state.pots,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  fetchPots,
  updateOrderOfPots,
})(Reorder);
