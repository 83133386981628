import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import {
  updateDateSelection,
  fetchDayRecords,
  fetchLatestRecords,
  updateDateToBeNextDay,
  saveRecord,
} from "../../actions/timeActions";
import { fetchPots } from "../../actions/potActions";
import { showModal, hideModal } from "../../actions/modalActions";

import routes from "../../utils/routes";
import { getDBDateFormat } from "../../utils/dateFunctions";
import { AuthPropTypes, TimePropTypes } from "../../utils/propTypes";

import DayDisplay from "./DayDisplay";
import NoteWindow from "./NoteWindow";
import OnboardingPotCreation from "./OnboardingPotCreation";
import ProgressBar from "./ProgressBar";
import Tracking from "./Tracking";
import UndoButton from "./UndoButton";

class DashboardTracking extends Component {
  state = {
    note: "",
    loading: true,
  };

  componentDidMount = async () => {
    await Promise.all([
      // fetch the user's custom pots
      this.props.fetchPots(),
      // fetch the last day the user submitted
      this.props.fetchLatestRecords(),
    ]);

    // when we get the latest record we then set the day
    await this.props.fetchDayRecords({
      date: this.props.time.date,
    });

    this.setState({ loading: false });
  };

  onNewDay = async () => {
    await this.props.updateDateToBeNextDay(this.props.time.date);
    await this.props.fetchDayRecords({
      date: this.props.time.date,
    });
  };

  onNewLog = async (log) => {
    await this.props.saveRecord(log);
    // Refetch day records to populate the progress bar
    await this.props.fetchDayRecords({
      date: this.props.time.date,
    });
  };

  showOnboardingModal = () => {
    const modalContent = () => (
      <OnboardingPotCreation dismissModal={this.props.hideModal} />
    );

    this.props.showModal({
      component: modalContent,
      position: "centered",
      className: "u-blue-background u-mt--32 u-p--24",
    });
  };

  noteChange = (e) => {
    this.setState({
      note: e.currentTarget.value,
      noteIsSaved: false,
    });
  };

  submitDaySummary = async () => {
    // prepare the api request body with the date, note and user and then do actions
    const dateDescribed = getDBDateFormat(this.props.time.date);
    const note = this.state.note;

    if (this.state.noteExistsInDb) {
      await this.props.editNote({ note, dateDescribed });
    } else {
      await this.props.saveNewNote({ note, dateDescribed });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="u-mt--32 u-wrap">
          <h3 className="u-main-font--large u-color-white">Loading...</h3>
        </div>
      );
    }

    // when a user has no pots - take them through the welcome screen
    if (this.props.pots.customPots.length === 0) {
      return <Redirect to={routes.onboarding} />;
    }

    return (
      <>
        <div id="tracking-utils">
          <div className="u-flex u-flex-column--mobile u-align-center u-wrap">
            <DayDisplay />

            <div className="u-w-all u-align-center u-flex">
              <ProgressBar
                logs={this.props.time.logs}
                pots={this.props.pots.customPots}
              />
              <UndoButton />
            </div>
          </div>

          <div className="u-flex u-align-center u-mb--8--mobile u-mb--24 u-wrap">
            <NoteWindow />
          </div>
        </div>

        <Tracking
          customPots={this.props.pots.customPots}
          logs={this.props.time.logs}
          date={this.props.time.date}
          onNewDay={this.onNewDay}
          onNewLog={this.onNewLog}
        />
      </>
    );
  }
}

DashboardTracking.propTypes = {
  auth: PropTypes.shape(AuthPropTypes).isRequired,
  pots: PropTypes.object.isRequired,
  time: PropTypes.shape(TimePropTypes).isRequired,
  notes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pots: state.pots,
  time: state.time,
  notes: state.notes,
});

export default connect(mapStateToProps, {
  saveRecord,
  fetchPots,
  fetchDayRecords,
  fetchLatestRecords,
  updateDateSelection,
  showModal,
  hideModal,
  updateDateToBeNextDay,
})(DashboardTracking);
