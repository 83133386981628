import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchPots } from "../actions/potActions";
import { fetchPageOfRecords } from "../actions/timeActions";
import { fetchNotes } from "../actions/notesActions";

import Navbar from "../components/layout/Navbar";
import ProgressBar from "../components/layout/ProgressBar";

import { convertDateToDDMMYY } from "../utils/dateFunctions";
import { track } from "../utils/mixpanel";
import BackgroundBlobTop from "../components/objects/BackgroundBlobTop";

interface Props {
  auth: AuthState;
  pots: PotsState;
  time: TimeState;
  notes: NotesState;
  logoutUser: () => void;
  requestError: (error: Error) => void;
  fetchPageOfRecords: ({
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }) => void;
  fetchPots: () => void;
  fetchNotes: () => void;
}

interface State {
  data: any;
  loading: boolean;
  pageNumber: number;
  pageSize: number;
  records: DayRecordsAndNote[];
}

class Diary extends Component<Props, State> {
  state = {
    data: null,
    loading: true,
    pageNumber: 1,
    pageSize: 20,
    records: [],
  };

  componentDidMount = async () => {
    track("open-diary");

    await Promise.all([
      // get all the pots a user has
      this.props.fetchPots(),
      // get all the notes a user has
      this.props.fetchNotes(),
    ]);

    await this.loadNextPageOfRecords();

    this.setState({ loading: false });
  };

  separateLogsIntoSeperateDays() {
    const { logs } = this.props.time;

    const dayRecords: DayRecordsAndNote[] = [];
    // for each record
    logs.forEach((log) => {
      // check if it's day is already recorded
      const indexOfDayRecords = dayRecords.findIndex(
        (day) => day.date === log.date
      );

      if (indexOfDayRecords > -1) {
        //if it is in the array, add it to the day in the right order
        const recordsForDay = dayRecords[indexOfDayRecords];
        recordsForDay.records.push(log);
      } else {
        // fetch the note for this day
        const noteForDay = this.props.notes.notes.find(
          (note: Note) => note.dateDescribed === log.date
        );

        //if it is not, create a new day with the date and add it to the record
        dayRecords.push({
          date: log.date,
          records: [log],
          note: noteForDay,
        });
      }
    });

    return dayRecords.reverse();
  }

  loadNextPageOfRecords = async () => {
    const { pageNumber, pageSize } = this.state;

    await this.props.fetchPageOfRecords({ pageNumber, pageSize });

    const nextPageNumber = pageNumber + 1;

    this.setState({
      pageNumber: nextPageNumber,
      records: this.separateLogsIntoSeperateDays(),
    });
  };

  render() {
    const {
      time: { endReached },
    } = this.props;
    const { records } = this.state;

    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-overflow-scroll u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-m0auto u-vh u-absolute u-top--0 u-left--0 u-right--0">
          <Navbar />
          <div className="u-mt--32 u-mt--16--mobile u-wrap">
            {this.state.loading ? (
              <h3 className="u-main-font--large u-color-white">Loading...</h3>
            ) : !records.length ? (
              <h3 className="u-main-font--large u-color-white">
                No records to display yet!
              </h3>
            ) : (
              <>
                {records.map((dayRecords: DayRecordsAndNote, index: number) => (
                  <div key={index} className="u-flex u-align-center">
                    <div className="o-hoverover-element u-sub-font--small u-color-white u-mr--16">
                      {convertDateToDDMMYY(dayRecords.date)}
                      {!!dayRecords.note && (
                        <div className="o-hoverover-overlay">
                          {dayRecords.note.note}
                        </div>
                      )}
                    </div>
                    <ProgressBar
                      logs={dayRecords.records}
                      pots={this.props.pots.customPots}
                    />
                  </div>
                ))}
                {!endReached && (
                  <button
                    className="o-button--purple o-button--stretch u-main-font--small"
                    type="button"
                    onClick={() => this.loadNextPageOfRecords()}
                  >
                    Load next 30 days
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: EveryHourState) => ({
  auth: state.auth,
  pots: state.pots,
  time: state.time,
  notes: state.notes,
});

export default connect(mapStateToProps, {
  fetchPageOfRecords,
  fetchPots,
  fetchNotes,
})(Diary);
