import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { saveNewPot } from "../../actions/potActions.js";
import { hideModal } from "../../actions/modalActions";
import { AuthPropTypes } from "../../utils/propTypes.js";

import NameInput from "./PotFields/NameInput.js";
import TypeInput from "./PotFields/TypeInput.js";
import ColourInput from "./PotFields/ColourInput.js";
import {
  nameChange,
  typeChange,
  colourChange,
} from "../../utils/definePotFunctions";

class NewPotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      potDefinition: {
        name: "",
        colour: this.generateRandomColour(),
        type: "",
        index: this.props.pots.customPots.length + 1,
      },
    };
  }

  generateRandomColour = () => {
    //the way we generate hex codes can sometimes be 5 characters long (not 6)
    //and the way we validate api requests demands it must always be 6
    let randomColour = "#" + Math.floor(Math.random() * 16777215).toString(16);
    if (randomColour.length === 5) {
      randomColour.concat("0");
    }
    return randomColour;
  };

  onSubmit = async (e) => {
    this.setState({ disabledSubmitButton: true });
    e.preventDefault();
    await this.props.saveNewPot(this.state.potDefinition);
    if (!Object.keys(this.props.errors).length) {
      this.props.hideModal();
    }
    return this.setState({ disabledSubmitButton: false });
  };

  render() {
    return (
      <div className="u-p--32">
        <h2 className="u-pb--16 u-mb--24 u-bb--light-grey u-main-font--medium u-color-white">
          Create new pot
        </h2>
        <form
          noValidate
          onSubmit={this.onSubmit}
          className="u-flex u-flex-column"
        >
          <NameInput
            nameChange={(e) => nameChange(e, this)}
            name={this.state.potDefinition.name}
          />

          <TypeInput typeChange={(e) => typeChange(e, this)} />

          <ColourInput
            colourChange={(e) => colourChange(e, this)}
            colour={this.state.potDefinition.colour}
          />

          <div className="u-flex u-align-center u-mt-16--mobile u-mt--32">
            <button
              className="o-button--wide u-m0auto u-main-font--small"
              type="submit"
              disabled={this.state.disabledSubmitButton}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    );
  }
}

// Typescript will warn if any props our outside the defined validation
NewPotModal.propTypes = {
  pots: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.shape(AuthPropTypes).isRequired,
  hideModal: PropTypes.func,
  saveNewPot: PropTypes.func,
};

const mapStateToProps = (state) => ({
  pots: state.pots,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { saveNewPot, hideModal })(NewPotModal);
