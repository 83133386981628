import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { fetchPots } from "../actions/potActions.js";
import {
  fetchDailyPotHours,
  fetchDailyPotTypeHours,
  fetchThisWeeksPotsTotalHours,
  fetchThisWeeksPotTypeTotalHours,
  fetchLastWeeksPotsTotalHours,
  fetchLastWeeksPotTypeTotalHours,
  fetchThisMonthsPotsTotalHours,
  fetchThisMonthsPotTypeTotalHours,
  fetchLastMonthsPotsTotalHours,
  fetchLastMonthsPotTypeTotalHours,
  fetchAllTimePotsTotalHours,
  fetchAllTimePotTypesTotalHours,
} from "../actions/analyticalActions.js";
import {
  getStatusOfTargets,
  // getStreakOfTargets,
} from "../actions/targetActions";

import Navbar from "../components/layout/Navbar.js";
import Highlights from "../components/layout/AnalyticalComponents/Highlights.js";
import LifeBalancePieCharts from "../components/layout/AnalyticalComponents/LifeBalancePieCharts.js";
import PotsProgressChart from "../components/layout/AnalyticalComponents/PotsProgressChart.js";
import WinnersAndLosersChart from "../components/layout/AnalyticalComponents/WinnersAndLosersChart.js";
import TargetProgress from "../components/layout/AnalyticalComponents/TargetProgress";

import { AuthPropTypes } from "../utils/propTypes.js";
import { track } from "../utils/mixpanel.js";
import BackgroundBlobTop from "../components/objects/BackgroundBlobTop.js";
import ComparisonsChart from "../components/layout/AnalyticalComponents/ComparisonsChart.js";

class Analytics extends Component {
  state = {
    loading: true,
    data: null,
    visibleLines: [],
  };

  componentDidMount = async () => {
    track("open-analytics");

    //get window screen width and height and set it for the components
    this.setScreenOrientation();
    // track if the user rotates
    window.addEventListener("resize", this.setScreenOrientation);

    await Promise.all([
      //get all the pots a user has
      this.props.fetchPots(),
      //get all the pots hours on a daily basis using the API
      this.props.fetchDailyPotHours(),

      //get all the pots types hours on a daily basis using the API
      this.props.fetchDailyPotTypeHours(),

      //used in pie charts, winner and loser charts, and highlights
      this.props.getStatusOfTargets(),
      // this.props.getStreakOfTargets(),
      this.props.fetchThisWeeksPotsTotalHours(),
      this.props.fetchThisWeeksPotTypeTotalHours(),
      this.props.fetchThisMonthsPotsTotalHours(),
      this.props.fetchThisMonthsPotTypeTotalHours(),
      this.props.fetchAllTimePotsTotalHours(),
      this.props.fetchAllTimePotTypesTotalHours(),
      this.props.fetchLastMonthsPotsTotalHours(),
      this.props.fetchLastMonthsPotTypeTotalHours(),
      this.props.fetchLastWeeksPotsTotalHours(),
      this.props.fetchLastWeeksPotTypeTotalHours(),
    ]);

    this.setState({
      loading: false,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleScroll);
  }

  setScreenOrientation = () => {
    const newWindowWidth = window.innerWidth;
    const newWindowHeight = window.innerHeight;
    const mobileBreakPoint = 600;
    const desktopWrapWidth = 1200;
    const minimumPieSize = 350;
    let pieChartSize =
      0.2 * newWindowWidth > minimumPieSize
        ? minimumPieSize
        : 0.2 * newWindowWidth;

    // if viewing on mobile make the pie charts stacked and wide
    if (newWindowWidth < mobileBreakPoint) {
      pieChartSize = 0.75 * newWindowWidth;
    }

    //set line charts size, making it larger if horizontal view on mobile
    let lineChartWidth =
      0.9 * newWindowWidth > desktopWrapWidth
        ? desktopWrapWidth
        : 0.9 * newWindowWidth;
    let lineChartHeight =
      0.4 * newWindowHeight > 200 ? 0.4 * newWindowHeight : 200;
    this.setState({
      windowWidth: newWindowWidth,
      windowHeight: newWindowHeight,
      pieChartSize: pieChartSize,
      lineChartWidth: lineChartWidth,
      lineChartHeight: lineChartHeight,
    });
  };

  render() {
    // define variables for target data to be extracted into
    let dailyTargets = [],
      weeklyTargets = [],
      monthlyTargets = [];
    if (this.props.targets && this.props.targets.targetStatus) {
      dailyTargets = this.props.targets.targetStatus.filter(
        (target) => target.period === "day"
      );
      weeklyTargets = this.props.targets.targetStatus.filter(
        (target) => target.period === "week"
      );
      monthlyTargets = this.props.targets.targetStatus.filter(
        (target) => target.period === "month"
      );
    }

    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-overflow-scroll u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-m0auto u-vh u-absolute u-top--0 u-left--0 u-right--0">
          <Navbar />
          <div className="u-mt--32 u-mt--16--mobile u-wrap">
            <div>
              <Highlights />
              {this.props.targets ? (
                dailyTargets.length > 0 && !this.state.loading ? (
                  <TargetProgress
                    targets={dailyTargets}
                    type={"day"}
                    // streaks={dayStreaks}
                  />
                ) : this.state.loading ? (
                  <div className="o-analytics-key-highlights">
                    <div className="u-main-font--small u-color-white">
                      Loading daily targets...
                    </div>
                  </div>
                ) : null
              ) : null}
              {this.props.targets ? (
                weeklyTargets.length > 0 && !this.state.loading ? (
                  <TargetProgress
                    targets={weeklyTargets}
                    type={"week"}
                    // streaks={weekStreaks}
                  />
                ) : this.state.loading ? (
                  <div className="o-analytics-key-highlights">
                    <div className="u-main-font--small u-color-white">
                      Loading weekly targets...
                    </div>
                  </div>
                ) : null
              ) : null}
              {this.props.targets ? (
                monthlyTargets.length ? (
                  <TargetProgress
                    targets={monthlyTargets}
                    type={"month"}
                    // streaks={monthStreaks}
                  />
                ) : this.state.loading ? (
                  <div className="o-analytics-key-highlights">
                    <div className="u-main-font--small u-color-white">
                      Loading monthly targets...
                    </div>
                  </div>
                ) : null
              ) : null}

              <LifeBalancePieCharts pieChartSize={this.state.pieChartSize} />

              {
                // on mobile dont render bar chart
                this.state.windowWidth < 500 ? null : (
                  <WinnersAndLosersChart
                    width={this.state.lineChartWidth}
                    height={this.state.lineChartHeight}
                  />
                )
              }
              {
                // on mobile dont render line chart
                this.state.windowWidth < 500 ? null : (
                  <div>
                    {!this.props.analytics.dailyPotHours ||
                    !this.props.analytics.dailyPotTypeHours ||
                    !this.props.pots.customPots ? (
                      <div className="o-analytics-key-highlights">
                        <div className="u-main-font--small u-color-white">
                          Loading...
                        </div>
                      </div>
                    ) : (
                      <PotsProgressChart
                        width={this.state.lineChartWidth}
                        height={this.state.lineChartHeight}
                      />
                    )}
                  </div>
                )
              }
              {
                // on mobile dont render line chart
                this.state.windowWidth < 500 ? null : (
                  <div>
                    {!this.props.analytics.dailyPotHours ||
                    !this.props.analytics.dailyPotTypeHours ||
                    !this.props.pots.customPots ? (
                      <div className="o-analytics-key-highlights">
                        <div className="u-main-font--small u-color-white">
                          Loading...
                        </div>
                      </div>
                    ) : (
                      <ComparisonsChart
                        width={this.state.lineChartWidth}
                        height={this.state.lineChartHeight}
                      />
                    )}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Analytics.propTypes = {
  pots: PropTypes.object.isRequired,
  auth: PropTypes.shape(AuthPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  auth: state.auth,
  pots: state.pots,
  time: state.time,
  targets: state.targets,
});

export default connect(mapStateToProps, {
  fetchPots,
  fetchDailyPotHours,
  fetchDailyPotTypeHours,
  fetchThisWeeksPotsTotalHours,
  fetchThisWeeksPotTypeTotalHours,
  fetchLastWeeksPotsTotalHours,
  fetchLastWeeksPotTypeTotalHours,
  fetchThisMonthsPotsTotalHours,
  fetchThisMonthsPotTypeTotalHours,
  fetchLastMonthsPotsTotalHours,
  fetchLastMonthsPotTypeTotalHours,
  fetchAllTimePotsTotalHours,
  fetchAllTimePotTypesTotalHours,
  // getStreakOfTargets,
  getStatusOfTargets,
})(Analytics);
