import {
  REQUESTING,
  POTS_FETCHED,
  POT_CREATED,
  DEMO_POTS_FETCHED,
  POT_TARGETS_FETCHED,
  POT_TARGET_CREATED,
} from "../actions/types";

const initialState = {
  customPots: [],
};

export default function potReducers(state = initialState, action) {
  switch (action.type) {
    case POTS_FETCHED:
      return {
        ...state,
        customPots: action.pots,
      };
    case DEMO_POTS_FETCHED:
      return {
        ...state,
        customPots: action.pots,
      };
    case POT_CREATED:
      const transformedPot = {
        name: action.pot.name,
        colour: action.pot.colour,
        type: action.pot.type,
        id: action.pot._id,
        order: state.customPots.length,
      };
      return {
        ...state,
        customPots: [...state.customPots, transformedPot],
      };
    case POT_TARGET_CREATED:
      return {
        ...state,
        potTargets: [...state.potTargets, action.potTargets],
      };
    case POT_TARGETS_FETCHED:
      return {
        ...state,
        potTargets: action.potTargets,
      };
    case REQUESTING:
      return {
        ...state,
        loading: action.bool,
      };
    default:
      return state;
  }
}
