import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { PieChart, Pie, Cell } from "recharts";
import { colours, potTypes } from "../../../utils/constants";

class TwoLevelPieChart extends Component {
  state = {};

  colourPotTypesInternalSegments = (potTypesHours) => {
    //for every pot type, find the pot types colour defined in constants and set its fill to be that
    return potTypesHours.map((entry, index) => {
      const potTypeForEntry = potTypes.find((potType) => {
        return potType.name === entry.name;
      });

      if (potTypeForEntry) {
        return <Cell fill={potTypeForEntry.colour} key={index} />;
      }

      return <Cell fill={colours.grey.hex} key={index} />;
    });
  };

  renderCustomizedLabel = ({
    name,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent > 0.01) {
      return (
        <text
          x={x}
          y={y}
          stroke="transparent"
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {name} {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
  };

  render() {
    const centerPoint = this.props.pieChartSize / 2; // center of allowed space

    return (
      <div>
        <PieChart
          width={this.props.pieChartSize}
          height={this.props.pieChartSize}
        >
          <Pie
            data={this.props.innerData}
            dataKey="totalDuration"
            cx={centerPoint}
            cy={centerPoint}
            label={this.renderCustomizedLabel}
          >
            {/* pot types dont have colours on the pot definition so need to add some */}
            {this.colourPotTypesInternalSegments(this.props.innerData)}
          </Pie>
        </PieChart>
      </div>
    );
  }
}

TwoLevelPieChart.propTypes = {
  pieChartSize: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  innerData: PropTypes.array,
  outerData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  auth: state.auth,
  pots: state.pots,
  time: state.time,
});

export default connect(mapStateToProps, {})(TwoLevelPieChart);
