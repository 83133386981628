import React, { Component } from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import store from "./store";

import { storeUserLoginDetails } from "./actions/authActions";
import { saveDeferredPWAPrompt } from "./actions/pwaActions";

import ErrorModal from "./components/objects/ErrorModal";
import Modal from "./components/objects/Modal";
import PrivateRoute from "./components/objects/PrivateRoute";

import AskToResetPassword from "./pages/auth/AskToResetPassword";
import About from "./pages/About";
import Analytics from "./pages/Analytics";
import Diary from "./pages/Diary";

import Landing from "./pages/Landing";
import Login from "./pages/auth/Login";
import Notes from "./pages/Notes";
import NotFound404 from "./pages/NotFound404";
import Onboarding from "./pages/Onboarding";
import Settings from "./pages/Settings";
import Track from "./pages/Track";
import Register from "./pages/auth/Register";
import Reorder from "./pages/Reorder";
import ResetPassword from "./pages/auth/ResetPassword";

import HVCO from "./pages/HVCO/HVCO";
import FreeConsultation from "./pages/HVCO/FreeConsultation";

import BlogHome from "./pages/blog/BlogHome";
import YouShould from "./pages/blog/articles/youShould";
import WhyItWasSoHard from "./pages/blog/articles/whyItWasSoHard";
import HowToRecord from "./pages/blog/articles/howToRecord";

import { ModalPropTypes, AuthPropTypes } from "./utils/propTypes";
import { init as initialiseMixpanelTracking } from "./utils/mixpanel";
import routes from "./utils/routes";

initialiseMixpanelTracking();
class App extends Component {
  state = {
    pending: true,
  };

  componentDidMount() {
    if (localStorage.jwtToken) {
      storeUserLoginDetails(localStorage.jwtToken, store.dispatch);
    }

    this.setState({ pending: false });

    // Catch PWA install event and save it to use when the user clicks
    window.addEventListener("beforeinstallprompt", this.pwaPrompt);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeinstallprompt", this.pwaPrompt);
  }

  pwaPrompt(e) {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // TODO: figure out if we can distinguish between not supported and already installed
    // Stash the event so it can be triggered later.
    const deferredPrompt = e;
    store.dispatch(saveDeferredPWAPrompt(deferredPrompt));
  }

  render() {
    const {
      auth: { isAuthenticated },
      modal: { status },
    } = this.props;

    if (!this.state.pending) {
      return (
        <div className={status ? "u-overflow--hidden" : ""}>
          <Switch>
            <Route
              exact
              path={routes.home}
              component={isAuthenticated ? Track : Landing}
            />
            <Route
              exact
              path={routes.analytics}
              component={isAuthenticated ? Analytics : Login}
            />
            <Route
              exact
              path={routes.diary}
              component={isAuthenticated ? Diary : Login}
            />
            <Route
              exact
              path={routes.notes}
              component={isAuthenticated ? Notes : Login}
            />
            <Route
              exact
              path={routes.settings}
              component={isAuthenticated ? Settings : Login}
            />
            <Route
              exact
              path={routes.reorder}
              component={isAuthenticated ? Reorder : Login}
            />
            <Route
              exact
              path={routes.onboarding}
              component={isAuthenticated ? Onboarding : Login}
            />

            <Route exact path={routes.about} component={About} />
            <Route
              exact
              path={routes.login}
              component={isAuthenticated ? Track : Login}
            />
            <Route
              exact
              path={routes.register}
              component={isAuthenticated ? Track : Register}
            />
            <Route
              exact
              path={routes.requestResetPassword}
              component={isAuthenticated ? Track : AskToResetPassword}
            />
            <Route
              exact
              path={routes.resetPassword}
              component={isAuthenticated ? Track : ResetPassword}
            />
            {/* Blogs */}
            <Route exact path={routes.blog.home} component={BlogHome} />
            <Route exact path={routes.blog.youShould} component={YouShould} />
            <Route
              exact
              path={routes.blog.whyItWasSoHard}
              component={WhyItWasSoHard}
            />
            <Route
              exact
              path={routes.blog.howToRecord}
              component={HowToRecord}
            />

            {/* Template HVCO can be switched off and should be on prod */}
            {process.env.REACT_APP_TEMPLATE_HVCO_VISIBLE === "true" ? (
              <Route exact path={routes.hvco} component={HVCO} />
            ) : null}
            {process.env.REACT_APP_TEMPLATE_HVCO_VISIBLE === "true" ? (
              <Route
                exact
                path={routes.consultation}
                component={FreeConsultation}
              />
            ) : null}

            <Route exact path={routes.notFound} component={NotFound404} />
            {/* send unknown routes to notFound */}
            <Redirect to={routes.notFound} />
          </Switch>
          <ErrorModal />
          <Modal />
        </div>
      );
    }
    return null;
  }
}
App.propTypes = {
  auth: PropTypes.shape(AuthPropTypes).isRequired,
  modal: PropTypes.shape(ModalPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  modal: state.modal,
});

export default connect(mapStateToProps, {})(withRouter(App));
