import axios from "axios";

import { demoPotsTargets } from "../utils/constants";
import { requestError } from "./errorActions";
import {
  POT_TARGETS_FETCHED,
  POT_TARGET_CREATED,
  POT_TARGET_EDITED,
  STATUS_OF_TARGETS,
} from "./types";

export const savePotTarget = (targetDetails) => (dispatch) => {
  const fieldsAllowingChange = {
    targetNumber: targetDetails.targetNumber,
    targetPeriod: targetDetails.targetPeriod,
    targetAdjective: targetDetails.targetAdjective,
  };
  return axios
    .post(
      `/api/v2/users/pots/${targetDetails.potId}/target`,
      fieldsAllowingChange
    )
    .then((res) => {
      dispatch(savedPotTarget(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const savedPotTarget = (data) => {
  return {
    type: POT_TARGET_CREATED,
    potTargets: data,
  };
};

export const fetchPotTargets = () => (dispatch) => {
  return axios
    .get(`/api/v2/users/pots/targets`)
    .then((res) => {
      dispatch(fetchedPotTargets(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const fetchDemoPotTargets = () => (dispatch) => {
  dispatch(fetchedPotTargets(demoPotsTargets));
};

export const fetchedPotTargets = (data) => {
  return {
    type: POT_TARGETS_FETCHED,
    potTargets: data,
  };
};

export const editPotTarget = (targetDetails) => (dispatch) => {
  const fieldsAllowingChange = {
    targetAdjective: targetDetails.targetAdjective,
    targetNumber: targetDetails.targetNumber,
    targetPeriod: targetDetails.targetPeriod,
  };
  return axios
    .patch(
      `/api/v2/users/pots/${targetDetails.potId}/target`,
      fieldsAllowingChange
    )
    .then(async (res) => {
      dispatch(changedPotTargetDetails(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const changedPotTargetDetails = (data) => {
  return {
    type: POT_TARGET_EDITED,
    targetChanges: data,
  };
};

export const deletePotTarget = (targetId) => (dispatch) => {
  return axios
    .delete(`/api/v2/users/pots/target/${targetId}`)
    .then(async (res) => {
      dispatch(fetchPotTargets());
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const getStatusOfTargets = () => (dispatch) => {
  return axios
    .get(`/api/v2/users/pots/targets/status`)
    .then(async (res) => {
      dispatch(fetchedStatusOfTargets(res.data));
    })
    .catch((err) => {
      if (err.response.data.error === "User has no targets") {
        return dispatch(fetchedStatusOfTargets([]));
      }
      dispatch(requestError(err));
    });
};

export const fetchedStatusOfTargets = (data) => {
  return {
    type: STATUS_OF_TARGETS,
    status: data,
  };
};
