import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { calculatePercentageOfYearLeft } from "../../../utils/dateFunctions";
import { AnalyticsPropTypes } from "../../../utils/propTypes";

class Highlights extends Component {
  getPotTypeFromName(name) {
    return this.props.analytics.thisWeeksPotTypesTotalHours
      ? this.props.analytics.thisWeeksPotTypesTotalHours.find(
          (pot) => pot.name.toLowerCase() === name.toLowerCase()
        )
      : null;
  }

  getPotFromName(name) {
    return this.props.analytics.thisWeeksPotsTotalHours
      ? this.props.analytics.thisWeeksPotsTotalHours.find(
          (pot) => pot.name.toLowerCase() === name.toLowerCase()
        )
      : null;
  }

  renderWorkHighlight = () => {
    // if the user has not worked in the last 7 days do not render
    if (!this.getPotTypeFromName("Work")) {
      return null;
    }
    return (
      <div className="u-flex o-analytics-key-highlights">
        <div className="u-main-font--small u-color-white">
          Last 7 days work hours
        </div>
        <div>
          <div className="u-main-font--large u-color-white u-text-right">
            {this.getPotTypeFromName("Work").totalDuration}
          </div>
          <div className="u-main-font--small u-color-white u-text-right">
            hours
          </div>
        </div>
      </div>
    );
  };

  calculateAverageSleep = () => {
    const totalSleep = this.getPotFromName("Sleep").totalDuration;
    return Number.parseFloat(totalSleep / 7).toFixed(1) || 0;
  };

  renderSleepHighlight = () => {
    // Only show the sleep highlight if there is data
    if (!this.getPotFromName("Sleep")) {
      return null;
    }
    return (
      <div className="u-flex o-analytics-key-highlights">
        <div className="o-key-highlights__title u-main-font--small u-color-white">
          Last 7 days avg. sleep
        </div>
        <div>
          <div className="u-main-font--large u-color-white u-text-right">
            {this.calculateAverageSleep()}
          </div>
          <div className="u-main-font--small u-color-white u-text-right">
            hours
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (
      !this.props.analytics.thisWeeksPotTypesTotalHours ||
      !this.props.analytics.thisWeeksPotsTotalHours ||
      !this.props.analytics.allTimePotTypesTotalHours
    ) {
      return (
        <div className="u-flex o-analytics-key-highlights">
          <div className="u-main-font--small u-color-white">
            Loading highlights
          </div>
        </div>
      );
    }
    return (
      <div className="u-flex u-justify-between u-grid--2--mobile">
        {this.renderWorkHighlight()}
        {this.renderSleepHighlight()}
        <div className="u-flex o-analytics-key-highlights">
          <div className="o-key-highlights__title u-main-font--small u-color-white">
            Year Remaining
          </div>
          <div>
            <div className="u-main-font--large u-color-white u-text-right">
              {calculatePercentageOfYearLeft()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Highlights.propTypes = {
  analytics: PropTypes.shape(AnalyticsPropTypes).isRequired,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
});

export default connect(mapStateToProps, {})(Highlights);
