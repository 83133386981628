import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  BarChart,
  Bar,
  Tooltip,
  Cell,
  YAxis,
  LabelList,
  ReferenceLine,
} from "recharts";

import { getColourOfPotType } from "../../../utils/potFunctions.js";

class WinnersAndLosersChart extends Component {
  state = {
    dataType: "count", // can also be "percentage"
    potType: "potTypes", // can also be "pots"
    periodType: "weeks", // can also be "months"
    processed: false,
  };

  componentDidUpdate = () => {
    // if data is already
    if (this.state.processed) {
      return;
    }

    //if data isn't retrieved yet
    if (
      this.props.analytics.lastWeeksPotTypesTotalHours &&
      this.props.analytics.thisWeeksPotTypesTotalHours &&
      this.props.analytics.lastWeeksPotsTotalHours &&
      this.props.analytics.thisWeeksPotsTotalHours &&
      this.props.analytics.lastMonthsPotTypesTotalHours &&
      this.props.analytics.lastMonthsPotsTotalHours &&
      this.props.analytics.thisMonthsPotTypesTotalHours &&
      this.props.analytics.thisMonthsPotsTotalHours
    ) {
      this.processData();
      this.setState({ processed: true });
    }
  };

  processData = () => {
    const weeksPotsData = this.createPotsDataset(
      this.props.analytics.thisWeeksPotsTotalHours,
      this.props.analytics.lastWeeksPotsTotalHours
    );
    const weeksPotTypesData = this.createPotTypesDataset(
      this.props.analytics.thisWeeksPotTypesTotalHours,
      this.props.analytics.lastWeeksPotTypesTotalHours
    );
    const monthsPotsData = this.createPotsDataset(
      this.props.analytics.thisMonthsPotsTotalHours,
      this.props.analytics.lastMonthsPotsTotalHours
    );
    const monthsPotTypesData = this.createPotTypesDataset(
      this.props.analytics.thisMonthsPotTypesTotalHours,
      this.props.analytics.lastMonthsPotTypesTotalHours
    );

    this.setState({
      weeksPotsData,
      weeksPotTypesData,
      monthsPotsData,
      monthsPotTypesData,
      data: weeksPotTypesData,
    });
  };

  createPotsDataset = (recent, previous) => {
    let dataset = [];

    // for all recent pots create data
    recent.forEach((potObject) => {
      const name = potObject.name;
      const color = potObject.color;
      const recentCount = potObject.totalDuration;
      let previousCount = "";
      let percentageChange = "";

      const index = previous.findIndex(
        (previousPotObject) => previousPotObject.name === potObject.name
      );

      if (index < 0) {
        // if there is no data for last week
        previousCount = 0;
        percentageChange = 100;
      } else {
        previousCount = previous[index].totalDuration;
        percentageChange = parseFloat(
          (100 * potObject.totalDuration) / previous[index].totalDuration - 100
        );
      }

      dataset.push({
        name,
        recentCount,
        previousCount,
        percentageChange,
        color,
      });
    });

    //for all pots that are in previous week
    previous.forEach((potObject) => {
      const index = recent.findIndex(
        (recentPotObject) => recentPotObject.name === potObject.name
      );

      //find the ones that are only in the previous and add those to the data set too
      if (index < 0) {
        const name = potObject.name;
        const color = potObject.color;
        const previousCount = potObject.totalDuration;

        const recentCount = 0;
        const percentageChange = -100;

        // add only these into the dataset
        dataset.push({
          name,
          recentCount,
          previousCount,
          percentageChange,
          color,
        });
      }
    });

    //sort to show increasing
    dataset.sort((a, b) => b.percentageChange - a.percentageChange);

    return dataset;
  };

  createPotTypesDataset = (recent, previous) => {
    let dataset = [];

    // for all recent pots create data
    recent.forEach((potObject) => {
      const name = potObject.name;
      const color = getColourOfPotType(potObject.name);
      const recentCount = potObject.totalDuration;
      let previousCount = "";
      let percentageChange = "";

      const index = previous.findIndex(
        (previousPotObject) => previousPotObject.name === potObject.name
      );

      if (index < 0) {
        // if it there is no data for last week
        previousCount = 0;
        percentageChange = 100;
      } else {
        previousCount = previous[index].totalDuration;
        percentageChange = parseFloat(
          (100 * potObject.totalDuration) / previous[index].totalDuration - 100
        );
      }

      dataset.push({
        name,
        recentCount,
        previousCount,
        percentageChange,
        color,
      });
    });

    //for all pot types that are only in the previous week
    previous.forEach((potObject) => {
      const index = recent.findIndex(
        (recentPotObject) => recentPotObject.name === potObject.name
      );

      if (index < 0) {
        // if it there is no data for last week
        const name = potObject.name;
        const color = getColourOfPotType(potObject.name);
        const previousCount = potObject.totalDuration;
        const recentCount = 0;
        const percentageChange = -100;

        // add only these into the dataset
        dataset.push({
          name,
          recentCount,
          previousCount,
          percentageChange,
          color,
        });
      }
    });

    //sort to show increasing
    dataset.sort((a, b) => b.percentageChange - a.percentageChange);

    return dataset;
  };

  createBars = () => {
    if (this.state.dataType === "count") {
      //MUST RETURN AN ARRAY - recharts can't understand unless they fall directly under <BarChart/>
      return [
        <Bar dataKey="recentCount" key={1}>
          {
            // Colour the pots segments
            this.state.data.map((entry, index) => {
              return <Cell fill={entry.color} key={index} />;
            })
          }
          <LabelList dataKey="name" content={this.customBarChartLabel} />
        </Bar>,
        <Bar dataKey="previousCount" key={2}>
          {
            // Colour the pots segments that are the same colour but slightly transparent
            this.state.data.map((entry, index) => (
              <Cell fill={entry.color + "99"} key={index} />
            ))
          }
        </Bar>,
      ];
    } else if (this.state.dataType === "percentage") {
      return [
        <Bar dataKey="percentageChange" key={1}>
          {
            // Colour the pots segments
            this.state.data.map((entry, index) => {
              return <Cell fill={entry.color} key={index} />;
            })
          }
          <LabelList dataKey="name" content={this.customBarChartLabel} />
        </Bar>,
      ];
    }
  };

  customBarChartLabel = (props) => {
    const { x, y, width, height, value } = props;

    const coord = [
      x + width / 2,
      this.state.dataType === "percentage" ? y + height : (y + height) / 2,
    ];
    return (
      <g>
        <text
          x={coord[0]}
          y={coord[1]}
          textAnchor="middle"
          className="o-vertical-label u-sub-font--vsmall u-bold"
        >
          {value}
        </text>
      </g>
    );
  };

  getCorrectLabel = (name) => {
    // if the data is the most recent set of data (this week or this month - not last week or last month)
    if (name === "recentCount") {
      if (this.state.periodType === "weeks") {
        return "This week";
      } else {
        return "This month";
      }
    } else {
      if (this.state.periodType === "weeks") {
        return "Last week";
      } else {
        return "Last month";
      }
    }
  };

  customTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div style={{ color: "white", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <p>{payload[0].payload.name}</p>
          {this.state.dataType === "count" ? (
            <div>
              {payload.map((element) => {
                const label = this.getCorrectLabel(element.name);
                return (
                  <p className="label">
                    <span>{`${label} : ${element.value} hours`}</span>
                  </p>
                );
              })}
            </div>
          ) : (
            <span>{`${payload[0].payload.percentageChange.toFixed(1)}% `}</span>
          )}
        </div>
      );
    }
  };

  toggleDataType = () => {
    // Set state and then read state to pick the right dataset
    this.setState(
      {
        dataType: this.state.dataType === "count" ? "percentage" : "count",
      },
      () => {
        this.setCorrectDataset();
      }
    );
  };

  togglePotType = () => {
    // Set state and then read state to pick the right dataset
    this.setState(
      {
        potType: this.state.potType === "pots" ? "potTypes" : "pots",
      },
      () => {
        this.setCorrectDataset();
      }
    );
  };

  togglePeriodType = () => {
    // Set state and then read state to pick the right dataset
    this.setState(
      {
        periodType: this.state.periodType === "weeks" ? "months" : "weeks",
      },
      () => {
        this.setCorrectDataset();
      }
    );
  };

  setCorrectDataset = () => {
    this.setState({ data: this.retrieveCorrectDatasets() });
  };

  retrieveCorrectDatasets = () => {
    let correctDataset;

    if (this.state.potType === "pots") {
      if (this.state.periodType === "weeks") {
        correctDataset = this.state.weeksPotsData;
      } else if (this.state.periodType === "months") {
        correctDataset = this.state.monthsPotsData;
      }
    } else if (this.state.potType === "potTypes") {
      if (this.state.periodType === "weeks") {
        correctDataset = this.state.weeksPotTypesData;
      } else if (this.state.periodType === "months") {
        correctDataset = this.state.monthsPotTypesData;
      }
    }

    return correctDataset;
  };

  render() {
    // if data isn't processed
    if (!this.state.processed) {
      return (
        <div className="u-flex o-analytics-key-highlights">
          <div className="u-main-font--small u-color-white">
            Loading last 7 days vs 7 days before
          </div>
        </div>
      );
    }

    return (
      <div className="u-flex o-analytics-key-highlights">
        <div className="u-flex">
          <div className="u-main-font--large u-color-white u-mv--4">
            {this.state.periodType === "weeks"
              ? "Last 7 days vs 7 days before"
              : "Last month vs this month"}
          </div>

          <div className="u-right--0 u-mlauto">
            <button
              className="o-button--green u-m--4"
              onClick={this.togglePeriodType}
            >
              <p className="u-sub-font--vsmall">
                {this.state.periodType === "weeks"
                  ? "Show months"
                  : "Show weeks"}
              </p>
            </button>
            <button
              className="o-button--green u-m--4"
              onClick={this.togglePotType}
            >
              <p className="u-sub-font--vsmall">
                {this.state.potType === "potTypes"
                  ? "Show pots"
                  : "Show pot types"}
              </p>
            </button>
            <button
              className="o-button--green u-m--4"
              onClick={this.toggleDataType}
            >
              <p className="u-sub-font--vsmall">
                {this.state.dataType === "percentage"
                  ? "Show hours count"
                  : "Show percentage"}
              </p>
            </button>
          </div>
        </div>
        <BarChart
          width={this.props.width}
          height={this.props.height}
          data={this.state.data}
        >
          <YAxis />
          <Tooltip content={this.customTooltip} />
          {this.createBars()}
          <ReferenceLine y={0} stroke="white" />
        </BarChart>
      </div>
    );
  }
}

WinnersAndLosersChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  auth: state.auth,
  pots: state.pots,
  time: state.time,
});

export default connect(mapStateToProps)(WinnersAndLosersChart);
