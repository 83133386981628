import axios from "axios";

import { requestError } from "./errorActions";
import { getDBDateFormat } from "../utils/dateFunctions";

import {
  FETCH_PAGE_OF_NOTES,
  NOTE_SAVED,
  NOTES_FETCHED,
  NOTE_EDITED,
  NOTES_DELETED,
} from "./types";

export const saveNewNote = (noteData) => (dispatch) => {
  return axios
    .post(`/api/v2/users/notes`, noteData)
    .then((res) => {
      dispatch(savedNewNote(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const savedNewNote = (data) => {
  return {
    type: NOTE_SAVED,
    notes: data,
  };
};

export const fetchNotes = (date) => (dispatch) => {
  const apiUrl = date
    ? `/api/v2/users/notes?date=${getDBDateFormat(date)}`
    : `/api/v2/users/notes`;

  // get all notes for specific user
  return axios
    .get(apiUrl)
    .then((res) => {
      dispatch(fetchedNotes(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const fetchedNotes = (data) => {
  return {
    type: NOTES_FETCHED,
    notes: data,
  };
};

export const fetchPageOfNotes = (pageNumber) => (dispatch) => {
  // Get 20 note entries for page number pageNumber
  return axios
    .get(`/api/v2/users/notes?page=${pageNumber}&numberOfDays=20`)
    .then((response) => {
      if (pageNumber === 1) {
        dispatch(fetchedNotes(response.data));
      } else {
        dispatch(fetchedPageOfNotes(response.data));
      }
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const fetchedPageOfNotes = (data) => {
  return {
    type: FETCH_PAGE_OF_NOTES,
    notes: data,
    endReached: !data.length,
  };
};

export const editNote = (noteData) => (dispatch) => {
  const { dateDescribed, note } = noteData;

  return axios
    .patch(`/api/v2/users/notes/${dateDescribed}`, {
      note,
    })
    .then((res) => {
      dispatch(editedNote(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const editedNote = (data) => {
  return {
    type: NOTE_EDITED,
    notes: data,
  };
};

export const deleteNote = (dateDescribed) => (dispatch) => {
  return axios
    .delete(`/api/v2/users/notes/${dateDescribed}`)
    .then((res) => {
      dispatch(deletedNote(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};

export const deletedNote = (data) => {
  return {
    type: NOTES_DELETED,
    notes: data,
  };
};
