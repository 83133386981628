import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Icon } from "./Icon";
import { track } from "../../utils/mixpanel";

class DatePicker extends Component {
  render() {
    const { className = "" } = this.props;

    return (
      <div
        className={classnames("o-date-picker", {
          [className]: !!className,
        })}
      >
        <button className="o-date-picker__toggle-button">
          <Icon id="calendar" size="m" className="o-icon" />
        </button>
        <input
          className="o-date-picker__input"
          min="1970-01-01"
          type="date"
          onChange={this.props.onDateChange}
          onClick={() => track("click-date-picker")}
          name="day"
          required
        />
      </div>
    );
  }
}

// Typescript will warn if any props our outside the defined validation
DatePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(DatePicker);
