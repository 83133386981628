const routes = {
  about: "/about",
  analytics: "/analytics",
  home: "/",
  login: "/login",
  onboarding: "/onboarding",
  notes: "/notes",
  notFound: "/404",
  register: "/register",
  requestResetPassword: "/request-reset-password",
  reorder: "/reorder",
  resetPassword: "/reset-password",
  diary: "/diary",
  settings: "/settings",
  hvco: "/hvco",
  consultation: "/free-consultation",
  timeTrackingApps: "/time-tracking-apps",
  blog: {
    home: "/blog",
    youShould: "/blog/youShould",
    whyItWasSoHard: "/blog/whyItWasSoHard",
    howToRecord: "/blog/howToRecord",
  },
};

export default routes;
