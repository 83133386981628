import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { editPotDetails, fetchPots } from "../../actions/potActions";
import {
  savePotTarget,
  editPotTarget,
  fetchPotTargets,
  deletePotTarget,
} from "../../actions/targetActions";
import { requestError, resetErrors } from "../../actions/errorActions";
import { hideModal } from "../../actions/modalActions";

import { PotTargetPropTypes } from "../../utils/propTypes.js";

import NameInput from "./PotFields/NameInput.js";
import TypeInput from "./PotFields/TypeInput.js";
import ColourInput from "./PotFields/ColourInput.js";
import TargetInput from "./PotFields/TargetInput.js";
import {
  nameChange,
  typeChange,
  colourChange,
  targetAdjectiveChange,
  targetNumberChange,
  targetPeriodChange,
} from "../../utils/definePotFunctions";

class EditPotModal extends Component {
  state = {
    potDefinition: this.props.potDetails,
    potDefinitionHasChanged: false,
    potTargetDefinition: {
      targetNumber: null,
      targetPeriod: "day",
      targetAdjective: "min",
    },
    potTargetHasChanged: false,
    potTargetIsNew: true,
  };

  componentDidMount = () => {
    if (this.props.targetDetails) {
      this.setState({
        potTargetDefinition: this.props.targetDetails,
        potTargetIsNew: false,
      });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.props.resetErrors();

    if (this.state.potTargetDefinition.length > 0) {
      if (
        this.state.potTargetDefinition.targetAdjective === "min" &&
        this.state.potTargetDefinition.targetNumber.toString() === "0"
      ) {
        return this.props.requestError({
          response: {
            data: {
              targetNumber:
                "You cannot have a target of min of 0 hours as it will always be achieved",
            },
          },
        });
      }
    }
    //update the pots definition if it has changed
    if (this.state.potDefinitionHasChanged) {
      await this.props.editPotDetails(this.state.potDefinition);
    }

    //update the target if it has changed
    if (this.state.potTargetHasChanged) {
      //save the target if it is new
      if (this.state.potTargetIsNew) {
        await this.props.savePotTarget({
          targetNumber: this.state.potTargetDefinition.targetNumber,
          targetPeriod: this.state.potTargetDefinition.targetPeriod,
          targetAdjective: this.state.potTargetDefinition.targetAdjective,
          potId: this.state.potDefinition.id,
        });
      } else {
        await this.props.editPotTarget(this.state.potTargetDefinition);
      }
    }

    if (!Object.keys(this.props.errors).length) {
      //reload pots
      this.props.fetchPots();
      //reload pot targets
      this.props.fetchPotTargets();
      //hide modal
      this.props.hideModal();
    }
  };

  deleteTarget = async (e) => {
    this.setState({
      potTargetIsNew: true, // so that we save if the user adds any after deleting
      potTargetDefinition: {
        targetNumber: "",
        targetPeriod: "day",
        targetAdjective: "min",
      },
    });

    // if it is not new delete it from the db
    if (!this.state.potTargetIsNew) {
      this.props.deletePotTarget(this.state.potTargetDefinition._id);
    }
    this.props.hideModal();
  };

  toggleFinishedStatus = () => {
    //finished status always starts as false as currently you can only edit pots that are not finished
    if (this.state.potDefinition.finishedStatus) {
      //user has clicked twice hence pot should still be visible and hence pot definition hasn't changed
      this.setState({
        potDefinition: { ...this.state.potDefinition, finishedStatus: false },
        potDefinitionHasChanged: false,
      });
    } else {
      //user has marked pot to be hidden
      this.setState({
        potDefinition: { ...this.state.potDefinition, finishedStatus: true },
        //pot definition has changed
        potDefinitionHasChanged: true,
      });
    }
    //reload pots
    this.props.fetchPots();
  };

  render() {
    return (
      <div className="u-p--16--mobile u-p--32">
        <h2 className="u-pb--16 u-mb--16--mobile u-mb--24 u-bb--light-grey u-main-font--medium u-color-white">
          Edit pot details
        </h2>

        <form
          noValidate
          onSubmit={this.onSubmit}
          className="u-flex u-flex-column"
        >
          <NameInput
            nameChange={(e) => nameChange(e, this)}
            name={this.state.potDefinition.name}
          />

          <TypeInput typeChange={(e) => typeChange(e, this)} />

          <ColourInput
            colourChange={(e) => colourChange(e, this)}
            colour={this.state.potDefinition.colour}
          />
          <TargetInput
            targetPeriodChange={(e) => targetPeriodChange(e, this)}
            targetNumberChange={(e) => targetNumberChange(e, this)}
            targetAdjectiveChange={(e) => targetAdjectiveChange(e, this)}
            potTargetHasChanged={this.state.potTargetHasChanged}
            potTargetIsNew={this.state.potTargetIsNew}
            potTargetDefinition={this.state.potTargetDefinition}
            deleteTargetFunction={this.deleteTarget}
          />
          <div className="u-mb--24--mobile u-mb--48">
            <div className="u-flex u-justify-between u-align-center o-hoverover-element">
              <div>
                <label
                  htmlFor="hide-pot"
                  className="u-sub-font--small u-color-white u-w-all"
                >
                  Hide pot?
                </label>
                <div className="u-sub-font--vsmall u-color-grey u-mb--4--mobile u-mt--8">
                  To unhide a pot go to settings and reorder your pots
                </div>
              </div>
              <input
                className="u-m--16 u-scale--150"
                type="checkbox"
                id="hide-pot"
                value={this.state.potDefinition.finishedStatus}
                onChange={this.toggleFinishedStatus}
              ></input>
            </div>
          </div>

          <div className="">
            <button
              className="o-button--red o-button--stretch u-main-font--small"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EditPotModal.propTypes = {
  errors: PropTypes.object.isRequired,
  targetDetails: PropTypes.shape(PotTargetPropTypes),
  editPotDetails: PropTypes.func.isRequired,
  savePotTarget: PropTypes.func.isRequired,
  editPotTarget: PropTypes.func.isRequired,
  fetchPotTargets: PropTypes.func.isRequired,
  fetchPots: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {
  editPotDetails,
  fetchPots,
  hideModal,
  savePotTarget,
  editPotTarget,
  fetchPotTargets,
  deletePotTarget,
  requestError,
  resetErrors,
})(EditPotModal);
