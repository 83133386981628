import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  updateDateSelection,
  fetchDayRecords,
} from "../../actions/timeActions.js";
import { fetchPots } from "../../actions/potActions.js";

import DatePicker from "../objects/DatePicker";

import {
  getNextStartTimeFromLogs,
  getWordyDate,
} from "../../utils/dateFunctions.js";
import { AuthPropTypes, TimePropTypes } from "../../utils/propTypes";

class DayDisplay extends Component {
  onDateChange = async (e) => {
    e.preventDefault();

    if (!e.target.value) return;

    // convert a string into a date object and then adjust so timezones are ignored
    const date = new Date(e.target.value);
    const selectedDate = new Date(
      date.getTime() + 60000 * date.getTimezoneOffset()
    );
    await this.props.updateDateSelection(selectedDate);
    await this.props.fetchDayRecords({
      date: selectedDate,
    });
    await this.props.fetchPots();
  };

  render() {
    const { className = "" } = this.props;

    return (
      <div
        className={classnames(
          "u-flex u-align-center u-flex-1 u-text-unselectable",
          {
            [className]: !!className,
          }
        )}
      >
        <DatePicker onDateChange={this.onDateChange} className="u-mr--24" />

        <div className="u-text-oneline u-color-white u-mr--16 u-sub-font--small">
          {getWordyDate(this.props.time.date)}
        </div>

        <div className="u-color-white u-sub-font--small u-mr--16">
          {getNextStartTimeFromLogs(this.props.time.logs)}
        </div>
      </div>
    );
  }
}

// Typescript will warn if any props our outside the defined validation
DayDisplay.propTypes = {
  time: PropTypes.shape(TimePropTypes),
  auth: PropTypes.shape(AuthPropTypes).isRequired,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  time: state.time,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateDateSelection,
  fetchPots,
  fetchDayRecords,
})(DayDisplay);
