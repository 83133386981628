import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { saveNewPot } from "../actions/potActions.js";

import { Icon } from "../components/objects/Icon";

import OnboardingPotCreation from "../components/layout/OnboardingPotCreation.js";
import NavDots from "../components/layout/NavDots.js";

import { colours } from "../utils/constants";
import { track } from "../utils/mixpanel.js";
import { AuthPropTypes } from "../utils/propTypes";

class Onboarding extends React.Component {
  state = {
    onboardingStepCount: 1,
    maxOnboardingStepCount: 2,
    potDefinition: {
      name: "",
      colour: colours.violet.hex,
      type: "",
    },
    potDefinitionHasChanged: false,
    disabledSubmitButton: false,
  };

  componentDidMount() {
    track("open-onboarding");
  }

  showOnboardingContent = () => {
    switch (this.state.onboardingStepCount) {
      case 1:
        return this.whyTrack();
      case 2:
        return this.makeMorePots();
      // no default
    }
  };

  moveForward = () => {
    if (this.state.onboardingStepCount > 2) {
      return;
    }
    const newCount = this.state.onboardingStepCount + 1;
    this.setState({ onboardingStepCount: newCount });
  };

  moveBackward = () => {
    if (this.state.onboardingStepCount < 2) {
      return;
    }
    const newCount = this.state.onboardingStepCount - 1;
    this.setState({ onboardingStepCount: newCount });
  };

  whyTrack = () => {
    return (
      <div className="u-mauto u-text-center">
        <h1 className="u-main-font--small u-color-white">
          <div className=" u-w-fit u-m0auto">Why track?</div>
        </h1>
        <div className="u-sub-font--large u-color-white">
          <b>Each hour is a choice. </b>
        </div>

        <img
          className="u-mt--48 u-w-all"
          src="./onboarding--pots.png"
          alt="Example of a 'sleep' pot as a simple box you can drop data in"
        />
        <div className="u-sub-font--small u-color-white">
          We tend to make decisions unconsciously. When you track time, you
          can't.
        </div>
        <div className="u-sub-font--medium u-color-white u-mt--120">
          <b>What do you do with your time?</b>
        </div>
      </div>
    );
  };

  makeMorePots = () => {
    return (
      <div className="u-w-all">
        <div
          className="u-mauto u-text-center u-overflow--auto"
          id="onboardingTextContent"
        >
          <h1 className="u-main-font--small u-color-white">
            <div className="u-underline--everyhour-style--inline u-w-fit u-m0auto">
              Defining pots
            </div>
          </h1>
          <div className="u-sub-font--medium u-color-white">
            <b>What do you do?</b>
          </div>

          <div className="u-sub-font--small u-color-white ">
            <b>Scroll and pick</b> 10 (or more) things that you do. I have
            picked 4 for you already.
          </div>
        </div>
        <OnboardingPotCreation />
      </div>
    );
  };

  componentDidMount = () => {
    let detect = {
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        minX: 120, // min X swipe for horizontal swipe
        maxY: 120, // max Y difference for horizontal swipe
      },
      direction = null,
      element = document.getElementById("onboarding-page");

    element.addEventListener(
      "touchstart",
      function (event) {
        let touch = event.touches[0];
        detect.startX = touch.screenX;
        detect.startY = touch.screenY;
      },
      { passive: true }
    );

    element.addEventListener(
      "touchmove",
      function (event) {
        let touch = event.touches[0];
        detect.endX = touch.screenX;
        detect.endY = touch.screenY;
      },
      { passive: true }
    );

    element.addEventListener(
      "touchend",
      (event) => {
        if (
          //check there was in fact a move
          detect.endX &&
          detect.endY &&
          // Horizontal move.
          Math.abs(detect.endX - detect.startX) > detect.minX &&
          Math.abs(detect.endY - detect.startY) < detect.maxY
        ) {
          direction = detect.endX > detect.startX ? "right" : "left";
        }

        if (direction === "left") {
          this.moveForward();
          //reset after each event
          direction = null;
          detect = {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            minX: 120, // min X swipe for horizontal swipe
            maxY: 120, // max Y difference for horizontal swipe
          };
        } else if (direction === "right") {
          this.moveBackward();
          //reset after each event
          direction = null;
          detect = {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            minX: 120, // min X swipe for horizontal swipe
            maxY: 120, // max Y difference for horizontal swipe
          };
        }
      },
      { passive: true }
    );
  };

  render() {
    return (
      <div
        className="u-absolute u-top--0 u-w-all u-blue-background u-flex u-overflow--hidden"
        id="onboarding-page"
      >
        {this.state.onboardingStepCount > 1 ? (
          <button
            className="u-w--15pct u-color-white u-fade-background--left--white u-hide--mobile"
            onClick={this.moveBackward}
          >
            <Icon id="chevron-left" size="xxl" className="o-icon" />
          </button>
        ) : (
          <div className="u-w--15pct u-hide--mobile"></div>
        )}
        <div className="u-wrap u-relative u-mauto u-vh u-justify-center">
          {this.showOnboardingContent()}
          <div className="u-text-center u-p--32 ">
            {
              //if on last page don't show nav dots
              this.state.onboardingStepCount ===
              this.state.maxOnboardingStepCount ? null : (
                <div>
                  <div className="u-color-white u-sub-font--vsmall u-hide--tabletAndDesktop">
                    Swipe to move forward
                  </div>
                </div>
              )
            }
          </div>
        </div>
        {this.state.onboardingStepCount !==
        this.state.maxOnboardingStepCount ? (
          <button
            className="u-w--15pct u-color-white u-fade-background--right--white u-hide--mobile"
            onClick={this.moveForward}
          >
            <Icon id="chevron-right" size="xxl" className="o-icon" />
          </button>
        ) : (
          <div className="u-w--15pct u-hide--mobile"></div>
        )}
      </div>
    );
  }
}

Onboarding.propTypes = {
  auth: PropTypes.shape(AuthPropTypes).isRequired,
  errors: PropTypes.object,
  saveNewPot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pots: state.pots,
  errors: state.errors,
});

export default connect(mapStateToProps, { saveNewPot })(withRouter(Onboarding));
