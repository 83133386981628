import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { requestError } from "../../../actions/errorActions";

interface Props {
  auth: AuthState;
  requestError: (error: Error) => void;
  placeholder: string;
  type: "input" | "textarea";
  title: string;
}

interface State {
  feedbackText: string | null;
  feedbackSubmitted: boolean;
}

class FeedbackInput extends Component<Props, State> {
  state = {
    feedbackText: null,
    feedbackSubmitted: false,
  };

  feedbackTextChanged = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ feedbackText: e.currentTarget.value });
  };

  feedbackTextSubmit = async () => {
    const { id: userId } = this.props.auth.user;
    const { feedbackText: body } = this.state;

    if (!body) {
      this.props.requestError(new Error("Feedback cannot be blank"));
      return;
    }

    try {
      await axios.post(`/api/feedback`, { body, userId });

      this.setState({ feedbackSubmitted: true });
    } catch (e) {
      this.props.requestError(e);
    }
  };

  renderInput = () => {
    return (
      <div className="u-w-all ">
        <p className="u-sub-font--vsmall u-color-white u-p--8">
          {this.props.title}
        </p>

        <input
          placeholder={this.props.placeholder}
          className="u-w-all u-p--8 u-border-radius--small u-b--light-grey u-sub-font--vsmall u-color-white"
          onChange={this.feedbackTextChanged}
        ></input>
        <div className="u-w-all u-flex">
          {this.state.feedbackSubmitted ? (
            <div className="u-sub-font--vsmall u-mlauto u-color-white">
              Sent - thanks!
            </div>
          ) : (
            <button
              onClick={this.feedbackTextSubmit}
              className="o-button--green o-button--wide u-main-font--vsmall  u-mlauto "
            >
              Send
            </button>
          )}
        </div>
      </div>
    );
  };

  renderTextArea = () => {
    return (
      <div className="u-w-all ">
        <p className="u-sub-font--vsmall u-color-white u-p--8">
          {this.props.title}
        </p>
        <textarea
          placeholder={this.props.placeholder}
          className="u-w-all u-p--8 u-border-radius--small u-b--light-grey u-sub-font--vsmall u-color-white"
          onChange={this.feedbackTextChanged}
        ></textarea>
        <div className="u-w-all u-flex">
          {this.state.feedbackSubmitted ? (
            <div className="u-sub-font--vsmall u-mlauto u-color-white">
              Sent - thanks!
            </div>
          ) : (
            <button
              onClick={this.feedbackTextSubmit}
              className="o-button--green o-button--wide u-main-font--vsmall u-mlauto"
            >
              Send
            </button>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="u-w-all u-flex">
        {this.props.type === "input"
          ? this.renderInput()
          : this.renderTextArea()}
      </div>
    );
  }
}

const mapStateToProps = (state: EveryHourState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { requestError })(FeedbackInput);
