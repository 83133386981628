import {
  FETCH_PAGE_OF_NOTES,
  NOTES_FETCHED,
  NOTE_SAVED,
  NOTE_EDITED,
  REQUESTING,
} from "../actions/types";

const initialState = {
  notes: [],
  loading: false,
  endReached: false,
};

export default function notesReducers(state = initialState, action) {
  switch (action.type) {
    case NOTES_FETCHED:
      return {
        ...state,
        notes: action.notes,
      };
    case FETCH_PAGE_OF_NOTES:
      return {
        ...state,
        notes: [...state.notes, ...action.notes],
        endReached: action.endReached,
      };
    case NOTE_SAVED:
      return {
        ...state,
      };
    case NOTE_EDITED:
      return {
        ...state,
      };
    case REQUESTING:
      return {
        ...state,
        loading: action.bool,
      };
    default:
      return state;
  }
}
