import React, { Component } from "react";
import PropTypes from "prop-types";

import routes from "../../utils/routes";

class LinkButton extends Component {
  render() {
    return (
      <div className="link-button">
        <a href={routes.home + this.props.type}>
          <button
            className={
              `u-sub-font--small u-bold u-justify-center u-flex-hold o-button` +
              (this.props.color ? `--` + this.props.color : "")
            }
          >
            {this.props.text}
          </button>
        </a>
      </div>
    );
  }
}

LinkButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};

export default LinkButton;
