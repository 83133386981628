import axios from "axios";

import { requestError } from "./errorActions";

import {
  DAILY_POT_HOURS_FETCHED,
  DAILY_POT_TYPE_HOURS_FETCHED,
  THIS_WEEKS_POTS_TOTAL_HOURS,
  THIS_WEEKS_POT_TYPES_TOTAL_HOURS,
  LAST_WEEKS_POTS_TOTAL_HOURS,
  LAST_WEEKS_POT_TYPES_TOTAL_HOURS,
  THIS_MONTHS_POT_TYPES_TOTAL_HOURS,
  THIS_MONTHS_POTS_TOTAL_HOURS,
  LAST_MONTHS_POT_TYPES_TOTAL_HOURS,
  LAST_MONTHS_POTS_TOTAL_HOURS,
  ALL_TIME_POTS_TOTAL_HOURS,
  ALL_TIME_POT_TYPES_TOTAL_HOURS,
  SPECIFIC_POT_HOURS_COUNT,
} from "./types";
import {
  getYesterdaysISODate,
  get7DaysAgoISODate,
  get8DaysAgoISODate,
  get14DaysAgoISODate,
  getLastMonthsISODate,
  getLastLastMonthsISODate,
} from "../utils/dateFunctions";

export const fetchDailyPotHours = () => (dispatch) => {
  // get all pots for specific user
  return axios
    .get(`/api/v2/users/pots/daily-hours`)
    .then((res) => {
      dispatch(fetchedDailyPotHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedDailyPotHours = (data) => {
  return {
    type: DAILY_POT_HOURS_FETCHED,
    data: data,
  };
};

export const fetchDailyPotTypeHours = () => (dispatch) => {
  // get all pots for specific user
  return axios
    .get(`/api/v2/users/pot-types/daily-hours`)
    .then((res) => {
      dispatch(fetchedDailyPotTypeHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedDailyPotTypeHours = (data) => {
  return {
    type: DAILY_POT_TYPE_HOURS_FETCHED,
    data: data,
  };
};

export const fetchThisWeeksPotTypeTotalHours = () => (dispatch) => {
  // get total hours for pot types for specific user for the most recent 7 days
  const lastWeeksDate = get7DaysAgoISODate();
  const yesterdaysDate = getYesterdaysISODate();

  return axios
    .get(
      `/api/v2/users/pot-types/total-time?startDate=${lastWeeksDate}&endDate=${yesterdaysDate}`
    )
    .then((res) => {
      dispatch(fetchedThisWeeksPotTypeTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedThisWeeksPotTypeTotalHours = (data) => {
  return {
    type: THIS_WEEKS_POT_TYPES_TOTAL_HOURS,
    data: data,
  };
};

export const fetchThisWeeksPotsTotalHours = () => (dispatch) => {
  // get total hours for pot types for specific user for the most recent 7 days
  const lastWeeksDate = get7DaysAgoISODate();
  const yesterdaysDate = getYesterdaysISODate();

  return axios
    .get(
      `/api/v2/users/pots/total-time?startDate=${lastWeeksDate}&endDate=${yesterdaysDate}`
    )
    .then((res) => {
      dispatch(fetchedThisWeeksPotsTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedThisWeeksPotsTotalHours = (data) => {
  return {
    type: THIS_WEEKS_POTS_TOTAL_HOURS,
    data: data,
  };
};

export const fetchLastWeeksPotTypeTotalHours = () => (dispatch) => {
  const startOfLastWeeksDate = get8DaysAgoISODate();
  const endOfLastWeeksDate = get14DaysAgoISODate();

  return axios
    .get(
      `/api/v2/users/pot-types/total-time?startDate=${endOfLastWeeksDate}&endDate=${startOfLastWeeksDate}`
    )
    .then((res) => {
      dispatch(fetchedLastWeeksPotsTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedLastWeeksPotsTotalHours = (data) => {
  return {
    type: LAST_WEEKS_POT_TYPES_TOTAL_HOURS,
    data: data,
  };
};

export const fetchLastWeeksPotsTotalHours = () => (dispatch) => {
  // get total hours for pot types for specific user for the most recent 7 days
  const startOfLastWeeksDate = get8DaysAgoISODate();
  const endOfLastWeeksDate = get14DaysAgoISODate();

  return axios
    .get(
      `/api/v2/users/pots/total-time?startDate=${endOfLastWeeksDate}&endDate=${startOfLastWeeksDate}`
    )
    .then((res) => {
      dispatch(fetchedLastWeeksPotTypeTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedLastWeeksPotTypeTotalHours = (data) => {
  return {
    type: LAST_WEEKS_POTS_TOTAL_HOURS,
    data: data,
  };
};

export const fetchThisMonthsPotTypeTotalHours = () => (dispatch) => {
  // get total hours for pot types for specific user for the most recent month
  const lastMonthsDate = getLastMonthsISODate();

  return axios
    .get(`/api/v2/users/pot-types/total-time?startDate=${lastMonthsDate}`)
    .then((res) => {
      dispatch(fetchedThisMonthsPotTypeTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedThisMonthsPotTypeTotalHours = (data) => {
  return {
    type: THIS_MONTHS_POT_TYPES_TOTAL_HOURS,
    data: data,
  };
};

export const fetchThisMonthsPotsTotalHours = () => (dispatch) => {
  // get total hours for pot types for specific user for the most recent month
  const lastMonthsDate = getLastMonthsISODate();
  return axios
    .get(`/api/v2/users/pots/total-time?startDate=${lastMonthsDate}`)
    .then((res) => {
      dispatch(fetchedThisMonthsPotsTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedThisMonthsPotsTotalHours = (data) => {
  return {
    type: THIS_MONTHS_POTS_TOTAL_HOURS,
    data: data,
  };
};

export const fetchLastMonthsPotTypeTotalHours = () => (dispatch) => {
  const lastLastMonthsDate = getLastLastMonthsISODate();
  const lastMonthsDate = getLastMonthsISODate();

  return axios
    .get(
      `/api/v2/users/pot-types/total-time?startDate=${lastLastMonthsDate}&endDate=${lastMonthsDate}`
    )
    .then((res) => {
      dispatch(fetchedLastMonthsPotTypeTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedLastMonthsPotTypeTotalHours = (data) => {
  return {
    type: LAST_MONTHS_POT_TYPES_TOTAL_HOURS,
    data: data,
  };
};

export const fetchLastMonthsPotsTotalHours = () => (dispatch) => {
  const lastLastMonthsDate = getLastLastMonthsISODate();
  const lastMonthsDate = getLastMonthsISODate();

  return axios
    .get(
      `/api/v2/users/pots/total-time?startDate=${lastLastMonthsDate}&endDate=${lastMonthsDate}`
    )
    .then((res) => {
      dispatch(fetchedLastMonthsPotsTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedLastMonthsPotsTotalHours = (data) => {
  return {
    type: LAST_MONTHS_POTS_TOTAL_HOURS,
    data: data,
  };
};

export const fetchAllTimePotsTotalHours = () => (dispatch) => {
  return axios
    .get(`/api/v2/users/pots/total-time`)
    .then((res) => {
      dispatch(fetchedAllTimePotsTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedAllTimePotsTotalHours = (data) => {
  return {
    type: ALL_TIME_POTS_TOTAL_HOURS,
    data: data,
  };
};

export const fetchAllTimePotTypesTotalHours = () => (dispatch) => {
  return axios
    .get(`/api/v2/users/pot-types/total-time`)
    .then((res) => {
      dispatch(fetchedAllTimePotTypesTotalHours(res.data));
    })
    .catch((err) => {
      dispatch(requestError(err));
    });
};
export const fetchedAllTimePotTypesTotalHours = (data) => {
  return {
    type: ALL_TIME_POT_TYPES_TOTAL_HOURS,
    data: data,
  };
};

export const fetchSpecificPotHoursCount =
  (potId, startDate, endDate) => (dispatch) => {
    return axios
      .get(
        `/api/v2/users/pots/${potId}/total-time?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch(fetchedSpecificPotHoursCount(res.data));
      })
      .catch((err) => {
        dispatch(requestError(err));
      });
  };
export const fetchedSpecificPotHoursCount = (data) => {
  return {
    type: SPECIFIC_POT_HOURS_COUNT,
    data: data,
  };
};

export const fetchDemoTargetsPotHoursCount = (count) => (dispatch) => {
  //this provides the landing page exercise pot some previous history
  // count allows the user on the landing page to see status change
  dispatch(
    fetchedSpecificPotHoursCount({
      potId: "wertghtryhn",
      count,
    })
  );
};
