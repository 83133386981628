import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { resetErrors } from "../../actions/errorActions";

class ErrorModal extends Component {
  state = {
    show: false,
    hiding: false,
  };

  componentDidUpdate = (nextProps) => {
    if (this.props.errors.message && !nextProps.errors.message) {
      this.showModal();
    }
  };

  showModal = () => {
    this.setState(
      {
        show: true,
      },
      () => setTimeout(this.fadeModal, 4000)
    );
  };

  fadeModal = () => {
    this.setState(
      {
        hiding: true,
      },
      () => setTimeout(this.hideModal, 1000)
    );
  };

  hideModal = () => {
    this.setState({
      show: false,
      hiding: false,
    });
    this.props.resetErrors();
  };

  render() {
    return (
      <div
        className={classnames("o-error-modal-container ", {
          "u-hide": !this.state.show,
          "u-fade-out": this.state.hiding,
        })}
      >
        <div className="o-error-modal u-flex u-flex-column">
          <span className="u-color-light-grey u-sub-font--vsmall u-mb--8">
            Ono, something went wrong! Please retry that!
          </span>
          <span className="u-color-white u-sub-font--vsmall">
            {this.props.errors.message}
          </span>
        </div>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { resetErrors })(ErrorModal);
