import { GET_ERRORS, RESET_ERRORS } from "../actions/types";

const initialState = {};

export default function errorReducers(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      const updatedState = { message: action.message, data: action.data };
      return updatedState;

    case RESET_ERRORS:
      return initialState;

    default:
      return state;
  }
}
