import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  findFontAndBackgroundColour,
  reduceHexColor,
} from "../../utils/potFunctions";
import { colours } from "../../utils/constants";

class ProgressBar extends Component {
  state = {
    editingDate: false,
  };

  getColourHexFromPotId(potId) {
    const pot = this.props.pots.customPots.find((pot) => pot.id === potId);

    if (pot) {
      const potColourObject = findFontAndBackgroundColour(pot.colour);
      return potColourObject.hex;
    }

    return colours.grey.hex;
  }

  getPotNameFromPotId(potId) {
    const pot = this.props.pots.customPots.find((pot) => pot.id === potId);
    if (pot) {
      return pot.name;
    }

    return "Deleted pot";
  }

  render() {
    return (
      <div className="progress-bar__stack">
        {
          //if there are logs for the day we should display those
          !!this.props.logs &&
            this.props.logs.map((log, index) => {
              const colourHex = this.getColourHexFromPotId(log.potId);
              const fontColour = findFontAndBackgroundColour(colourHex);
              return (
                <div
                  key={index}
                  // to make sure the first unit gets the right css rounding applied to it we need to name this
                  className={index === 0 ? "stack-unit--first" : "stack-unit"}
                  style={{
                    width: "calc(16px + (100% *" + log.duration + ") / 24)",
                    background: `linear-gradient(25deg, ${reduceHexColor(
                      colourHex,
                      2
                    )}, ${colourHex})`,
                    zIndex: 96 - index,
                  }}
                >
                  <div
                    className="progress-bar__stack__tooltip"
                    style={{
                      background: `linear-gradient(25deg, ${reduceHexColor(
                        colourHex,
                        2
                      )}, ${colourHex})`,
                      color: fontColour.fontOverlayHex,
                    }}
                  >
                    <div className="progress-bar__stack__tooltip__title">
                      {this.getPotNameFromPotId(log.potId)}
                    </div>
                    <div className="progress-bar__stack__tooltip__title">
                      {log.startTime} for {log.duration}hrs
                    </div>
                  </div>
                </div>
              );
            })
        }
      </div>
    );
  }
}

// Typescript will warn if any props our outside the defined validation
ProgressBar.propTypes = {
  pots: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  pots: state.pots,
});

export default connect(mapStateToProps, {})(ProgressBar);
