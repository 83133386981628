import React from "react";

import BlogSuggestionCard from "../../components/layout/BlogSuggestionCard.js";
import routes from "../../utils/routes.js";

export const suggestedArticles = () => {
  const articles = [
    {
      title: "A new super power for us, the unprepared",
      forward: "Everything is made to distract us, we need tools to help",
      link: routes.blog.youShould,
      image:
        "https://images.unsplash.com/photo-1521517407911-565264e7d82d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80",
    },
    {
      title: "Time tracking was painful, so I made it better for us",
      forward:
        "For a year I tried different apps, then I decided to learn to build one",
      link: routes.blog.whyItWasSoHard,
      image:
        "https://images.unsplash.com/photo-1597514577371-224f64277638?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=975&q=80",
    },
    {
      title: "How to start",
      forward:
        "My years of experience. If you don't know where to start, start here. ",
      link: routes.blog.howToRecord,
      image:
        "https://images.unsplash.com/photo-1620404426680-fa32422d7882?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=976&q=80",
    },
  ];

  return (
    <div className="u-flex--desktop u-w-all">
      {articles.map((article) => {
        return <BlogSuggestionCard article={article} />;
      })}
    </div>
  );
};
