import React, { Component } from "react";

import Navbar from "../components/layout/Navbar.js";
import SettingsContent from "../components/layout/SettingsContent.js";

import { track } from "../utils/mixpanel.js";

class Settings extends Component {
  componentDidMount() {
    track("open-settings");
  }

  render() {
    return (
      <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden">
        <div className="u-relative u-wrap">
          <Navbar />
          <div className="c-settings">
            <div className="c-settings__contents u-hide--mobile">
              <h2 className="u-sub-font--medium u-color-white u-mv--12">
                Settings
              </h2>

              <ul className="c-settings__contents__items">
                <li>
                  <a
                    href="#reorder"
                    className="u-sub-font--vsmall u-link-white"
                  >
                    Reorder Pots
                  </a>
                </li>
                <li>
                  <a
                    href="#install"
                    className="u-sub-font--vsmall u-link-white"
                  >
                    Install App
                  </a>
                </li>
                <li>
                  <a
                    href="#notifications"
                    className="u-sub-font--vsmall u-link-white"
                  >
                    Notifications
                  </a>
                </li>
                <li>
                  <a
                    href="#support"
                    className="u-sub-font--vsmall u-link-white"
                  >
                    Support & Feedback
                  </a>
                </li>
                <li>
                  <a
                    href="#user-analytics"
                    className="u-sub-font--vsmall u-link-white"
                  >
                    User Analytics
                  </a>
                </li>
                <li>
                  <a href="#delete" className="u-sub-font--vsmall u-link-white">
                    Delete Account
                  </a>
                </li>
              </ul>
            </div>

            <div className="c-settings__body u-pb--64">
              <SettingsContent
                contentId="reorder"
                contentTitle="Reorder your pots"
              />
              <SettingsContent
                contentId="install"
                contentTitle="Install the App"
              />
              <SettingsContent
                contentId="notifications"
                contentTitle="Notifications"
              />
              <SettingsContent
                contentId="support"
                contentTitle="Support and Feedback"
              />
              <SettingsContent
                contentId="user-analytics"
                contentTitle="Data analytics"
              />
              <SettingsContent
                contentId="delete"
                contentTitle="Delete your account and all data"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
