import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { potTypes } from "../../../utils/constants";

class TypeInput extends Component {
  render() {
    return (
      <div className="u-mb--12--mobile u-mb--32">
        <label
          htmlFor="pot-type"
          className="u-sub-font--vsmall u-color-light-grey u-flex"
        >
          Pot type
        </label>
        <div className="u-flex u-align-center u-mb--4--mobile u-mt--8">
          <select
            className="u-sub-font--small u-color-white u-w-all o-select-options"
            id="pot-type"
            onChange={this.props.typeChange}
          >
            <option className="" value="">
              {this.props.type ? this.props.type : "Pick your pot type"}
            </option>
            {potTypes.map((potType) => (
              <option className="" value={potType.name}>
                {potType.name}
              </option>
            ))}
          </select>
        </div>
        {this.props.errors.data && this.props.errors.data.type && (
          <small className="u-sub-font--vsmall u-color-red u-mt--4">
            {this.props.errors.data.type}
          </small>
        )}
      </div>
    );
  }
}

TypeInput.propTypes = {
  errors: PropTypes.object.isRequired,
  type: PropTypes.string,
  typeChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {})(TypeInput);
