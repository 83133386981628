import React, { Component } from "react";
import { connect } from "react-redux";

import { track } from "../../../utils/mixpanel";
import { Icon } from "../../objects/Icon";

interface Props {
  pwa: PWAState;
}

class InstallSettingsContent extends Component<Props> {
  state = {
    deferredPrompt: null,
    installShouldWork: false,
  };

  componentDidMount() {
    track("open-install-settings", {
      canInstall: !!this.props.pwa.deferredPrompt,
    });

    // Mac's using chrome or edge will still work hence change that
    const macNotUsingSafari = /chrome|firefox|edge/.test(
      window.navigator.userAgent.toLowerCase()
    );

    // if the user is using safari it will not work
    if (!macNotUsingSafari) {
      this.setState({ installShouldWork: true });
    }
  }

  installClicked = async (e: React.MouseEvent<HTMLButtonElement>) => {
    //from https://web.dev/customize-install/

    track("click-install-pwa");

    this.props.pwa.deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    const { userChoice: outcome } = await this.props.pwa.deferredPrompt;
    track("install-pwa-outcome", {
      outcome,
    });

    // We've used the prompt, and can't use it again, throw it away
  };

  render() {
    return (
      <div className="u-flex u-align-center">
        <div className="u-sub-font--small u-color-white">
          Get the app on your phone or laptop
          <div className="u-sub-font--vsmall u-color-grey u-mt--8 u-mb--16 u-text-left">
            {/* Show iOS message, or show error message, or show install button */}
            {this.state.installShouldWork ? (
              <>
                The quickest way to add everyhour to your home screen on an
                iPhone is to:
                <ul style={{ marginLeft: "1em", marginTop: "1em" }}>
                  <li style={{ display: "listItem", listStyleType: "circle" }}>
                    open everyhour.xyz in Safari,
                  </li>
                  <li style={{ display: "listItem", listStyleType: "circle" }}>
                    {" "}
                    click the share button{" "}
                    <Icon
                      id="safari-share"
                      size="s"
                      className="u-color-white"
                    />{" "}
                  </li>
                  <li style={{ display: "listItem", listStyleType: "circle" }}>
                    and then click "Add to Home Screen"
                  </li>
                </ul>
              </>
            ) : this.props.pwa.deferredPrompt ? (
              <>
                For those interested, this app is a Progressive Web App (PWA).
              </>
            ) : (
              <>
                This can be disabled for a few reasons.
                <br /> Please message me below if you are having problems.
              </>
            )}
          </div>
        </div>

        {this.props.pwa.deferredPrompt && !this.state.installShouldWork ? (
          <button
            onClick={this.installClicked}
            className="u-right--0 u-mlauto u-main-font--vsmall o-button--green"
          >
            Install the app
          </button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: EveryHourState) => ({
  auth: state.auth,
  pwa: state.pwa,
});

export default connect(mapStateToProps)(InstallSettingsContent);
