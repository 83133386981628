import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ColourInput extends Component {
  render() {
    return (
      <div className="u-mb--12--mobile u-mb--32">
        <label
          htmlFor="pot-colour"
          className="u-sub-font--vsmall u-color-light-grey u-flex"
        >
          Pot colour
        </label>
        <div className="u-flex u-align-center u-mb--4--mobile u-mt--8">
          <input
            id="pot-colour"
            className="o-input-field--color u-w-all"
            type="color"
            value={this.props.colour}
            onChange={this.props.colourChange}
          ></input>
        </div>
        {this.props.errors.data && this.props.errors.data.colour && (
          <small className="u-sub-font--vsmall u-color-red u-mt--4">
            {this.props.errors.data.colour}
          </small>
        )}
      </div>
    );
  }
}

ColourInput.propTypes = {
  errors: PropTypes.object.isRequired,
  colour: PropTypes.string,
  colourChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {})(ColourInput);
