import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { showModal } from "../../actions/modalActions";

import { Icon } from "../objects/Icon";

import { PotPropTypes, PotTargetPropTypes } from "../../utils/propTypes";
import {
  findFontAndBackgroundColour,
  reduceHexColor,
} from "../../utils/potFunctions";

import EditPotModal from "./EditPotModal";
import ExpandedPotModal from "./ExpandedPotModal";

class Pot extends Component {
  state = {};

  showEditPotModal = () => {
    const { pot } = this.props;

    const modalContent = () => (
      <div>
        <EditPotModal
          potDetails={this.props.pot}
          targetDetails={this.props.targetDetails}
        />
      </div>
    );

    this.props.showModal({ component: modalContent, data: { pot } });
  };

  displayAchievement = () => {
    const { targetDetails, currentTargetProgress } = this.props;
    if (
      //if there is a target
      targetDetails &&
      //and there is progress for that target
      currentTargetProgress
    ) {
      //and the totalDuration is larger or equal to the target and the target is to do a minimum of X hours
      if (
        currentTargetProgress.totalDuration >= targetDetails.targetNumber &&
        targetDetails.targetAdjective === "min"
      ) {
        return (
          <div
            role="img"
            aria-label="welldone-trophy"
            className="o-celebrate-animation u-mh--4"
          >
            🏆
          </div>
        );
      }
      // but if the target is to do a maximum of X hours and the count is larger
      else if (
        currentTargetProgress.totalDuration > targetDetails.targetNumber &&
        targetDetails.targetAdjective === "max"
      ) {
        return (
          <span role="img" aria-label="thumbsdown">
            👎
          </span>
        );
      } else {
        return null;
      }
    }
  };

  expandPotModal = () => {
    const modalContent = () => (
      <ExpandedPotModal
        potDetails={this.props.pot}
        targetDetails={this.props.targetDetails}
        currentTargetProgress={this.props.currentTargetProgress}
      />
    );
    this.props.showModal({
      component: modalContent,
      data: {},
      modalStyle: { backgroundColor: this.props.pot.colour },
    });
  };

  render() {
    const { name, type, colour: potColour } = this.props.pot;
    const colour = findFontAndBackgroundColour(potColour);

    return (
      <div className="u-relative">
        <div
          className={this.props.size ? "pot--" + this.props.size : "pot"}
          style={{
            background: `linear-gradient(25deg, ${reduceHexColor(
              colour.hex,
              1.6
            )}, ${colour.hex})`,
            color: colour.fontOverlayHex,
            border: this.props.onboardingSelected ? "2px solid white" : "none",
          }}
          onClick={
            this.props.onClickOfPot
              ? this.props.onClickOfPot
              : this.expandPotModal
          }
        >
          <div className="pot-container u-text-unselectable u-p--12--mobile u-pv--16 u-ph--24">
            <div className="u-text-left">
              <div
                className="pot__name u-sub-font--small u-truncate u-truncate--2l u-fill-available u-flex"
                style={{
                  color:
                    this.props.onboardingSelected === false ? "grey" : "white",
                }}
              >
                {this.displayAchievement()} {name}
              </div>

              <div
                className="u-sub-font--vsmall u-color-white"
                style={{
                  color:
                    this.props.onboardingSelected === false ? "grey" : "white",
                }}
              >
                {type}
              </div>

              {this.props.targetDetails && this.props.currentTargetProgress ? (
                <div>
                  <div className="u-sub-font--vsmall u-color-white u-hide--mobile">
                    {this.props.currentTargetProgress.totalDuration}/
                    {this.props.targetDetails.targetPeriod} (Target:{" "}
                    {this.props.targetDetails.targetNumber}/
                    {this.props.targetDetails.targetPeriod})
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {this.props.isEditable && (
          <button
            onClick={this.showEditPotModal}
            className="pot__menu u-absolute u-right--0 u-top--0 u-flex u-align-center u-justify-center u-mt--24 u-mt--12--mobile u-mh--8"
          >
            <Icon id="more-vert" size="m" />
          </button>
        )}
      </div>
    );
  }
}

Pot.propTypes = {
  pot: PropTypes.shape(PotPropTypes).isRequired,
  isEditable: PropTypes.bool,
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  currentTargetProgress: PropTypes.object,
  targetDetails: PropTypes.shape(PotTargetPropTypes),
  onboardingSelected: PropTypes.bool,
};

export default connect(null, {
  showModal,
})(Pot);
