import React, { Component } from "react";

import InstallSettingsContent from "./SettingsContents/Install";
import NotificationsSettingsContent from "./SettingsContents/Notifications";
import UserAnalyticsSettingsContent from "./SettingsContents/UserAnalytics";
import SupportSettingsContent from "./SettingsContents/Support";
import ReorderSettingsContent from "./SettingsContents/Reorder";
import DeleteAccountSettingsContent from "./SettingsContents/DeleteAccount";

class SettingsContent extends Component {
  returnComponent = (id) => {
    switch (id) {
      case "notifications":
        return <NotificationsSettingsContent />;
      case "install":
        return <InstallSettingsContent />;
      case "support":
        return <SupportSettingsContent />;
      case "user-analytics":
        return <UserAnalyticsSettingsContent />;
      case "reorder":
        return <ReorderSettingsContent />;
      case "delete":
        return <DeleteAccountSettingsContent />;
      //no default
    }
  };

  render() {
    return (
      <div className="u-bb--light-grey u-pb--64 u-w-all">
        <h1 className="u-sub-font--medium u-color-white">
          <span // this allows the hyperlinking to fit below the nav bar : https://css-tricks.com/hash-tag-links-padding/
            id={this.props.contentId}
          >
            {this.props.contentTitle}
          </span>
        </h1>
        {this.returnComponent(this.props.contentId)}
      </div>
    );
  }
}

export default SettingsContent;
