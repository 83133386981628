import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  checkResetPasswordTokenIsValid,
  resetPasswordRequestEmail,
  resetPassword,
} from "../../actions/authActions";

import Navbar from "../../components/layout/Navbar";

import routes from "../../utils/routes";
import BackgroundBlobTop from "../../components/objects/BackgroundBlobTop";
import BackgroundBlobBottom from "../../components/objects/BackgroundBlobBottom";

class ResetPassword extends Component {
  state = {
    email: "",
    password: "",
    password2: "",
    token: "",
    errors: {},
  };

  componentDidMount = async () => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get("email"); // automatically decodes URI
    const token = url.searchParams.get("token");

    if (email && token) {
      await this.props.checkResetPasswordTokenIsValid(
        encodeURIComponent(email),
        token
      );
    }

    this.setState({
      email: email,
      token,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  sendNewTokenEmail = async () => {
    await this.props.resetPasswordRequestEmail(
      encodeURIComponent(this.state.email)
    );
  };

  updateWithNewPassword = async () => {
    // Save new password and send email reset confirmation
    await this.props.resetPassword(
      encodeURIComponent(this.state.email),
      this.state.token,
      this.state.password,
      this.state.password2
    );

    if (!this.state.errors.length) {
      this.props.history.push(routes.login);
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-flex u-overflow-x--hidden u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-relative u-w-all">
          <Navbar hideCta={true} />
          <div className="u-wrap">
            <div className="u-mt--80 u-flex u-align-center u-justify-center">
              <div className="login-form  u-pl--48 u-p--0--mobile  ">
                <div className="login-form__card o-card">
                  {
                    /* if reset token is invalid show the error and offer the reset-password-link*/
                    this.props.auth.isPasswordTokenValid ? (
                      <div>
                        <h2 className="u-main-font--medium u-mb--24">
                          Create your new password
                        </h2>
                        <div className="o-input-field">
                          <input
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            id="password"
                            type="password"
                            placeholder="Password"
                          />
                          <span className="u-input-error-text">
                            {errors.password}
                          </span>
                        </div>
                        <div className="o-input-field">
                          <input
                            onChange={this.onChange}
                            value={this.state.password2}
                            error={errors.password2}
                            id="password2"
                            type="password"
                            placeholder="Confirm Password"
                          />
                          <span className="u-input-error-text">
                            {errors.password2}
                          </span>
                        </div>

                        <div className="u-w-all u-mt--16">
                          <div className="u-flex u-justify-center">
                            <button
                              className="o-button u-main-font--small u-justify-center"
                              type="submit"
                              onClick={this.updateWithNewPassword}
                            >
                              Save new password
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h2 className="u-main-font--medium u-mb--24">
                          This link has expired. Please try resetting your
                          password again and use the link in the email.
                        </h2>
                        <div className="u-justify-center u-flex u-sub-font u-color-black u-p--8">
                          <button
                            className="o-button u-main-font--small u-justify-center"
                            onClick={this.sendNewTokenEmail}
                          >
                            Send reset password email
                          </button>
                        </div>
                        <div className="u-justify-center u-flex u-sub-font u-color-black u-p--8">
                          <p>
                            <Link to={routes.login}>Log in</Link>
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

// Typescript is now aware of props required as input
ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  checkResetPasswordTokenIsValid,
  resetPasswordRequestEmail,
  resetPassword,
})(ResetPassword);
