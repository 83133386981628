import {
  SET_CURRENT_USER,
  USER_LOADING,
  CONFIRM_VALID_PASSWORD_TOKEN,
  NEW_USER_SIGNED_UP,
} from "../actions/types";

const isEmpty = require("is-empty");
const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  isPasswordTokenValid: false,
  isBrandNewUser: false,
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case NEW_USER_SIGNED_UP:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.decoded,
        isBrandNewUser: true,
        createdOn: action.payload.createdOn,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.decoded,
        createdOn: action.payload.createdOn,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_VALID_PASSWORD_TOKEN:
      return {
        ...state,
        isPasswordTokenValid: true,
      };
    default:
      return state;
  }
}
