import React, { Component } from "react";

import Navbar from "../../../components/layout/Navbar.js";
import BackgroundBlobTop from "../../../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../../../components/objects/BackgroundBlobBottom.js";
import Footer from "../../../components/layout/Footer.js";
import LinkButton from "../../../components/objects/LinkButton.js";

import { suggestedArticles } from "../blogPostsDirectory.js";

class YouShould extends Component {
  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden u-z-index---1">
          <BackgroundBlobTop />
          <Navbar fixedPosition={true} />
          <div className="u-w-all u-overflow--hidden">
            <img
              className="o-banner-image"
              src="https://images.unsplash.com/photo-1521517407911-565264e7d82d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80"
            />
          </div>
          <div className="u-w-all u-wrap">
            <div className=" u-align-center u-mt--16">
              <div className="u-m0auto u-color-white u-sub-font--small u-blog-text">
                <div className="u-text-center">
                  <div className="u-sub-font--large">
                    A new super power for us,
                    <br /> the unprepared
                  </div>
                  <h4 className="u-sub-font--small u-italic u-mt--32">
                    Work is entering the home. Life seperation is blurred.
                    <br />
                    Everything else is made for your attention.
                    <br /> We are all unprepared.
                  </h4>
                </div>
                <div className="u-w-all u-overflow--hidden u-mt--32">
                  <img
                    className="o-banner-image--inset"
                    src="https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1842&q=80"
                  />
                </div>
                <h3 className="u-sub-font--medium u-mt--12">
                  We don’t have awareness of time.
                </h3>
                <p className="">
                  Throughout time, we have generally struggled with time
                  keeping. We tend to only be aware if we are measuring it. We
                  tend to be shocked when it passes by quickly. But time is
                  unchanging, instead our perception of it changes. More and
                  more, things are built to attract your attention, and hence
                  distort how time feels to you.
                </p>
                <p className="">
                  Now everything you interact with is built to capture as much
                  time as possible.
                </p>
                <p>
                  If you think you have spent five minutes, you are unlikely to
                  stop. It is a little rest and recovery afterall. It is
                  innocent, right?
                </p>
                <p>
                  But what if 5 miuntes is actually 15? And what if it is
                  multiple times a day?
                </p>
                <p>
                  It is harder to judge these mini-interuptions so this becomes
                  a target. An addiction is one that will make money for others.
                  Do you know how much time you spend on these interuptions
                  throughout the day?
                </p>
                <p>
                  I do now. When I started it was shocking. It was more time
                  than I was spending with my partner. Now, through tracking, I
                  now am intentional and aware about doing it and manage it -
                  reclaim literally hours back of my life a day.
                </p>
                <div className="u-w-all u-overflow--hidden u-mt--32">
                  <img
                    className="o-banner-image--inset"
                    src="https://images.unsplash.com/photo-1504805572947-34fad45aed93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
                  />
                </div>

                <h3 className="u-sub-font--medium ">We are rarely motivated</h3>
                <p className="">
                  If someone asked you to do something, you would likely do it
                  for them. Motivation isn’t even a thought.
                </p>
                <p>
                  <strong>But when you give yourself a task</strong>, it’s much
                  harder.
                </p>
                <p>
                  There is likely less definition of the task and how to
                  complete it, less accountability as you’re only cheating
                  yourself and less urgency. <br />
                  <br />
                  This hits your life balance.
                  <br />
                  <br />
                  You either don't do what you want to do, or, your doing more
                  work than anything else becuase others regularly ask you. You
                  may neglect the things that make you happy and you'll
                  eventually burn out.
                </p>
                <div className="u-w-all u-overflow--hidden u-mt--32">
                  <img
                    className="o-banner-image--inset"
                    src="https://images.unsplash.com/photo-1596492593868-fd2f33cdd70e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                  />
                </div>
                <h3 className="u-sub-font--large u-mt--32">The defence</h3>
                <h3 className="u-sub-font--medium u-mt--32">
                  Tracking gives you awareness and motivation
                </h3>
                <p>
                  I started tracking my hours — every hour of the day. “What was
                  the main thing I did in that hour?”
                </p>
                <p>
                  Suddenly, tracking my time made me accountable to myself. I
                  would say to myself "Come on don't waste another hour" whereas
                  before I wouldn’t have noticed an hour had gone. I would see
                  that I am not spending time on the things I enjoy. I saw
                  vividly the amount of my life I was giving to work.
                </p>
                <p>
                  I also had a way to see where I wasn’t spending time — no time
                  went in the friends pot for a while and I knew it.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">What to track?</h3>
                <p>
                  The main activities is where I started: Sleeping, Working,
                  Family, Friends, Hobbies, Leisure, Admin.
                </p>
                <p>
                  Later I got more specific: Work I hate, work I love; Specific
                  projects and hobbies.
                </p>
                <p>
                  Then I made targets — at least 20 hours with friends each
                  month. At least 3 hours of exercise a week. At most, an hour
                  of youtube a day.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">
                  Get this superpower?
                </h3>
                <p>
                  I started tracking with a spreadsheet. Feel free too. I built
                  everyhour so that you don't have to. You can record easily on
                  any device. You can customised it as much as you want, with as
                  many categories as you want. You can view analytics on your
                  time in one click.
                  <br />
                  <br />
                  You can sign up here:
                  <div className="u-flex u-justify-center u-mv--24">
                    <LinkButton type="Register" text="Sign up" color="purple" />
                  </div>
                </p>
              </div>
              <h3 className="u-sub-font--medium u-mt--32 u-color-white u-text-center">
                Read more...
              </h3>
              {suggestedArticles()}
            </div>
            <Footer />
          </div>
          <BackgroundBlobBottom />
        </div>
      </div>
    );
  }
}

export default YouShould;
