import React, { Component } from "react";

import Navbar from "../../../components/layout/Navbar.js";
import BackgroundBlobTop from "../../../components/objects/BackgroundBlobTop.js";
import BackgroundBlobBottom from "../../../components/objects/BackgroundBlobBottom.js";
import Footer from "../../../components/layout/Footer.js";
import LinkButton from "../../../components/objects/LinkButton.js";
import { suggestedArticles } from "../blogPostsDirectory.js";

class WhyItWasSoHard extends Component {
  render() {
    return (
      <div>
        <div className="u-absolute u-top--0 u-w-all u-blue-background u-overflow--hidden u-z-index---1">
          <BackgroundBlobTop />
          <Navbar fixedPosition={true} />
          <div className="u-w-all u-overflow--hidden">
            <img
              className="o-banner-image"
              src="https://images.unsplash.com/photo-1597514577371-224f64277638?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=975&q=80"
            />
          </div>
          <div className="u-w-all u-wrap">
            <div className=" u-align-center u-mt--16">
              <div className="u-m0auto u-color-white u-sub-font--small u-blog-text">
                <div className="u-text-center">
                  <div className="u-sub-font--large">
                    Why time tracking <b>was</b> so hard and how to jump over
                    it.
                  </div>
                  <h3 className="u-sub-font--small u-italic u-mt--32">
                    Time tracking is hot on the lips of any hedonistic project
                    manager, but for any individual who wants a quick easy and
                    useful tracking tool for themselves the options are small
                    and the problems are many.
                  </h3>
                </div>
                <div className="u-w-all u-overflow--hidden u-mt--32">
                  <img
                    className="o-banner-image--inset"
                    src="https://cdn-images-1.medium.com/max/2560/1*MaVA7y1bCtAdPlqw7YkcuQ.png"
                  />
                </div>
                <h3 className="u-sub-font--medium u-mt--12">
                  The tools are crap:
                </h3>
                <p>
                  Excel is the most common. It is great for doing ad-hoc
                  analysis which may be interesting but knowing was to analysis
                  in a flood of hours is hard. Plus actually recording your
                  hours is really hard.
                </p>
                <p>
                  There are other tools and I have tried most of them (as it
                  would have been easier to use a tool than build my own!) but
                  nothing worked for me. For each one I used, I started making a
                  list
                  <a
                    href="https://www.notion.so/d3f534e55c2b4c9eba33f2026eb52f69?v=6bc1de79e5a54630abce8f0344c90460"
                    target="_blank"
                  >
                    {" "}
                    and you can see them here
                  </a>{" "}
                  but as a summary they tend to fit into a few groups:
                  <ul>
                    <li>
                      <b>Timers</b> - literally click a stop watch each time you
                      change activities (but I kept forgetting!)
                    </li>
                    <li>
                      <b>Dashboards</b> - connect to lots of things to get data
                      and focus on showing it
                    </li>
                    <li>
                      <b>Timesheets</b> - literally what businesses use for
                      tracking hours to projects - most of the features were
                      irrelevant or in the way
                    </li>
                    <li>
                      <b>Snoopers</b> - they watch what you are doing on your
                      devices and report on it. The detail was too much, meaning
                      I would need to trawler through all the data to make sense
                      of it.
                    </li>
                    <li>
                      <b>Counters</b> - at the end you click yes or no if you
                      did things.
                    </li>
                  </ul>
                </p>
                <p>
                  I have tried almost all of them and still I struggled to apply
                  it to do what I want — track everything I do in a day.
                </p>
                <h3 className="u-sub-font--medium u-mt--32">
                  <strong>Problem with these tools:</strong>
                </h3>
                <ul>
                  <li>
                    <strong>Access</strong> - Some are not good (or available)
                    on mobile so tracking is painful
                  </li>
                  <li>
                    <strong>Simple</strong> - While mobile apps may be
                    available, actual logging of time can be challenging
                    (typically requiring multiple inputs of start times and end
                    times and more — all of which are not relevant to my
                    use-case).
                  </li>
                  <li>
                    <strong>Repetition</strong> - In my first year, I had to
                    type the same 12 words 8760 times (the number of hours in a
                    year). If I did 8 hours of the same stuff (sleep) I had to
                    type 8 times. I do not want to do this
                    <strong>ever</strong> again. Likewise 8 button clicks
                    doesn’t sound appealing.
                  </li>
                  <li>
                    <strong>Pressure</strong> - When using Toggl, I felt
                    constantly under a timer even when I couldn’t see it.
                  </li>
                  <li>
                    <strong>Forgetting</strong> - I need to build habits to do
                    this. The app must give me a habit, not just be a tool to
                    use when you remember, as you will forget.
                  </li>
                  <li>
                    <strong>Too Specific</strong> - when it told me I had done
                    12 hours 42 minutes and 25 seconds of Exercise so far, I
                    really don’t care.
                  </li>
                  <li>
                    <strong>No fun</strong> - This should track my life, not
                    bore it! I am not an accountant billing customers. There is
                    the joy in remembering my time, of succeeding goals, of
                    spending time properly, so where is that joy in these apps?
                  </li>
                </ul>

                <div className="u-w-all u-overflow--hidden u-mt--32">
                  <img
                    className="o-banner-image--inset"
                    src="https://cdn-images-1.medium.com/max/800/1*quDScL6CLwZ1CmBj3V1Edw.png"
                  />
                </div>
                <h3 className="u-sub-font--medium u-mt--12">
                  So I built my own
                </h3>
                <p>
                  This is why I decided to learn JavaScript and build it myself.
                  I fixed all of these points.
                </p>

                <ul>
                  <li>
                    <strong>Access</strong> - everyhour is a mobile and webapp
                    so you can reach it from any device.
                  </li>
                  <li>
                    <strong>Simple</strong> - you record by dragging time blocks
                    - so there are no more forms!
                  </li>
                  <li>
                    <strong>Repetition</strong> - you can record 8 hours in one
                    swipe.
                  </li>
                  <li>
                    <strong>Pressure</strong> - it is not pressuring as it is
                    retroactive and self-reflective - encouraging you to review
                    and improve consciously.
                  </li>
                  <li>
                    <strong>Forgetting</strong> - I have added notifications to
                    help prevent forgetting and have built the app to sit on
                    your phones home page. It is still tricky but it is better
                    than before!
                  </li>
                  <li>
                    <strong>Too Specific</strong> - it isn't specific as the
                    smallest chunk to record is 15 minutes.
                  </li>
                  <li>
                    <strong>No fun</strong> - it is not laborous anymore - I
                    truly get a kick out of dragging time and hitting my goals!
                  </li>
                </ul>

                <h3 className="u-sub-font--medium u-mt--32">
                  Get this superpower?
                </h3>
                <p>
                  I started tracking with a spreadsheet. Feel free too. I built
                  everyhour so that you don't have to. You can record easily on
                  any device. You can customised it as much as you want, with as
                  many categories as you want. You can view analytics on your
                  time in one click.
                  <br />
                  <br />
                  You can sign up here:
                  <div className="u-flex u-justify-center u-mv--24">
                    <LinkButton type="Register" text="Sign up" color="purple" />
                  </div>
                </p>
              </div>
              <h3 className="u-sub-font--medium u-mt--32 u-color-white u-text-center">
                Read more...
              </h3>
              {suggestedArticles()}
            </div>
            <Footer />
          </div>
          <BackgroundBlobBottom />
        </div>
      </div>
    );
  }
}

export default WhyItWasSoHard;
