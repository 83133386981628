import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { registerUser } from "../../actions/authActions";

import Navbar from "../../components/layout/Navbar";
import BackgroundBlobTop from "../../components/objects/BackgroundBlobTop";
import BackgroundBlobBottom from "../../components/objects/BackgroundBlobBottom";

import routes from "../../utils/routes";
import { track } from "../../utils/mixpanel";

class Register extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    errors: {},
    isAllowToRegister: false,
    isSubmitting: false,
  };

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(routes.home);
    }

    track("view-register-page");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    const userInput = e.target.value;
    this.setState({ [e.target.id]: userInput });
  };

  consentCheckBoxTriggered = () => {
    const checkBox = document.getElementById("agreementOfService");

    if (checkBox.checked === true) {
      this.setState({ isAllowToRegister: true });
    } else {
      this.setState({ isAllowToRegister: false });
    }
  };

  signUpHasBeenClicked = () => {
    this.setState({ isSubmitting: true });

    if (this.state.isAllowToRegister) {
      const newUser = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
      };
      this.props.registerUser(newUser, this.props.history);
    } else {
      const notAllowedToRegisterError = {
        data: {
          checkBox:
            "Sorry, you need to accept the terms of service to use everyhour",
        },
        message: "Request not sent",
      };
      this.setState({
        errors: { ...notAllowedToRegisterError },
      });
    }
    this.setState({ isSubmitting: false });
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="u-absolute u-top--0 u-w-all u-h-all u-blue-background u-flex u-overflow-x--hidden u-z-index---1">
        <BackgroundBlobTop />
        <div className="u-relative u-w-all">
          <Navbar hideCta={true} />
          <div className="u-wrap">
            <div className="u-mt--80 u-flex u-align-center u-justify-center">
              <div className="register-form  u-flex u-align-center">
                <div className="register-form__card o-card">
                  <h2 className="u-main-font--large u-m--32 u-text-center">
                    Get started!
                  </h2>
                  <div className="o-input-field">
                    <span className="u-input-error-text">
                      {this.state.errors.data && this.state.errors.data.name
                        ? this.state.errors.data.name
                        : null}
                    </span>
                    <input
                      onChange={this.onChange}
                      value={this.state.name}
                      error={errors.name}
                      id="name"
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="o-input-field">
                    <span className="u-input-error-text">
                      {this.state.errors.data && this.state.errors.data.email
                        ? this.state.errors.data.email
                        : null}
                    </span>
                    <input
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="o-input-field">
                    <span className="u-input-error-text">
                      {this.state.errors.data && this.state.errors.data.password
                        ? this.state.errors.data.password
                        : null}
                    </span>
                    <input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="o-input-field--checkbox u-mt--8">
                    <span className="u-input-error-text">
                      {this.state.errors.data && this.state.errors.data.checkBox
                        ? this.state.errors.data.checkBox
                        : null}
                    </span>
                    <br />
                    <input
                      type="checkbox"
                      id="agreementOfService"
                      name="Agreement Of Service"
                      className="u-m--8"
                      onClick={this.consentCheckBoxTriggered}
                    />
                    <label htmlFor="agreementOfService">
                      I have read and agree to the{" "}
                      <a href="https://www.websitepolicies.com/policies/view/KNpQEkQZ">
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="https://www.websitepolicies.com/policies/view/GrLNc9fQ">
                        Cookie Policy
                      </a>{" "}
                    </label>
                  </div>
                  <div className="u-w-all u-mt--16">
                    <div className="u-flex u-justify-center">
                      <button
                        className={`o-button${
                          this.state.isAllowToRegister ? "" : "--grey"
                        } u-main-font--small u-justify-center`}
                        type="submit"
                        onClick={this.signUpHasBeenClicked}
                      >
                        Sign up
                      </button>
                    </div>
                    <div className="u-justify-center u-flex u-sub-font">
                      <p>
                        Already have an account?{" "}
                        <Link to={routes.login}>Log in</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackgroundBlobBottom />
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
